import { styled, Typography } from '@mui/material';

export const MuiSelectGradient = styled(Typography)(({ theme }) => ({
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:12,
  color:'#000000'
}));

export const MuiInvertColorPallete = styled(Typography)(({ theme }) => ({
  marginLeft: 12,
  fontSize:16,
  fontWeight:400,
  fontFamily:'Montserrat',
  color:'#000000'
}));

export const MuiDivider = styled('span')(({ theme }) => ({
  marginLeft:'5px',
  fontSize:'16px',
  marginRight:'5px'
}));

export const keyStyle = {
  fontSize:16,
  fontWeight:400,
  fontFamily:'Montserrat'
}

export const divContainerStyle = {
  display:'flex',
  marginLeft:16
}
