import {
  SliderField,
  SelectField,
  CheckboxField,
} from 'components/common/ReactHooksFormFields';
import {FormProvider, useForm} from 'react-hook-form';
import {
  makeStyles,
  Grid,
  Switch,
  Button,
  LinearProgress,
  TextField,
  Divider,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  InputAdornment,
  TablePagination,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import Typography from '@material-ui/core/Typography';
import round from 'utils/roundIfNecessary';
import useGenerateRecommendedOffers
  from 'api/hooks/useGenerateRecommendedOffers';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  setOpenToast,
  setSeverityToast,
  setMessageToast,
  setUpdateRecommendationLayerFromWidget,
  setSelectWidgetId,
  setWidgetFiltersForTargetDemand,
  setWidgetFiltersForCurrentOffer,
  setRecomputedRecommendationsWidgets
} from 'store/appSlice';
import {GLOBAL, messageSeverity} from 'utils/constants';
import {useTranslation} from 'components/providers/TranslationProvider';
import apiPaths from 'api/apiPaths';
import {status200} from 'api/status.utils.js';
import {
  DataGrid,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'api/axios/axiosInstance.js';
import CustomSkeleton from 'components/common/CustomSkeleton';
import TypographyWithInfoIconTooltip
  from 'components/common/TypographyWithInfoIconTooltip';
import {
  Create as CreateIcon,
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
  ThumbUp as ThumbUpIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  PinDrop as PinDropIcon,
  Tune as TuneIcon,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Refresh as RefreshIcon
} from '@material-ui/icons';
import {
  OpenInFull as OpenInFullIcon,
} from '@mui/icons-material';
import VariableInfo from 'components/common/VariableInfo';
import GenerateOrComputeRecommendations
  from './modals/GenerateOrComputeRecommendations'
import {processJobStatus} from 'utils/constants';
import FilterRecommendationsPopover from './FilterRecommendationsPopover';
import IndicatorOfFiltersAppliedOnAlayer
  from './IndicatorOfFiltersAppliedOnAlayer';
import {getWidgetsWithFiltersByLayerId} from 'utils/supportComponents';
import Loader from "../../../views/organizations/common/Loader";

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '&, & *': {
      '&::-webkit-scrollbar': {
        backgroundColor: '#ebeef2',
        height: 5,
        borderRadius: 3,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#d3dbe5',
        borderRadius: 3,
      },
    },
  },
  '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
    backgroundColor: localStorage.getItem('colorPrimary'),
    borderColor: 'rgba(0, 0, 0, 0.6)',
  },
  columnHeader: {
    '&:focus': {
      outline: 'none !important',
    },
  },
  cell: {
    '&:focus': {
      outline: 'none !important',
    },
  },
  widgetHeader: {
    fontSize: 24,
    fontWeight: 600
  },
  widgetSubHeader: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10
  },
  widgetContainerName: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10
  },
  recommedationsBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '1.5rem',
    marginBottom: '1.5rem'
  },
  potentialOffersBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '2rem',
    marginBottom: '1.5rem'
  },
  container: {
    height: 510
  },
  widgetDivider: {
    marginTop: 20,
    marginBottom: 10
  },
  formulaSelector: {
    display: 'flex',
    alignItems: 'center'
  },
  welfareFormulaSelector: {
    marginBottom: 10
  },
  welfareFormulaSelectorLabel: {
    marginRight: 10
  },
  advancedOptionsLabel: {
    marginRight: 15
  },
  advancedOptions: {
    display: 'flex',
    marginBottom: 15
  },
  no_items_to_show: {
    color: 'gray',
    textAlign: 'center'
  },
  accordion: {
    backgroundColor: 'inherit',
    boxShadow: 'none',
    width: '100%',
    borderRadius: '12px !important',
    border: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
    marginBottom: 16,
  },
  dataGridContainer: {
    marginTop: 20
  },
  accordionHeader: {
    fontWeight: 600,
    fontSize: 17,
  },
  accordionSeparator: {
    '&.MuiAccordion-root:before': {
      display: 'none',
      boxShadow: 0
    }
  },
  displayFlex: {
    display: 'flex'
  },
  marginAuto: {
    margin: 'auto'
  },
  typographyWithInfoIconTooltipStyles: {
    paddingTop: 5,
    paddingBottom: 23,
    margin: 'auto',
  },
  recommendations_candidates_header: {
    fontWeight: 500,
    letterSpacing: '0.1%',
    fontSize: 14,
    alignSelf: 'center',
    marginRight: 22,
    color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800]
  },
  pencilIcon: {
    width: '18px !important',
    height: '18px !important',
    color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  },
  recommendationsCandidatesHeaderContainer: {
    width: '100%',
    display: 'flex',
    marginBottom: 8,
    justifyContent: 'space-between'
  },
  recommendationsCandidatesData: {
    width: '374px',
  },
  accordionDetails: {
    flexDirection: 'column',
    padding: '0px 16px 16px',
  },
  sectionDivider: {
    width: '100%',
    marginTop: 16,
    marginBottom: 16,
    backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]
  },
  recommendationsListSection: {
    width: '100%',
  },
  // tabla de recomendaciones
  recommendationsTableHeader: {
    fontWeight: GLOBAL.FONT_WEIGHTS.MEDIUM,
    fontSize: 14,
    letterSpacing: 0.1,
    width: '100%'
  },
  recommendationsTableHeaderContainer: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  accordionTable: {
    justifyContent: 'space-between',
    margin: 'auto',
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    marginRight: 45,
    marginLeft: 10,
  },
  unorderedListContainer: {
    marginTop: 6.5
  },
  recomendationsVariables: {
    color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700]
  },
  itemsDivider: {
    borderBottom: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]
  },
  recommendationsDataTableContainer: {
    borderBottom: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
    width: '100%',
  },
  headerVariableData: {
    display: 'inline-flex'
  },
  filterBox: {
    '& .MuiOutlinedInput-root': {
      height: 36,
      borderRadius: 18,
      marginRight: 17
    }
  },
  circularBorder: {
    borderRadius: '50%',
    backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[200],
    display: "flex",
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  recommendationInfoIcon: {
    width: 22,
    height: 20
  },
  displayRecommendationIcon: {
    width: 18,
    height: 18,
    fill: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800]
  },
  // fin tabla recomendaiones
  // nueva version de la tabla de recomendaciones
  tableCellIcons: {},
  tableCell: {
    padding: 0,
    height: 52,
  },
  tableHeader: {
    borderTop: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`
  },
  dividerCell: {
    padding: 0,
  },
  borderedCell: {
    borderLeft: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
    textAlign: 'center',
  },
  btnHover: {
    width: 32,
    height: 32
  },
  checkBoxAttr: {
    width: 18,
    height: 18
  },
  iconsAttrs: {
    position: 'relative',
  },
  widget_sub_header: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '17.07px',
    letterSpacing: '0.1px',
    color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800],
  },
  filterListIcon: {
    color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700]
  },
  tuneIcon: {
    color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800]
  },
  tableHeaderLabel: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '14.63px',
    letterSpacing: '0.4px',
    textAlign: 'center',
  },
  // fin de la nueva version
  filterOverDemandContainer: {
    marginTop: 16,
  },
  filterOverDemandContainerWithoutMargin: {
    marginTop: 0,
  },
  accordionSummary: {
    width: '406px !important',
    '& .MuiAccordionSummary-content.Mui-expanded': {
      marginBottom: '16px !important',
      marginTop: '16px',
    },
    '& .MuiCollapse-root': {
      width: '406px !important',
    }
  },
  gridRecommendationsSummary: {
    flexBasis: 'unset',
  }
}));

function RecommendationsWidgetUI(props) {
  const classes = useStyles();
  const {
    data,
    onSelect,
    id,
    customData,
    updateListForDelete,
    demandDatasetColumns,
    demandDatasetColumnsIsSuccess,
    targetDemandFilter,
    currentOfferFilter,
    dynamicRecommendationsName,
    processJobStatusPotOffers,
    handleFilterRecommendations,
    setProcessJobName,
    offerTypesFilter,
    setRecommendationsComputionData,
    sortedTypeByColumn,
    sortedTypeById,
    columnsToOrder,
    runFilterHandler,
  } = props;
  const {columns: dataCols = [], values: dataRows = []} = data || {};
  const [viewSelected, setViewSelected] = React.useState(null);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [selectAllChecked, setSelectAllChecked] = React.useState(false);
  const location = useLocation();
  const splittedPathname = React.useMemo(() => location.pathname.split('/'), [location.pathname]);
  const org_id = splittedPathname[2] === 'workspace' ? user.id : splittedPathname[2];
  const dispatch = useDispatch();
  const {t, lng} = useTranslation();
  const [recommendedExpansion, setRecommendedExpansion] = React.useState(customData.recommended_expansion);
  const [showProgressBar, setShowProgressBar] = React.useState(false);
  const [privateLoading, setPrivateLoading] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = React.useState('');
  const [headerMessage, setHeaderMessage] = React.useState('');
  const [titleMessage, setTitleMessage] = React.useState('');
  const [progress, setProgress] = React.useState(0);
  const recommendationsGeneratedSuccessfully = React.useRef(false);
  const parametersCatalog = useSelector((state) => state.app.parametersCatalog);
  const [severity, setSeverity] = React.useState('error');
  const [isWelfareVariableFormulaSelected, setIsWelfareVariableFormulaSelected] = React.useState(customData.custom_w_var ? true : !!customData.isFormulaFieldSelected);
  const [welfareVariableFormulaValue, setWelfareVariableFormulaValue] = React.useState(customData.custom_w_var ? customData.custom_w_var : customData.formulaWelfare);

  const [isAdvancedOptionsSelected, setIsAdvancedOptionsSelected] = React.useState(customData.advancedOptions);
  const [widgetHeight, setWidgetHeight] = React.useState(630);

  const [isPotentialOffersAccordionExpanded, setIsPotentialOffersAccordionExpanded] = React.useState(false);
  const [isRecommendationsAccordionExpanded, setIsRecommendationsAccordionExpanded] = React.useState(customData.allowsRegenerateServicePoint !== null && customData.allowsRegenerateServicePoint !== undefined && !customData.allowsRegenerateServicePoint);
  const [isOfferCapacitySelectedLink, setIsOfferCapacitySelectedLink] = React.useState(false);

  const filterRecommendations = useSelector((state) => state.app.listRecommendationsWidgetForDelete);
  const widget = useSelector((state) => state.app.widgets.find(w => w.id === id));
  const recomputedRecommendationsWidgets = useSelector((state) => state.app.recomputedRecommendationsWidgets);
  const selectWidgetId = useSelector((state) => state.app.selectWidgetId);

  const selectedWidgetId = useSelector((state) => state.app.selectWidgetId);
  const selectedWidgetEntity = useSelector((state) => (state.app.recomputedRecommendationsWidgets.find(w => w.id === selectedWidgetId) || state.app.widgets.find(w => w.id === selectedWidgetId)));
  const sources = useSelector((state) => state.carto.dataSources);
  const layers = useSelector((state) => state.app.layers);
  const widgets = useSelector((state) => state.app.widgets);
  const spatialFilter = useSelector((state) => state.app.listFilterPolygonData);

  const [showPotentialOffersSectionByWidgetConfig, setShowPotentialOffersSectionByWidgetConfig] = React.useState(customData.allowsRegenerateServicePoint === null || customData.allowsRegenerateServicePoint === undefined || customData.allowsRegenerateServicePoint);

  const [dataForRecom, setDataForRecom] = React.useState({});
  const [dataForOP, setDataForOP] = React.useState({});

  const [updatedWidget, setUpdatedWidget] = React.useState({...widget.params});

  const {
    mutateAsync: generateRecommendedOffers,
    data: recommendedOffersData,
    status: recommendedOffersDataStatus,
    isSuccess: generateRecommendedOffersIsSuccess,
    isLoading: generateRecommendedOffersIsLoading,
  } = useGenerateRecommendedOffers();

  const getLanguage = () => {
    switch (lng) {
      case 'EN':
        return 'english';
      case 'FR':
        return 'french';
      case 'PO':
        return 'portuguese';
      case 'ES':
        return 'spanish';
      default:
        return 'english';
    }
  }
  // COMPONENTE TABLA

  const [openRow, setOpenRow] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 7));
    setPage(0);
  }

  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index); // Alternar entre abrir y cerrar la fila
  };

  // eslint-disable-next-line no-unused-vars
  const handleChange = (event) => {
    setRecommendedExpansion(event.target.checked);
  };

  const schema = yup.object().shape({
    budget: yup.number(),
    expanxionCapacity: yup.number(),
    expanxionCost: yup.number(),
    densityVariable: yup.string(),
    welfareVariable: yup.string(),
  });

  const getDefaultValues = () => {
    return {
      budget: customData.budget ? customData.budget : 1,
      expanxionCapacity: customData.recommended_expansion ? customData.expansion_capacity : 500,
      expanxionCost: customData.recommended_expansion ? customData.expansion_cost : 500,
      welfareVariable: customData.welfareVariable,
      densityVariable: customData.densityVariable,
      offerCoverageRadius: customData.coverage ? customData.coverage : customData.offerCoverageRadius,
      offerCost: customData.cost_lower ? customData.cost_lower : customData.offerCost,
      isOfferCapacitySelected: customData.capacity_lower ? true : customData.isOfferCapacitySelected,
      offerCapacity: customData.capacity_lower ? customData.capacity_lower : customData.offerCapacity
    }
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    watch,
    setValue,
  } = methods;

  const rows = dataRows.map((d, index) => {
    Object.entries(d).map(([k, v]) => {
      if (k === 'geo_isochrone') {
        d[k] = JSON.parse(v);
      }
      if (typeof v === 'boolean') {
        return d[k] = v;
      }
      if (typeof v !== 'object' && k !== 'cartodb_id') {
        return (d[k] = round(v, 3));
      }
      return (d[k] = v);
    });
    return {
      id: index,
      ...d,
    };
  });

  const columns = useMemo(
    () => [
      {
        field: 'actions',
        type: 'actions',
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        editable: false,
        getActions: (params) => [
          <GridActionsCellItem
            icon={(
                <div style={{ width: '13.5px'}}>
                <VisibilityIcon
                  style={{
                    color: params.id === viewSelected ? localStorage.getItem('colorPrimary') : 'gray',
                  }}
                />
              </div>
            )}
            onClick={() => {
              setViewSelected(params.id);
              onSelect({
                lat: params.row.geom.coordinates[1],
                lon: params.row.geom.coordinates[0]
              });
            }}
            label='Navigate'
            color='primary'
          />,
        ],
      },
      ...dataCols.map((c) => ({
        field: c.field,
        headerName: c.name,
        hide: !c.visible,
        type: c.field === 'expansion_recommended' ? 'boolean' : 'any'
      })),
    ],
    [dataCols, onSelect]
  );

  const wasComputedWithFilters = useMemo(
    () => {
      if (widget.params.isGlobal) {
        return !!(widget?.custom?.widgetFiltersForTargetDemand?.length > 0 || widget?.custom?.widgetFiltersForCurrentOffer?.length > 0)
      }
      return !!(widget?.params?.widgetFiltersForTargetDemand?.length > 0 || widget?.params?.widgetFiltersForCurrentOffer?.length > 0)
    },
    [widget]
  );

  const wasComputedWithFiltersForTargetDemand = useMemo(
    () => {
      if (widget.params.isGlobal) {
        return widget?.custom?.widgetFiltersForTargetDemand?.length > 0
      }
      return widget?.params?.widgetFiltersForTargetDemand?.length > 0
    },
    [widget]
  );

  const wasComputedWithFiltersForCurrentOffer = useMemo(
    () => {
      if (widget.params.isGlobal) {
        return widget?.custom?.widgetFiltersForCurrentOffer?.length > 0
      }
      return widget?.params?.widgetFiltersForCurrentOffer?.length > 0
    },
    [widget]
  );

  const budget = watch('budget');
  const expanxionCapacity = watch('expanxionCapacity');
  const expanxionCost = watch('expanxionCost');
  const densityVariable = watch('densityVariable');
  const welfareVariable = watch('welfareVariable');
  const isOfferCapacitySelected = watch('isOfferCapacitySelected');
  const offerCapacity = watch('offerCapacity');
  const offerCoverageRadius = watch('offerCoverageRadius')
  const offerCost = watch('offerCost');

  const handleGenerateRecommendations = async (budget, densityVariable, recommendedExpansion, expanxionCost, expanxionCapacity, isWelfareFormulaSelected, welfareFormula, welfareVariable, coverageType) => {
    const scheme_id = location.pathname.split('/')[6];
    let localParametersCatalog = null;
    if (parametersCatalog?.length > 0) {
      localParametersCatalog = {};
      parametersCatalog.forEach(p => {
        localParametersCatalog[p.propName] = p.value
      });
    }
    const payload = {
      "payload": {
        "demand_dataset_name": selectedWidgetEntity.params.demandDatasetName,
        "quant_var": densityVariable,
        "budget": budget,
        "user_name": dynamicRecommendationsName,
        "scenario_id": selectWidgetId,
        "recommend_expansion": recommendedExpansion,
        "max_expansion_cost": expanxionCost,
        "max_expansion_capacity": expanxionCapacity,
        "notification_host": process.env.REACT_APP_PROCESS_JOB_ENDPOINT + apiPaths.processJob,
        "parametersCatalog": localParametersCatalog,
        "lang": getLanguage(),
        "distance_metric": coverageType ? 'EUCLIDEAN': 'ISO_POLYGON'
      }
    };

    if (targetDemandFilter) {
      payload.payload['demand_custom_filter'] = targetDemandFilter;
    }

    if (currentOfferFilter) {
      payload.payload['current_offer_filter'] = currentOfferFilter;
    }

    if (isWelfareFormulaSelected) {
      payload.payload.custom_w_var = welfareFormula;
    } else {
      payload.payload.w_var = welfareVariable;
    }
    if (Object.keys(spatialFilter).length > 0){
      const key =Object.keys(spatialFilter)[0]
      payload.payload['spatial_filter_table'] = `incluia-project.incluia_datasets.widget_polygon_`+ spatialFilter[key].mapId + '_' + spatialFilter[key].widgetId;
      payload.payload['spatial_filter_column'] = 'polygon_id';
      payload.payload['spatial_feature_ids'] = spatialFilter[key].polygons;
    }

    generateRecommendedOffers(payload).then((response) => {
      performActionsAfterUpload(t('recommended_offers_generating'));
      if (response.value) {
        const processJobId = response.value.processJobId;
        let intervalMaxFailedRequest = 10;
        let intervalFailedRequestCount = 0;
        let intervalHandler = setInterval(() => {
          if (intervalFailedRequestCount === intervalMaxFailedRequest) {
            setShowProgressBar(false);
            setPrivateLoading(false);
            clearInterval(intervalHandler);

            const messageToastParams = {
              status: true,
              info: t('recommended_offers_error_occurred'),
              severity: messageSeverity.ERROR
            };
            manageMessageToast(messageToastParams);
          }
          axios.get(`${apiPaths.processJob}/${processJobId}`, status200).then(processJobResponse => {
            if (processJobResponse && processJobResponse.status === 200 && processJobResponse.data) {
              if (processJobResponse.data.value) {
                const processJob = processJobResponse.data.value;
                setHeaderMessage(t('recommended_offers_generating'));
                setTitleMessage(t('recommended_offers_generating'));
                if (processJob.status === 'in_progress') {
                  setProgress(processJob.progress);
                  setNotificationMessage(processJob.message);
                  customData.setWidgetProcessJobStatus(processJobStatus.INPROGRESS);
                }
                if (processJob.status === messageSeverity.SUCCESS) {
                  setShowProgressBar(false);
                  recommendationsGeneratedSuccessfully.current = true
                  setPrivateLoading(false);
                  clearInterval(intervalHandler);
                  customData.refetchRecommendationsOffers();
                  customData.refetchListDataIsochrone();
                  dispatch(setSelectWidgetId(id));
                  dispatch(setUpdateRecommendationLayerFromWidget(true));
                  customData.setWidgetProcessJobStatus(processJobStatus.SUCCESS);
                  setProgress(0);
                  setTitleMessage('');
                  setHeaderMessage('');
                }
                if (processJob.status === 'failed') {
                  setShowProgressBar(false);
                  setPrivateLoading(false);
                  clearInterval(intervalHandler);
                  const messageToastParams = {
                    status: true,
                    info: `${t('recommended_offers_error_occurred')}: ${processJob.message}`,
                    severity: messageSeverity.ERROR
                  };
                  manageMessageToast(messageToastParams)
                  customData.setWidgetProcessJobStatus(processJobStatus.FAILED);
                }
              } else {
                setShowProgressBar(false);
                setPrivateLoading(false);
                clearInterval(intervalHandler);
                const messageToastParams = {
                  status: true,
                  info: `${t('recommended_offers_error_occurred')}: ${processJob.message}`,
                  severity: messageSeverity.ERROR
                };
                manageMessageToast(messageToastParams);
                customData.setWidgetProcessJobStatus(processJobStatus.FAILED);
              }
            } else {
              intervalFailedRequestCount++;
            }
          }).catch(err => {
            setShowProgressBar(false);
            setPrivateLoading(false);
            clearInterval(intervalHandler);
            const messageToastParams = {
              status: true,
              info: t('recommended_offers_error_occurred'),
              severity: messageSeverity.ERROR
            };
            manageMessageToast(messageToastParams)
            customData.setWidgetProcessJobStatus(processJobStatus.FAILED);
          });
        }, 1600);

        const compute = {
          budget: payload.payload.budget,
          custom_w_var: payload.payload.custom_w_var,
          expansion_cost: payload.payload.max_expansion_cost,
          expansion_capacity: payload.payload.max_expansion_capacity,
          recommended_expansion: payload.payload.recommend_expansion,
          quant_var: payload.payload.quant_var,
          w_var: payload.payload.w_var,
          is_offer_capacity_selected: selectedWidgetEntity.isOfferCapacitySelected,
          is_welfare_formula_selected: selectedWidgetEntity.is_welfare_formula_selected,
          coverage_type: coverageType
        }

        setRecommendationsComputionData({...compute});
      }
    });
  };

  const showMessage = (message, severity = messageSeverity.SUCCESS) => {
    setSeverity(severity);
    setOpenToast(true);
    setMessageToast(message);
  };

  const performActionsAfterUpload = (message) => {
    setPrivateLoading(true);
    setShowProgressBar(true);
  };

  const manageMessageToast = (message) => {
    dispatch(setOpenToast(message.status));
    dispatch(setMessageToast(message.info));
    dispatch(setSeverityToast(message.severity))
  }

  const handleDataCheckboxChange = (event, rowIndex) => {
    const selectedIndex = selectionModel.indexOf(rowIndex);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectionModel, rowIndex);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectionModel.slice(1));
    } else if (selectedIndex === selectionModel.length - 1) {
      newSelected = newSelected.concat(selectionModel.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectionModel.slice(0, selectedIndex),
        selectionModel.slice(selectedIndex + 1)
      );
    }

    setSelectionModel(newSelected);
  }

  React.useEffect(() => {
    setIsOfferCapacitySelectedLink(isOfferCapacitySelected)
  }, [isOfferCapacitySelected])

  React.useEffect(() => {
    updateListForDelete(selectionModel)

    if (selectionModel.length === 0) {
      setSelectAllChecked(false);
    }
  }, [selectionModel.length])

  React.useEffect(() => {
    setDataForRecom({
      ...dataForRecom,
      budget: customData.budget ? customData.budget : 1,
      expanxionCapacity: customData.recommended_expansion ? customData.expansion_capacity : 500,
      expanxionCost: customData.recommended_expansion ? customData.expansion_cost : 500,
      welfareVariable: customData.w_var,
      densityVariable: customData.quant_var,
      isWelfareFormulaSelected: !!customData.custom_w_var,
      welfareFormula: customData.custom_w_var,
      demandDatasetColumnsIsSuccess,
      demandDatasetColumns,
      handleGenerateRecommendations,
      widget,
      customData,
    })
    setDataForOP({
      ...dataForOP,
      isOfferCapacitySelected: customData.capacity_lower ? true : customData.isOfferCapacitySelected,
      offerCapacity: customData.capacity_lower ? customData.capacity_lower : customData.offerCapacity,
      offerCoverageRadius: customData.coverage ? customData.coverage : customData.offerCoverageRadius,
      offerCost: customData.cost_lower ? customData.cost_lower : customData.offerCost,
      performActionsAfterUpload,
      setShowProgressBar,
      setPrivateLoading,
      manageMessageToast,
      setProgress,
      setNotificationMessage,
      messageSeverity,
      recommendationsGeneratedSuccessfully,
      showMessage,
      setHeaderMessage,
      setTitleMessage
    })
    setIsOfferCapacitySelectedLink(isOfferCapacitySelected)
  }, [demandDatasetColumnsIsSuccess, demandDatasetColumns, widget, customData])

  React.useEffect(() => {
    if (Object.keys(filterRecommendations).length > 0 && selectionModel.length == 0) {
      if (filterRecommendations[props.id]) {
        const selectedIndex = filterRecommendations[props.id].map(objSeleccionado => {
          return dataRows.findIndex(item => item.ido === objSeleccionado.recommendation_id);
        });
        setSelectionModel(selectedIndex);
      } else {
        setSelectionModel([]);
      }
    } else if (Object.keys(filterRecommendations).length == 0) {
      setSelectionModel([]);
    }
  }, [filterRecommendations]);

  React.useEffect(() => {
    const regeneratedRecommendationWidget = recomputedRecommendationsWidgets.find((item) => item.id === selectWidgetId)
    if (widget.id === selectWidgetId && regeneratedRecommendationWidget) {
      setUpdatedWidget(regeneratedRecommendationWidget);
    } else {
      setUpdatedWidget(widget);
    }
  }, [recomputedRecommendationsWidgets])

  const parametersCandidateRecommendations = {
    isOfferCapacitySelected,
    offerCapacity,
    offerCoverageRadius,
    offerCost,
    performActionsAfterUpload,
    setPrivateLoading,
    manageMessageToast,
    setProgress,
    setNotificationMessage,
    messageSeverity,
    setShowProgressBar,
    recommendationsGeneratedSuccessfully,
    showMessage,
    setHeaderMessage,
    setTitleMessage
  }

  const recomputePotentialOffers = () => {
    customData.createPotentialOffers(parametersCandidateRecommendations);
  }

  const handlePotentialOffersAccordionChange = (event, isExpanded) => {
    setIsPotentialOffersAccordionExpanded(isExpanded);
  };

  const handleRecommendationsAccordionChange = (event, isExpanded) => {
    setIsRecommendationsAccordionExpanded(isExpanded);
  };

  const showInfoIcon = (item) => {
    if (!item.fixed && item.expansion_recommended) {
      return <Box className={classes.circularBorder}><OpenInFullIcon
        className={classes.recommendationInfoIcon}/></Box>
    }
    if (!item.fixed) {
      return <Box className={classes.circularBorder}><ThumbUpIcon
        className={classes.recommendationInfoIcon}/></Box>
    }
    if (item.fixed) {
      return <Box className={classes.circularBorder}><PinDropIcon
        className={classes.recommendationInfoIcon}/></Box>
    }
  }

  const handleSelectAllCheckboxChange = (event) => {
    const checked = event.target.checked;
    setSelectAllChecked(checked);
    const selectedIndexes = checked ? rows.map((row, index) => index) : [];
    setSelectionModel(selectedIndexes);
  };

  const handleOpenComputePOModal = () => {
    dispatch(setWidgetFiltersForTargetDemand([]));
    navigate(`${location.pathname}?recommendations=compute`, {
      replace: true,
    });
  }

  const handleOpenGenerateRecommendationsModal = () => {
    dispatch(setWidgetFiltersForCurrentOffer([]));
    navigate(`${location.pathname}?recommendations=generate`, {
      replace: true,
    });
  }

  const handleReGenerateRecommendations = () => {
    const payloadToGenerateRecommendations = _.cloneDeep(updatedWidget);
    payloadToGenerateRecommendations.params.budget = updatedWidget.params.budget || 1;
    payloadToGenerateRecommendations.params.expansion_capacity = updatedWidget.params.expansion_capacity || updatedWidget.params.expanxionCapacity;
    payloadToGenerateRecommendations.params.expansion_cost = updatedWidget.params.expansion_cost || updatedWidget.params.expanxionCost;
    payloadToGenerateRecommendations.params.w_var = updatedWidget.params.w_var || updatedWidget.params.welfareVariable;
    payloadToGenerateRecommendations.params.quant_var = updatedWidget.params.quant_var || updatedWidget.params.densityVariable;
    payloadToGenerateRecommendations.params.custom_w_var = updatedWidget.params.custom_w_var || updatedWidget.params.welfareFormula;
    payloadToGenerateRecommendations.recommended_expansion = updatedWidget.params.recommended_expansion || updatedWidget.params.recommendedExpansion
    dispatch(setRecomputedRecommendationsWidgets(payloadToGenerateRecommendations));
    setProcessJobName('generate');

    handleGenerateRecommendations(
      payloadToGenerateRecommendations.params.budget,
      payloadToGenerateRecommendations.params.quant_var,
      payloadToGenerateRecommendations.recommended_expansion,
      payloadToGenerateRecommendations.params.expansion_cost,
      payloadToGenerateRecommendations.params.expansion_capacity,
      payloadToGenerateRecommendations.params.isWelfareFormulaSelected
    );

    dispatch(setRecomputedRecommendationsWidgets(payloadToGenerateRecommendations));

    const widgetFiltersForCurrentOfferResp = getWidgetsWithFiltersByLayerId(updatedWidget.params.currentOfferLayerName, sources, layers, widgets);
    const widgetFiltersForTargetDemandResp = getWidgetsWithFiltersByLayerId(selectedWidgetEntity.layer, sources, layers, widgets);
    dispatch(setWidgetFiltersForTargetDemand(
      widgetFiltersForTargetDemandResp.map(obj => ({...obj}))
    ));
    dispatch(setWidgetFiltersForCurrentOffer(
      widgetFiltersForCurrentOfferResp.map(obj => ({...obj}))
    ));
  }

  const widgetFiltersNumberForTargetDemand = useMemo(
    () => {
      return getWidgetsWithFiltersByLayerId(selectedWidgetEntity?.layer, sources, layers, widgets).length;
    },
    [selectedWidgetEntity?.layer, sources, layers, widgets]
  );

  const widgetFiltersNumberForCurrentOffer = useMemo(
    () => {
      return getWidgetsWithFiltersByLayerId(updatedWidget?.params?.currentOfferLayerName, sources, layers, widgets).length;
    },
    [updatedWidget?.params?.currentOfferLayerName, sources, layers, widgets]
  );

  const handleClick = ()=> {
    dispatch(setSelectWidgetId(widget.id));
  }

  return (
    <Grid item xs={12}>
      <Loader
        loaderType={'progress'}
        progress={progress}
        loading={privateLoading}
        polygonDataLoading={privateLoading}
        notificationMessage={notificationMessage}
        headerMessage={headerMessage}
        titleMessage={titleMessage}
      />
      <div className={classes.root}>
        <FormProvider {...methods}>
          <form>
            <Grid container style={{marginTop: 20, width: '406px'}}>
              <Accordion
                className={classes.accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon onClick={handleClick}/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.accordionSummary}
                >
                  <Typography
                    className={classes.accordionHeader}> {t('current_config')} </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Grid container direction='column'>
                    <Grid item xs={12}>
                      <div
                        className={classes.recommendationsCandidatesHeaderContainer}>
                        <Typography
                          className={classes.recommendations_candidates_header}> {t('service_point_config')} </Typography>
                        <IconButton
                          className={classes.mapIconButton}
                          onClick={handleOpenComputePOModal}
                          disabled={!widget.params.allowsRegenerateServicePoint}
                        >
                          <CreateIcon className={classes.pencilIcon} onClick={handleClick}/>
                        </IconButton>
                      </div>
                      <div className={classes.recommendationsCandidatesData}
                           spacing={2}>
                        <Grid container style={{gap: '12px'}}>
                          <Grid item xs={6} className={classes.gridRecommendationsSummary}>
                            <VariableInfo
                              label={widget.params?.customLabels?.coverageRadius || t('coverage_radius')}
                              filterData={(updatedWidget?.params?.coverage || updatedWidget?.params?.coverage === 0) ? updatedWidget?.params?.coverage : updatedWidget.params?.offerCoverageRadius}
                              name="(coverage)"
                              information="recommendations_offer_coverage_radius"/>
                          </Grid>
                          <Grid item xs={6} className={classes.gridRecommendationsSummary}>
                            <VariableInfo
                              label={widget.params?.customLabels?.cost || t('cost')}
                              filterData={(updatedWidget?.params?.offer_cost || updatedWidget?.params?.offer_cost === 0) ? updatedWidget?.params?.offer_cost : updatedWidget?.params?.offerCost}
                              name={`(${t('unit').toUpperCase()})`}
                              information="recommendations_offer_cost"/>
                          </Grid>
                          <Grid item xs={6} className={classes.gridRecommendationsSummary}>
                            <VariableInfo
                              label={widget.params?.customLabels?.recommendationsOfferCapacity || t('recommendations_offer_capacity')}
                              filterData={(updatedWidget?.params?.offer_capacity || updatedWidget?.params?.offer_capacity === 0) ? updatedWidget?.params?.offer_capacity : updatedWidget?.params?.offerCapacity}
                              name="(capacity_lower_bound)"
                              information="recommendations_offercapacity_tooltip"/>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  {/* second element */}
                  <Divider className={classes.sectionDivider}/>
                  <Grid container direction='column'>
                    <Grid item xs={12}>
                      <div
                        className={classes.recommendationsCandidatesHeaderContainer}>
                        <Typography
                          className={classes.recommendations_candidates_header}> {t('recommendations_computations_header')} </Typography>
                        <Box>
                          {((widgetFiltersNumberForTargetDemand > 0 || widgetFiltersNumberForCurrentOffer > 0) &&
                            <IconButton
                              className={classes.mapIconButton}
                              onClick={
                                handleReGenerateRecommendations
                              }
                            >
                              <RefreshIcon className={classes.pencilIcon}/>
                            </IconButton>)}

                          <IconButton
                            className={classes.mapIconButton}
                            onClick={handleOpenGenerateRecommendationsModal}
                          >
                            <CreateIcon className={classes.pencilIcon} onClick={handleClick}/>
                          </IconButton>
                        </Box>
                      </div>
                      <div className={classes.recommendationsCandidatesData} spacing={2}>
                        <Grid container style={{gap: '12px'}}>
                          <Grid item xs={6} className={classes.gridRecommendationsSummary}>
                            <VariableInfo
                              label={widget.params?.customLabels?.recommendationsBudget || t('budget')}
                              filterData={updatedWidget?.params?.budget}
                              name={`(${t('unit').toUpperCase()})`}
                              information="recommendations_budget_info"/>
                          </Grid>
                          <Grid item xs={6} className={classes.gridRecommendationsSummary}>
                            <VariableInfo
                              label={widget.params?.customLabels?.recommendationsExpansionCapacity || t('recommendations_expansion_capacity_box')}
                              filterData={updatedWidget?.params?.expansion_capacity ? updatedWidget?.params?.expansion_capacity : updatedWidget?.params?.expanxionCapacity}
                              name="(expanded_capacity)"
                              information="recommendations_expansion_capacity_info"/>
                          </Grid>
                          <Grid item xs={6} className={classes.gridRecommendationsSummary}>
                            <VariableInfo
                              label={widget.params?.customLabels?.recommendationsExpansionCost || t('recommendations_expansion_cost')}
                              filterData={updatedWidget?.params?.expansion_cost ? updatedWidget?.params?.expansion_cost : updatedWidget?.expanxionCost}
                              name={`(${t('unit').toUpperCase()})`}
                              information="recommendations_expansion_cost_info"/>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  {(wasComputedWithFilters &&
                    <Divider className={classes.sectionDivider}/>)}
                  {(wasComputedWithFilters &&
                    <Grid container direction='column'>
                      {(wasComputedWithFiltersForCurrentOffer &&
                        <Grid item xs={12}>
                          <Box>
                            <IndicatorOfFiltersAppliedOnAlayer
                              header={t('filter_over_service_point')}
                              widgetsFilteringTargetDemmand={widget.params.isGlobal ? widget.custom.widgetFiltersForCurrentOffer || [] : widget.params.widgetFiltersForCurrentOffer || []}
                            />
                          </Box>
                        </Grid>)}
                      {(wasComputedWithFiltersForTargetDemand &&
                        <Grid item xs={12}>
                          <Box
                            className={!wasComputedWithFiltersForTargetDemand ? classes.filterOverDemandContainerWithoutMargin : classes.filterOverDemandContainer}>
                            <IndicatorOfFiltersAppliedOnAlayer
                              header={t('filter_on_demand')}
                              widgetsFilteringTargetDemmand={widget.params.isGlobal ? widget.custom.widgetFiltersForTargetDemand || [] : widget.params.widgetFiltersForTargetDemand || []}
                            />
                          </Box>
                        </Grid>)}
                    </Grid>)}
                  <GenerateOrComputeRecommendations
                    defaultValues={customData}
                    dataForOP={dataForOP}
                    dataForRecom={dataForRecom}
                    widget={widget}
                    setDataForRecom={setDataForRecom}
                    setDataForOP={setDataForOP}
                    widgetId={id}
                    setProcessJobName={setProcessJobName}
                  />
                </AccordionDetails>
              </Accordion>
              <div className={classes.accordion}>
                <Grid container
                      className={classes.recommendationsTableHeaderContainer}>
                  <Grid item xs={12} sm={12}>
                    <Box height={52} display="flex" alignItems={"center"}
                         justifyContent={"space-between"}>
                      <Typography className={classes.widget_sub_header}>
                        {t('recommendations_widget_title')}
                      </Typography>
                      <>
                        {(
                          selectionModel.length !== 0 ?
                            <IconButton
                              onClick={handleFilterRecommendations}
                              disabled={selectionModel.length === 0}
                              className={`${classes.btnHover} ${classes.filterListIcon}`}
                            >
                              <FilterListIcon/>
                            </IconButton>
                            :
                            <FilterRecommendationsPopover
                              offerTypes={offerTypesFilter.offerTypes}
                              setOfferTypes={offerTypesFilter.setOfferTypes}
                              idSortedType={sortedTypeById.idSortedType}
                              setIdSortedType={sortedTypeById.setIdSortedType}
                              selectedColumnToOrder={sortedTypeByColumn.selectedColumnToOrder}
                              setSelectedColumnToOrder={sortedTypeByColumn.setSelectedColumnToOrder}
                              columnsToOrder={columnsToOrder}
                              columnSortedType={sortedTypeByColumn.columnSortedType}
                              setColumnSortedType={sortedTypeByColumn.setColumnSortedType}
                              runFilterHandler={runFilterHandler}
                            />
                        )}
                      </>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    {/* inicio componente */}
                    <TableContainer>
                      <Table aria-label="collapsible table">
                        <TableHead className={classes.tableHeader}>
                          <TableRow>
                            <TableCell style={{paddingLeft: 16}}
                                       className={classes.dividerCell}>
                              <Checkbox
                                classes={{
                                  root: classes.checkBoxAttr,
                                  checked: classes.checked,
                                  colorSecondary: classes.colorSecondary,
                                }}
                                checked={selectAllChecked}
                                color="secondary"
                                onChange={handleSelectAllCheckboxChange}
                              />
                            </TableCell>
                            <TableCell className={classes.dividerCell}>
                              <div style={{width: 53}}
                                   className={`${classes.borderedCell} ${classes.tableHeaderLabel}`}>{t('recommendations_list_type_header')}</div>
                            </TableCell>
                            <TableCell align="center"
                                       className={classes.dividerCell}>
                              <div style={{width: 94}}
                                   className={`${classes.borderedCell} ${classes.tableHeaderLabel}`}>{t('recommendations_priority_header')}</div>
                            </TableCell>
                            <TableCell align="center"
                                       className={classes.dividerCell}>
                              <div style={{width: 140}}
                                   className={`${classes.borderedCell} ${classes.tableHeaderLabel}`}>{t('recommendations_pc_header')}</div>
                            </TableCell>
                            <TableCell/>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((recommendationItem, rowIndex) => (
                              <React.Fragment
                                key={page * rowsPerPage + rowIndex}>
                                <TableRow>
                                  <TableCell align="center"
                                             className={classes.tableCell}
                                             style={{width: 69}}>
                                    <Box style={{paddingLeft: 16}}
                                         display='flex'
                                         justifyContent="space-between"
                                         alignItems="center">
                                      <Checkbox
                                        classes={{
                                          checked: classes.checked,
                                          colorSecondary: classes.colorSecondary,
                                          root: classes.checkBoxAttr
                                        }}
                                        color="secondary"
                                        checked={selectionModel.includes(page * rowsPerPage + rowIndex)}
                                        onChange={(event) => handleDataCheckboxChange(event, rowIndex)}
                                      />
                                      <GridActionsCellItem
                                        icon={(
                                          <VisibilityIcon
                                            className={classes.displayRecommendationIcon}
                                            style={{
                                              fill: recommendationItem.id === viewSelected ? localStorage.getItem('colorPrimary') : GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[600],
                                            }}
                                          />
                                        )}
                                        onClick={() => {
                                          setViewSelected(recommendationItem.id);
                                          onSelect({
                                            lat: recommendationItem.geom.coordinates[1],
                                            lon: recommendationItem.geom.coordinates[0]
                                          });
                                        }}
                                        label='Navigate'
                                        color='primary'
                                      />
                                    </Box>

                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCell} ${classes.iconsAttrs}`}
                                    style={{width: 69}}>
                                    {showInfoIcon(recommendationItem)}

                                  </TableCell>
                                  <TableCell align="center"
                                             className={classes.tableCell}>
                                    {recommendationItem.w_var_covered}
                                  </TableCell>
                                  <TableCell align="center"
                                             className={classes.tableCell}>
                                    {recommendationItem.quant_var_covered}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    <IconButton aria-label="expand row"
                                                size="small"
                                                onClick={() => handleRowClick(rowIndex)}>
                                      {openRow === rowIndex ?
                                        <KeyboardArrowUpIcon/> :
                                        <KeyboardArrowDownIcon/>}
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                    paddingLeft: 16
                                  }} colSpan={5}>
                                    <Collapse in={openRow === rowIndex}
                                              timeout="auto" unmountOnExit>
                                      <Grid item container>
                                        <Grid item xs={12} sm={6}>
                                          <div>
                                            <Typography variant="overline"
                                                        className={classes.recomendationsVariables}> {t('recommendations_identifier_col_data')} </Typography>
                                            <ul
                                              className={classes.unorderedListContainer}>
                                              <li><Typography
                                                variant="body2">{recommendationItem.ido}</Typography>
                                              </li>
                                            </ul>
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <div>
                                            <Typography variant="overline"
                                                        className={classes.recomendationsVariables}> {t('recommendations_expanded_capacity_col_data')} </Typography>
                                            <ul
                                              className={classes.unorderedListContainer}>
                                              <li><Typography
                                                variant="body2">{recommendationItem.expanded_capacity}</Typography>
                                              </li>
                                            </ul>
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <div>
                                            <Typography variant="overline"
                                                        className={classes.recomendationsVariables}> {t('recommendations_population_covered_col_data')} </Typography>
                                            <ul
                                              className={classes.unorderedListContainer}>
                                              <li><Typography
                                                variant="body2">{recommendationItem.quant_var_covered}</Typography>
                                              </li>
                                            </ul>
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <div>
                                            <Typography variant="overline"
                                                        className={classes.recomendationsVariables}> {t('recommendations_expansion_cost_col_data')} </Typography>
                                            <ul
                                              className={classes.unorderedListContainer}>
                                              <li><Typography
                                                variant="body2">{recommendationItem.expansion_cost}</Typography>
                                              </li>
                                            </ul>
                                          </div>
                                        </Grid>

                                      </Grid>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>

                              </React.Fragment>
                            ))
                          }

                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div style={{textAlign: 'left'}}>
                      <TablePagination
                        rowsPerPageOptions={[7]}
                        component="div"
                        count={dataRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        onPageChange={handleChangePage}
                        labelDisplayedRows={({
                                               from,
                                               to,
                                               count
                                             }) => `Showing ${from} to ${to} of ${count} results`}
                        styles={{
                          '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                            marginRight: '17px',
                            marginLeft: '10px',
                            fontSize: '12px',
                            color: '#64748B',

                          },
                        }}
                      />
                    </div>
                    {/* fin del componente */}

                  </Grid>
                </Grid>
              </div>
            </Grid>
          </form>
        </FormProvider>
      </div>
    </Grid>
  );

};

export default RecommendationsWidgetUI;
