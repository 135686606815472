import * as React from 'react';
import swap from 'lodash-move';
import {
  makeStyles,
  Grid,
  Typography,
  Checkbox,
} from '@material-ui/core';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton, ListItemIcon, ListItemText,
  TextField
} from '@mui/material';

import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from '../../../providers/TranslationProvider';
import {
  MuiListColumns,
  outlinedInputStyle,
  dragHandleStyle,
  typographyCommonStyle,
  gridInputStyle,
  gridFieldStyle,
  MuiSearchColumnField,
  MuiListVisibleColumns,
  MuiTransferArrowsRightIcon,
  MuiTransferArrowsLeftIcon } from './styles/transferListSort';

const useStyles = makeStyles((theme) => ({
  containerControls: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    justifySelf: 'end',
    display: 'flex',
    marginLeft: '2vh',
  },
  formControl: {
    position: 'relative',
    marginBottom: theme.spacing(3),
    width: '100%',
    paddingTop: '12px',
  },
  inputLabel: {
    position: 'absolute',
    top: '-8px',
    left: '14px',
    backgroundColor: '#fff',
    padding: '0 4px',
    zIndex: 1,
  },
  listContainer: {
    border: '1px solid',
    borderRadius: '4px',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    position: 'relative',
  },
  zIndex: {
    zIndex: theme.zIndex.tooltip,
  },
}));

const DragHandle = SortableHandle(() => (
  <DragHandleIcon color='primary' style={dragHandleStyle} />
));

const SortableItem = SortableElement(
  ({ item, action, type, handleEditName, handleShowColumn }) => {
    const { t } = useTranslation();

    const isDisabled = () => action === 'update' && type === 'polygon' ? true : false

    return (
      <Grid container>
        <Grid item xs={4}>
          <DragHandle />
          <Checkbox
            style={{marginTop:'1.8rem'}}
            checked={item.show}
            color="primary"
            disabled={isDisabled()}
            onChange={(e) =>
              handleShowColumn(item.id, item.show, e.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
        <Grid item xs={8} style={gridFieldStyle}>
          <Grid item xs={5}>
            <Typography variant='body1'
                        noWrap
                        style={{
                          ...typographyCommonStyle,
                          marginTop: '1rem',
                        }}
            >
              {item.field}
            </Typography>
          </Grid>
          <Grid item xs={7} style={gridInputStyle}>
            <TextField
              variant='outlined'
              type='text'
              label={t('tooltip_label')}
              defaultValue={item.name}
              InputProps={{
                style: typographyCommonStyle,
              }}
              style={outlinedInputStyle}
              onChange={(e) => {
                handleEditName(item.id, item.name, e.target.value)
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

const SortableList = SortableContainer(
  ({ items,right, handleEditName, handleShowColumn, keyLabel,
                     handleShowAll, handleHideAll, searchWidthInPixel, action,
                     type, leftColumnsWidthInPixel, rightColumnsWidthInPixel}) => {
    const [searchTerm, setSearchTerm] = React.useState('');

    const classes = useStyles();
    const { t } = useTranslation();

    const handleSearchChange = (event) => setSearchTerm(event.target.value);

    const filteredItems = items.filter((item) =>
      item?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const listColumns = () => (
      <MuiListColumns
        elevation={0}
        listColumnsWidth={leftColumnsWidthInPixel}
      >
        <MuiSearchColumnField
          searchWidth={searchWidthInPixel}
          fullWidth
          variant="outlined"
          placeholder={t('search')}
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <List dense component="div" role="list" >
          {filteredItems.filter(item => !item.show).map((item) => {
            const labelId = `transfer-list-item-${item}-label`;
            return (
              <ListItemButton
                key={item}
                role="listitem"
                sx={{marginLeft:'-20px'}}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={item.show}
                    tabIndex={-1}
                    disableRipple
                    onChange={(e) =>
                      handleShowColumn(item.id, item.show, e.target.checked)}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId}
                              primary={` ${item.name}`}
                              sx={{marginLeft:'-10px'}}
                              primaryTypographyProps={typographyCommonStyle}
                />
              </ListItemButton>
            );
          })}
        </List>
      </MuiListColumns>
    );

    const listVisibleColumns = (columns) => (
      <MuiListVisibleColumns visibleColumnsWidth={rightColumnsWidthInPixel}>
        <List dense component="div" role="list">
          {columns.map((item, index) => {
            return (
              <SortableItem
                key={item.id}
                index={index}
                item={item}
                action={action}
                type={type}
                handleEditName={handleEditName}
                handleShowColumn={handleShowColumn}
              />
            );
          })}
        </List>
      </MuiListVisibleColumns>
    );

    return (
      <FormControl className={classes.formControl} variant="outlined">
        <InputLabel shrink className={classes.inputLabel}>
          {t(keyLabel)}
        </InputLabel>
        <Grid container className={classes.listContainer}>
          <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
            <Grid item>{listColumns()}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <MuiTransferArrowsRightIcon onClick={handleShowAll} fontSize='medium'/>
                <MuiTransferArrowsLeftIcon onClick={handleHideAll} fontSize='medium'/>
              </Grid>
            </Grid>
            <Grid item>{listVisibleColumns(right)}</Grid>
          </Grid>
        </Grid>
      </FormControl>
    );
  }
);

const TransferListSort = ({items,right, setItems,setRight ,setOrderSelection,
                       action , changeDetectedShow, changeDetectedName, type,
                       changeDetectedOrder, searchWidthInPixel, leftColumnsWidthInPixel,
                        rightColumnsWidthInPixel, keyLabel}) => {
  const classes = useStyles();
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setOrderSelection(swap(right, oldIndex, newIndex));
  };

  const handleEditName = (id, name, newName) => {
    if (action === 'update') changeDetectedName(true)
    const newValue = right.map((item) => item.id === id ? { ...item, name: newName} : item);
    setRight(newValue);
  };

  const handleShowColumn = (id, show, checked) => {
    if (action === 'update') {
      changeDetectedShow(true)
      changeDetectedOrder(true)
    }

    if(checked){
      const newValue = items.filter((item) => item.id === id)?.map((item) => ({ ...item, show: checked}));
      right.push(newValue[0])
      setItems(items.filter((item) => item.id !== id)?.map((item) => ({ ...item})));
    }else{
      let newValue = right.filter((item) => item.id === id)?.map((item) => ({ ...item, show: checked}));
      newValue = newValue.concat(items)
      setItems(newValue)
      setRight(right.filter((item) => item.id !== id)?.map((item) => ({ ...item})))
    }
  };

  const handleShowAll = () => {
    const newValue = items.map((item) => ({ ...item, show: true}));
    if (action === 'update') changeDetectedShow(true)
    setRight(newValue);
    setItems([]);
  }

  const handleHideAll = () => {
    let newValue = right.map((item) => ({ ...item, show: false}));
    if (action === 'update') changeDetectedShow(true)
    newValue = newValue.concat(items)
    setItems(newValue)
    setRight([]);
  }

  return (
    <SortableList
      items={items}
      right={right}
      onSortEnd={onSortEnd}
      useDragHandle
      helperClass={classes.zIndex}
      handleEditName={handleEditName}
      handleShowColumn={handleShowColumn}
      handleShowAll={handleShowAll}
      handleHideAll={handleHideAll}
      searchWidthInPixel={searchWidthInPixel}
      leftColumnsWidthInPixel={leftColumnsWidthInPixel}
      rightColumnsWidthInPixel={rightColumnsWidthInPixel}
      keyLabel={keyLabel}
      action={action}
      type={type}
    />
  );
};

export default TransferListSort;
