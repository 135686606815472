import * as React from 'react';
import { useMemo } from 'react';

import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import { Tune } from '@mui/icons-material';
import { useTranslation } from '../../../../../../../providers/TranslationProvider';
import { MuiDeleteIcon } from '../../../layer/styles/layersSort';
import {
  commonFieldStyle,
  dragStyles,
  formTypeStyle,
  formValueStyle,
  labelStyle,
  MuiNewParameter,
  MuiTypographyCommon,
  nameFieldStyle,
  outlinedStyle,
  parameterTypeStyle,
  proNameFieldStyle,
  styles
} from '../styles/parameters';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import swap from 'lodash-move';
import { useSelector } from 'react-redux';
import { widgetType } from 'utils/constants';

const AddIconButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent', // Replace with your desired hover color
    },
  },
}))(IconButton);

const OutlinedContainer=withStyles((theme)=>({
  root: {
    height:'88px',
    width:'940px',
    borderRadius: '12px',
    border: '1px dashed #E0E0E0',
    marginTop:'20px'
  },
}))(Grid)

const useStyles = makeStyles(styles);

const DragHandle = SortableHandle(() => (
  <DragHandleIcon color='primary' style={dragStyles} />
));

const SortableParameter = SortableElement(
  ({
    parameter,
    updateParameter,
    deleteParameter,
    id,
    mode = 'create',
    isParameterBeingUsed
  }) => {

  const {t} = useTranslation();

  const useBooleanSwitchStyles = makeStyles((theme) => ({
    root: {
      width: 88,
      height: 32,
      marginLeft:8,
      padding: 0,
      display: 'flex',
      '&:active $thumb': {
        width: 12,
      },
      '&:active $switchBaseChecked': {
        transform: 'translateX(9px)',
      },
    },
    switchBase: {
      color:theme.palette.primary.main,
      padding: 2,
      '&$checked': {
        transform: 'translateX(40px)',
        color: theme.palette.primary.main,
        '&$checked + $track': {
          border: '1px solid',
          background: '#DFF2FC',
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 36,
      height: 24,
      borderRadius: 12,
      marginTop:2,
      marginLeft:4,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
      '&:before': {
        content: ({ index }) =>  `"${index === 1 ? t('key_yes') : t('key_no')}"`,
        color: 'white',
        position: 'absolute',
        left: 15,
        top: '50%',
        transform: 'translateY(-50%)',
        fontSize: 10,
      },
    },
    track: {
      background: '#DFF2FC',
      opacity: 1,
      borderRadius: '20px',
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      boxSizing: 'border-box',
      position: 'relative',
      '&:before': {
        content: `"${t('key_no')}"`,
        color:theme.palette.primary.main,
        position: 'absolute',
        left: 12,
        top: '50%',
        transform: 'translateY(-50%)',
        fontSize: 10,
      },
      '&:after': {
        content: `"${t('key_yes')}"`,
        position: 'absolute',
        right: 15,
        top: '50%',
        transform: 'translateY(-50%)',
        fontSize: 10,
        color:theme.palette.primary.main,
      },
    },
    thumbChecked: {
      color: '#FFFFFF',
    },
    checked: {},
    switchBaseChecked: {},
  }));

  const classes = useStyles({ type: parameter.type });
  const classesBooleanSwitch = useBooleanSwitchStyles({ index: parameter.value });

  return (
    <OutlinedContainer container item direction='row' alignItems='center'
                       spacing={2}>
      <Grid item style={proNameFieldStyle}>
        <DragHandle />
        <Typography variant='body1' style={{marginTop:3}}>
          {parameter.propName}
        </Typography>
      </Grid>
      <Grid item style={nameFieldStyle}>
        <FormControl>
          <TextField
            label='Param. Name'
            placeholder='Enter a user-friendly name for the parameter'
            variant='outlined'
            value={parameter.label}
            onChange={(event) => {
              if (event.target.value) {
                const updatedParameter = {...parameter};
                updatedParameter.label = event.target.value;
                updateParameter(updatedParameter);
              }
            }
            }
            InputLabelProps={{ style: labelStyle }}
          />
        </FormControl>
      </Grid>
      <Grid item style={parameterTypeStyle}>
        <FormControl style={formTypeStyle}>
          <MuiTypographyCommon variant="caption" gutterBottom >
            {t('parameter_type')}
          </MuiTypographyCommon>
          <FormControlLabel
            control={
              <Switch
                name={`type-switch-${id}`}
                checked={parameter.type === "Boolean"}
                onChange={(event) => {
                  const updatedParameter = {...parameter};
                  let type = event.target.checked ? "Boolean" : "Numeric";
                  updatedParameter.type = type;
                  if (type === "Boolean") {
                    updatedParameter.minValue = 0;
                    updatedParameter.maxValue = 1;
                    if (updatedParameter.value !== 0 && updatedParameter.value !== 1) {
                      updatedParameter.value = 0;
                    }
                  }
                  updateParameter(updatedParameter);
                }}
                inputProps={{'aria-label': 'controlled'}}
                classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
              />
            }
            labelPlacement="end"
          />
        </FormControl>
      </Grid>
      {parameter.type === "Numeric" ? (
        <>
          <Grid item style={commonFieldStyle}>
            <FormControl>
              <TextField
                type='number'
                label={'Min. value'}
                style={{width: '135px'}}
                variant='outlined'
                value={parseFloat(parameter.minValue)}
                inputProps={{
                  max: parameter.maxValue,
                  step: 10
                }}
                onChange={(event) => {
                  const updatedParameter = {...parameter}
                  updatedParameter.minValue = parseFloat(event.target.value);
                  updateParameter(updatedParameter);
                }}
                InputLabelProps={{ style: labelStyle }}
              />
            </FormControl>
          </Grid>
          <Grid item style={commonFieldStyle}>
            <FormControl>
              <TextField
                label={'Max. value'}
                style={{width: '135px'}}
                variant='outlined'
                value={parseFloat(parameter.maxValue)}
                inputProps={{
                  min: parseFloat(parameter.minValue),
                  step: 10,
                  type:'number'
                }}
                onChange={(event) => {
                  const updatedParameter = {...parameter}
                  updatedParameter.maxValue = parseFloat(event.target.value);
                  updateParameter(updatedParameter);
                }}
                InputLabelProps={{ style: labelStyle }}
              />
            </FormControl>
          </Grid>
          <Grid item style={commonFieldStyle}>
            <FormControl>
              <TextField
                type='number'
                label={'Default value'}
                style={{width: '135px'}}
                variant='outlined'
                value={parseFloat(parameter.value)}
                inputProps={{
                  min: parameter.minValue,
                  max: parameter.maxValue,
                  step: parameter.type === "Boolean" ? 1 : ((parameter.maxValue - parameter.minValue) / 10)
                }}
                onChange={(event)=>{
                  const updatedParameter = {...parameter}
                  updatedParameter.value = parseFloat(event.target.value);
                  updateParameter(updatedParameter);
                }}
                InputLabelProps={{ style: labelStyle }}
              />
            </FormControl>
          </Grid>
        </>
      ) : (
        <Grid item>
          <FormControl style={formValueStyle}>
            <MuiTypographyCommon variant="caption" gutterBottom >
              {t('default_value')}
            </MuiTypographyCommon>
            <FormControlLabel
              control={
                <Switch
                  name={`value-switch-${id}`}
                  checked={parameter.value === 1}
                  onChange={(event) => {
                    const updatedParameter = {...parameter};
                    updatedParameter.value = event.target.checked ? 1 : 0;
                    updateParameter(updatedParameter);
                  }}
                  inputProps={{'aria-label': 'controlled'}}
                  color="default"
                  classes={{
                    root: classesBooleanSwitch.root,
                    switchBase: classesBooleanSwitch.switchBase,
                    thumb: classesBooleanSwitch.thumb,
                    track: classesBooleanSwitch.track,
                    checked: classesBooleanSwitch.checked,
                  }}
                />
              }
              labelPlacement="end"
            />
          </FormControl>
        </Grid>
      )}
      { !isParameterBeingUsed( parameter.propName ) && (
        <Grid item>
          <MuiDeleteIcon onClick={() => deleteParameter(parameter.propName)} />
        </Grid>
      )}
    </OutlinedContainer>
  )
});

const SortableParametersList = SortableContainer(
  ({items, addParameter, updateParameter, deleteParameter,
                   mode = 'create', isParameterBeingUsed}) => {

  const {t} = useTranslation();

  return (
    <Grid container justifyContent='center' direction='column' alignContent='top'
          spacing={2}>
      <Grid container item direction='column'>
        {items?.map((item, i) => {
          return (
            <SortableParameter
              key={item.propName}
              index={i}
              parameter={item}
              updateParameter={updateParameter}
              deleteParameter={deleteParameter}
              id={i}
              mode={mode}
              isParameterBeingUsed={isParameterBeingUsed}/>
          )
        })}
      </Grid>
      <OutlinedContainer container justifyContent='center' alignContent='center'
                         style={outlinedStyle}>
          <Grid item>
            <AddIconButton onClick={()=>addParameter()}>
              <Tune sx={{ color:'#757575' }}/>
              <MuiNewParameter variant='h6'>
                {t('new_parameter')}
              </MuiNewParameter>
            </AddIconButton>
          </Grid>
      </OutlinedContainer>
    </Grid>
  )

});

const Parameters = ({parameters, updateParameters, mode = 'create'}) => {
  const classes = useStyles();
  const widgets = useSelector((state) => state.app.widgets);

  const parametersBeingUsed = useMemo(() => {
    const referencedParameters = [];
    let founded = false;
    let generatedRegex = null;
    parameters?.forEach(p => {
      const widgetUsingCurrentParameter = widgets.find(w => {
        if ( w.params?.calculator || w.type !== widgetType.RECOMMENDATIONS || w.type !== widgetType.STATISTICS || w.type !== widgetType.LIST ) {
          if ( w.type === widgetType.HISTOGRAM ) {
            generatedRegex = generateRegexForVariable(p.propName);
            founded = w.params.field.match(generatedRegex);
            return !!founded;
          } else if( w.type === widgetType.LIST ) {
            founded = false;
            const parameterUsedInListWidget = w.params.list.find( wItem => {
              if (wItem.calculator) {
                generatedRegex = generateRegexForVariable(p.propName);
                founded = wItem.field.match(generatedRegex);
              }
              return !!founded;
            });
            return !!parameterUsedInListWidget;
          } else if( w.type === widgetType.STATISTICS ) {
            const parameterUsedInStatsWidget = w.params.fields.find( wItem => {
              generatedRegex = generateRegexForVariable(p.propName);
              founded = wItem.field.match(generatedRegex);
              return !!founded;
            });
            return !!parameterUsedInStatsWidget;
          } else if( w.type === widgetType.RECOMMENDATIONS ) {
            generatedRegex = generateRegexForVariable(p.propName);
            founded = w.params?.targetDemands?.match(generatedRegex);
            if ( !founded ) {
              generatedRegex = generateRegexForVariable(p.propName);
              founded = w.params?.formulaWelfare?.match(generatedRegex);
              return !!founded;
            }
            return !!founded;
          }
        }
      });
      if ( widgetUsingCurrentParameter ) {
        referencedParameters.push(
          {
            widgetName: widgetUsingCurrentParameter.name,
            parameter: p.propName,
          }
        )
      }
    });
    return referencedParameters;
  }, [
    parameters, widgets
  ]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    updateParameters(swap(parameters, oldIndex, newIndex));
  };

  const updateParameter = (parameter) => {
    const toUpdate = parameters?.findIndex(p => p.propName === parameter.propName);
    const updatedList = [...parameters];
    if (toUpdate >= 0) {
      updatedList[toUpdate] = parameter;
      updateParameters(updatedList);
    }
  }

  const addParameter=()=>{
    const paramIndex=parameters?.length+1||1;
    const newParam = {
      propName: `P${paramIndex}`,
      label: "Parameter " + paramIndex,
      type: "Numeric",
      minValue: 0,
      maxValue: 100,
      value: 0
    }
    updateParameters([...parameters, newParam]);
  }

  function generateRegexForVariable(variable) {
    return new RegExp(`\\b${variable}\\b`, 'g');
  }

  const isParameterBeingUsed = ( parameterName ) => {
    return parametersBeingUsed.some((p) => {
      return p.parameter === parameterName;
    })
  }

  const deleteParameter = (propName) =>
    updateParameters(parameters?.filter(p => p.propName !== propName));

  return (
    <SortableParametersList
      useDragHandle
      items={parameters}
      onSortEnd={onSortEnd}
      addParameter={addParameter}
      helperClass={classes.zIndex}
      updateParameter={updateParameter}
      deleteParameter={deleteParameter}
      mode={mode}
      isParameterBeingUsed = {isParameterBeingUsed}
    />
  );
}
export default Parameters;
