import * as React from 'react';
import { Grid, Typography, makeStyles, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Toast from 'components/common/Toast';
import { useTranslation } from 'components/providers/TranslationProvider';

const useStyles = makeStyles((theme) => ({
  publicLink: {
    display: 'flex',
    background: theme.palette.primary.light.replace(')', ', 0.15)').replace('rgb', 'rgba'),
    borderRadius: theme.spacing(1.5),
    justifySelf: 'center',
    paddingLeft: theme.spacing(1),
    margin: theme.spacing(0),
    maxWidth: 700,
    maxHeight: theme.spacing(4.1),
  },
  icon: {
    color: theme.palette.primary.dark,
  },
  container: {
    margin: theme.spacing(0),
  },
}));

const CopyToClipBoard = ({ id, schemeId, type }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openToast, setOpenToast] = React.useState(false);
  const handleCloseToast = () => setOpenToast(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://${process.env.REACT_APP_HOST_URL}/public/${id}/${type}/${schemeId}`
    );
    setOpenToast(true);
  };
  return (
    <Grid item xs={11} className={classes.publicLink}>
      <Toast
        message={t('copied!')}
        handleClose={handleCloseToast}
        severity='success'
        horizontal='center'
        vertical='top'
        open={openToast}
        showTime={2000}
      />
      <Grid item xs={11} style={{ alignSelf: 'center' }}>
        <Typography noWrap>
          {`https://${process.env.REACT_APP_HOST_URL}/public/${id}/${type}/${schemeId}`}
        </Typography>
      </Grid>
      <Grid item xs={1} style={{ alignSelf: 'center' }}>
        <IconButton onClick={handleCopy} className={classes.icon}>
          <FileCopyIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CopyToClipBoard;
