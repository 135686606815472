const styles = (theme, isWidgetsPanelVisible) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
    position: 'relative',
    height: `calc(100% - ${theme.spacing(1)}px)`,
    width: isWidgetsPanelVisible ? `calc(100vw - 414px)` : '100vw',

    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - ${theme.spacing(12) - 1}px)`, // Minus 1 to fix that weirdly sometimes the bottom sheet is 1px lower than needed
    },

    [theme.breakpoints.up('sm')]: {
      height: '100%',
      '& .mapboxgl-map, & #deckgl-overlay, & > div': {
        borderRadius: theme.spacing(0.5),
      },
    },
  },
  tooltipDeckGL: {
    '& .content': {
      ...theme.typography.caption,
      position: 'relative',
      padding: theme.spacing(0),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      transform: `translate(-50%, calc(-100% - ${theme.spacing(2.5)}px))`,
      boxShadow: '0px 5px 15px 0px rgba(0,0,0,0.3)',
      left: '40%',

      '& .arrow': {
        display: 'block',
        position: 'absolute',
        top: 'calc(100% - 1px)',
        left: '10%',
        width: 0,
        height: 0,
        marginLeft: theme.spacing(0.1),
        borderRight: `${theme.spacing(1.3)}px solid transparent`,
        borderTop: `${theme.spacing(1.8)}px solid ${theme.palette.common.white}`,
      },
    },
  },
  tooltipGoogle: {
    '& .content': {
      ...theme.typography.caption,
      position: 'relative',
      padding: theme.spacing(0),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      transform: `translate(-50%, calc(-100% - ${theme.spacing(2.5)}px))`,
      boxShadow: '0px 5px 15px 0px rgba(0,0,0,0.3)',
      left: '40%',
      minWidth: 230,

      '& .arrow': {
        display: 'block',
        position: 'absolute',
        top: 'calc(100% - 1px)',
        left: '10%',
        width: 0,
        height: 0,
        marginLeft: theme.spacing(0.1),
        borderRight: `${theme.spacing(1.3)}px solid transparent`,
        borderTop: `${theme.spacing(1.8)}px solid ${theme.palette.common.white}`,
      },
    },
  },
  popup: {
    // backgroundColor: theme.palette.common.white,
    zIndex: theme.zIndex.tooltip,
    padding: 0,
  },
  popoverGoogle: {
    width: 200,
    minHeight: 120,
    '& .MuiPopover-paper': { display: 'inline-table' },
  },
});

export default styles;
