/* eslint-disable react/prop-types */
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles, Typography, ThemeProvider, Grid } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import themeIncluia from 'theme/incluia';
import { TextField } from 'components/common/ReactHooksFormFields';

import Button from 'components/common/Button';
import { useTranslation } from 'components/providers/TranslationProvider';
import Modal from 'components/common/Modal';
import Toast from 'components/common/Toast';

import styles from './styles/modalDelete';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {IconButton} from "@mui/material";
import {lighten} from "@mui/material/styles";

const useStyles = makeStyles(styles);

const schema = yup.object().shape({
  name: yup.string().required(),
  nameConfirmation: yup.string().oneOf(['delete','eliminar','supprimer','excluir',null], 'confirm_to_operation'),
});

const DeleteModal = ( props ) => {
  const {
    onSubmit,
    name,
    content,
    deleteMutation,
    isOrg = false,
    hideConfirmationField = false,
    hideConfirmationButton = false
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const {
    formState: { errors, isValid },
    setValue,
    reset,
  } = methods;

  // eslint-disable-next-line
  const query = new URLSearchParams(location.search);

  React.useEffect(() => {
    const deleteQuery = query.get('delete');
    setOpen(deleteQuery !== null);
    setValue('name', name);
    // eslint-disable-next-line
  }, [query]);

  const handleClose = () => {
    query.delete('delete');
    reset({ name: '', nameConfirmation: '' });
    navigate(`${location.pathname}${query && ''}${query}`, { replace: true });
  };

  React.useEffect(() => {
    if (deleteMutation.isSuccess) {
      handleClose();
    }
    if (deleteMutation.isError) {
      setError(t(deleteMutation.error.data?.error));
      setOpenToast(true);
    }
    // eslint-disable-next-line
  }, [deleteMutation.isSuccess, deleteMutation.isError]);

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const actions = (
    <>
      <Button
        onClick={handleClose}
        color='primary'
        variant='text'
        focusRipple
        className={classes.btnClose}
      >
        {t('cancel_btn')}
      </Button>
      {!hideConfirmationButton &&
        <Button
          onClick={onSubmit}
          type='submit'
          variant='outlined'
          loading={deleteMutation.isLoading}
          disabled={deleteMutation.isLoading || !isValid}
          className={classes.btnDelete}
          data-cy="sendRemovalRequest"
        >
          {t('delete')}
        </Button>
      }
    </>
  );

  const createMarkup = () => {
    return { __html: content };
  }

  const modalText = () => <div dangerouslySetInnerHTML={createMarkup()}/>;

  const contentModal = (
    <FormProvider {...methods}>
      <Grid container display='flex' direction='column' alignItems='center' justifyContent='center'>
        <Grid item xs={3} >
          <IconButton sx={{padding: '7px',
            color: '#ff1744',
            backgroundColor: lighten('#ff1744', 0.9),
            '&:hover': {
              backgroundColor: lighten('#ff1744', 0.5),
            }}}>
            <DeleteOutlinedIcon fontSize='medium'/>
          </IconButton>
        </Grid>
        <Grid item xs={9}>
          {modalText()}
        </Grid>
      </Grid>
      {isOrg && (
        <ul>
          {Array.from(Array(4).keys()).map((i) => (
            <li key={i}>
              <Typography className={classes.typographyOrgInfo}>
                {t(`org_delete_info_${i}`)}
              </Typography>
            </li>
          ))}
        </ul>
      )}
      <ThemeProvider theme={themeIncluia}>
        {
          !hideConfirmationField &&
          <TextField
            autoFocus
            name='nameConfirmation'
            margin='dense'
            type='text'
            label={t('confirm_to_operation')}
            variant='outlined'
            fullWidth
            error={t(errors.nameConfirmation?.message)}
            data-cy="deleteConfirmation"
          />
        }
      </ThemeProvider>
    </FormProvider>
  );

  return (
    <>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        open={openToast}
        vertical='top'
        horizontal='center'
      />
      <Modal open={open} onClose={handleClose}  isDelete actions={actions}>
        {contentModal}
      </Modal>
    </>
  );
};

export default DeleteModal;
