import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  SwipeableDrawer,
  Fab,
  Grid,
  Hidden,
  Portal,
  Snackbar,
  Toolbar,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { BASEMAPS } from 'lib/react-geo-tool/packages/react-basemaps/src/basemaps/basemaps';

import ZoomControl from 'components/common/ZoomControl';
import { setBottomSheetOpen, setError } from 'store/appSlice';
import cartoLogoMap from 'assets/img/carto-logo-map.svg';

import styles from './styles/main';

const useStyles = makeStyles(styles);

const Main = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const mobileContainer = React.useRef(null);
  const desktopContainer = React.useRef(null);
  const dispatch = useDispatch();
  const error = useSelector((state) => state.app.error);
  const bottomSheetOpen = useSelector((state) => state.app.bottomSheetOpen);
  const isGmaps = useSelector((state) => BASEMAPS[state.carto.basemap].type === 'gmaps');
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate('/orgs');
  }, [navigate]);

  const handleClose = () => dispatch(setError(null));

  const handleWidgetsDrawerToggle = () => dispatch(setBottomSheetOpen(!bottomSheetOpen));

  return (
    <Grid container direction='row' alignItems='stretch' item xs>
      <nav className={classes.drawer}>
        <Portal container={isMobile ? mobileContainer.current : desktopContainer.current}>
          <Outlet />
        </Portal>
        <Hidden xsDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant='permanent'
            PaperProps={{
              elevation: 8,
            }}
            open
          >
            <Toolbar variant='dense' />
            <Grid container item xs ref={desktopContainer} />
          </Drawer>
        </Hidden>
        <Hidden smUp implementation='css'>
          <SwipeableDrawer
            variant='persistent'
            anchor='bottom'
            open={bottomSheetOpen}
            onOpen={handleWidgetsDrawerToggle}
            onClose={handleWidgetsDrawerToggle}
            PaperProps={{
              className: `${classes.bottomSheet} ${!bottomSheetOpen ? classes.closed : ''}`,
              elevation: 8,
            }}
          >
            <div ref={mobileContainer} className={classes.bottomSheetContent} />
          </SwipeableDrawer>
          <Fab
            variant='extended'
            size='small'
            color='inherit'
            aria-label={bottomSheetOpen ? 'Hide' : 'Show'}
            className={`${classes.bottomSheetButton} ${!bottomSheetOpen ? classes.buttonShow : ''}`}
            onClick={handleWidgetsDrawerToggle}
          >
            <ExpandLessIcon className={classes.bottomSheetIcon} />
            {bottomSheetOpen ? 'Hide' : 'Show'}
          </Fab>
        </Hidden>
      </nav>

      <Grid item className={`${classes.mapWrapper} ${isGmaps ? classes.gmaps : ''}`}>
        <Hidden xsDown>
          <ZoomControl className={classes.zoomControl} />
        </Hidden>
        {!isGmaps && <img src={cartoLogoMap} alt='CARTO' className={classes.cartoLogoMap} />}
      </Grid>

      <Snackbar open={!!error} autoHideDuration={3000} onClose={handleClose}>
        <Alert severity='error'>{error}</Alert>
      </Snackbar>
    </Grid>
  );
};

export default Main;
