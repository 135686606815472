const styles = (theme) => ({
  xs6Container: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
  },
  xs12Container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
  },
  nameField: {
    padding: theme.spacing(0),
  },
  containerPaper: {
    width: '100%',
  },
  container: {
    marginTop: theme.spacing(2),
  },
  scenarioTypeContainer: {
    margin: theme.spacing(2, 0, 4),
    padding: theme.spacing(0, 0, 2),
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.primary.dark,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  checked: {},
  track: {},
});
export default styles;
