import { blueGrey } from '@material-ui/core/colors';
import { GLOBAL } from 'utils/constants';

const styles = (theme) => {
  return ({
    root: {
      width: 251,
      height: 309,
      borderRadius: 19,
      backgroundColor: '#f5f5f5',
      '&:hover': {
        backgroundColor: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[50],
      }
    },
    media: {
      height: 288,
      color: 'gray',
      margin: theme.spacing(1),
      backgroundSize: 'contain',
    },
    header: {
      position: 'relative',
      zIndex: 1200,
      backgroundColor: '#ffffff',
      height: 35,
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
    cardActionArea: {
      position: 'relative',
      justifyContent: 'center',
      height: '100%',
      cursor: 'default',
    },
    orgName: {
      color: blueGrey.A700,
      textDecoration: 'none',
    },
    orgNameTxt: {
      textAlign: 'center',
      width: '100%',
    },
    cardContentContainer: {
      display: 'flex',
    },
    parentCardContentContainer: {
      margin: 20,
      backgroundColor: 'white',
    },
    organizationContentAction: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    cardMediaContainer: {
      width: 219,
      height: 240,
    },
    cardContainer: {
      margin: 16
    },
    mediaItem: {
      width: 219,
      height: 219,
      margin: 'auto',
      backgroundSize: 'contain',
      backgroundColor: '#FFFFFF',
      borderRadius: 11,
    },
    orgNameTxtv2: {
      textAlign: 'left',
      fontSize: 16,
      fontWeight: 600,
    },
    orgDescription: {
      fontSize: 12,
    },
    organizationDescription: {
      marginTop: 14,
      marginBottom: 6,
      display: 'flex !important',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 48,
    },
    icon: {
      width: '17px !important',
      height: '17px !important',
      color:'#212121'
    },
    iconContainer: {
      display: 'flex',
    },
    organizationsButtons: {},
    nameContainer: {
      width: '100%',
      whiteSpace: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    marginAuto: {
      margin: 'auto'
    },
    skeleton: {
      borderRadius: 11,
    },
    iconButton: {
      '&:hover': {
        backgroundColor: GLOBAL.CUSTOM_ERROR_COLOR_PALETTE[50],
      },
    }
  });
};
export default styles;
