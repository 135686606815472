import * as React from 'react';
import { Typography, Grid,  Button as MuiButton, IconButton, makeStyles } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'components/providers/TranslationProvider';
import { TextField } from 'components/common/ReactHooksFormFields';
import resetPassword from 'api/resetPassword';
import changePassword from 'api/changePassword';
import Toast from 'components/common/Toast';

import styles from './styles/login';

const useStyles = makeStyles(styles);

const schema = yup.object().shape({
  password: yup
    .string()
    .required('password')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(^.{7,20}).+$/, 'strong_password'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'password_match'),
});

const ResetPassword = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [isPending, setIsPending] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const location = useLocation();
  const navigate = useNavigate();


  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleError = () => {
    setError(t('password_reset_token_error'));
    setOpenToast(true);
    setIsPending(false);
  };

  const handleSuccess = () => {
    navigate('/login');
    setIsPending(false);
  };

  const onSubmit = (data) => {
    setIsPending(true);

    if (token) {
      const item = {
        password: data.password,
        token,
      };
      resetPassword(item, handleError, handleSuccess, token);
    } else {
        const item = {
          user: location.state.user.email,
          password: data.password,
          accessToken: location.state.accessToken,
        };
        changePassword(item, handleError, handleSuccess);
    }
  };

  React.useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query) {
      setToken(query.get('token'));
    } else {
      setToken('');
    }
  }, []);

  return (
    <div className={classes.paper}>
      <div className={classes.form}>
      <Typography >
        {t('reset_password')}
      </Typography>
      <Toast message={error} handleClose={handleCloseToast} severity='error' open={openToast} />
      <FormProvider {...methods}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
                <TextField
                  autoFocus
                  variant='outlined'
                  fullWidth
                  className={classes.loginField}
                  label={t('password')}
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  autoComplete='current-password'
                  error={t(errors.password?.message)}
                />
                <IconButton
                  style={{ position: 'relative', bottom: '45px', left: '85%', color: '#6793A5' }}
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>

            </Grid>
            <Grid item xs={12}>

                <TextField
                  variant='outlined'
                  fullWidth
                  className={classes.loginField}

                  name='passwordConfirmation'
                  type={showPassword ? 'text' : 'password'}
                  label={t('confirm_password')}
                  autoComplete='current-password'
                  error={t(errors.passwordConfirmation?.message)}
                />
                <IconButton
                  style={{ position: 'relative', bottom: '45px', left: '85%' , color: '#6793A5' }}
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>

            </Grid>
          </Grid>


            <Grid item xs={12} >
              <MuiButton
                loading={isPending}
                fullWidth
                type='submit'
                className={`${classes.submitLogin} ${!isValid ? classes.submitLoginDisabled : '' }`}
                disabled={!isValid || isPending}
              >
                {isPending ? t('saving...') : t('save_changes')}
              </MuiButton>
            </Grid>

        </form>
      </FormProvider>
      </div>
    </div>
  );
};

export default ResetPassword;
