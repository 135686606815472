/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import useDeleteDataset from 'api/hooks/useDeleteDataset';
import { useTranslation } from 'components/providers/TranslationProvider';
import useDatasetUsage from 'api/hooks/useDatasetUsage';
import ModalDelete from '../common/ModalDelete';

const DeleteDataset = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const orgId = location.pathname.split('/')[2];
  const [datasetsUsage, setDatasetsUsage] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const { isLoading } = useDatasetUsage(location.state?._id, orgId, setDatasetsUsage);

  const deleteMutation = useDeleteDataset();

  const handleSubmit = () => {
    const data = {
      dataset_id: location.state?._id,
      name: location.state.name,
    };
    deleteMutation.mutateAsync(data);
  };

  return (
    <>
      {!isLoading && (
        <ModalDelete
          deleteMutation={deleteMutation}
          onSubmit={handleSubmit}
          content={
            datasetsUsage.length > 0
              ? `<p>${t('delete_dataset_scenario')}</p><p><strong>${location.state?.prosperiaName}</strong></p><div style="max-height: 150px; overflow-y: auto;"><ul>${datasetsUsage.map(name => `<li style="font-size: 12px"><strong>${name}</strong></li>`).join('')}</ul></div><p>${t('delete_dataset_related_layers')}</p>`
              : ` ${t('delete_dataset_without_scenario')} <div style="text-align: center;"><p><strong>"${location.state?.prosperiaName}"</strong></p></div>`
          }
          title={t('delete_dataset_title')}
          name={location.state?.prosperiaName}
          hideConfirmationField={ datasetsUsage.length > 0 }
          hideConfirmationButton={ datasetsUsage.length > 0 }
        />
      )}
    </>
  );
};

export default DeleteDataset;
