import React from "react";
import Button from '../../../../../../../common/Button';
import {Grid} from '@material-ui/core';
import {
  buttonCommonStyles, buttonOperationStyles, MuiGridCommonButton
} from './styles';
import { useTheme } from '../../../../../../../providers/CustomThemeProvider';

export function ButtonPanel({ formular, deleteLastElement }){
  const {theme} = useTheme()

	return(
		<>
      <Grid>
        <MuiGridCommonButton>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black' >
            !=
          </Button>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black'>
            {'<'}
          </Button>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black'>
            AND
          </Button>
          <Button variant="outlined" onClick={formular}
                  style={{...buttonCommonStyles, fontSize:'15px'}} color='black'>
            START WITH
          </Button>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black'>
            IN
          </Button>
          <Button variant="outlined" onClick={formular} color='black'
                  style={{
                    ...buttonCommonStyles,
                    backgroundColor: '#BDBDBD',
                  }}
          >
            MIN
          </Button>
        </MuiGridCommonButton>
        <MuiGridCommonButton>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black' >
            =
          </Button>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black'>
            {'>'}
          </Button>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black'>
            OR
          </Button>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black'>
            END WITH
          </Button>
          <Button variant="outlined" onClick={formular} color='black'
                  style={{
                    ...buttonCommonStyles,
                    backgroundColor: '#BDBDBD',
                  }}
          >
            CEIL
          </Button>
          <Button variant="outlined" onClick={formular} color='black'
                  style={{
                    ...buttonCommonStyles,
                    backgroundColor: '#BDBDBD',
                  }}
          >
            FLOOR
          </Button>
        </MuiGridCommonButton>
        <MuiGridCommonButton >
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black' >
            (
          </Button>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black'>
            {'<='}
          </Button>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black'>
            IS
          </Button>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black'>
            IS EMPTY
          </Button>
          <Button variant="outlined" onClick={formular} color='black'
                  style={{
                    ...buttonCommonStyles,
                    backgroundColor: '#BDBDBD',
                  }}>
            POW
          </Button>
          <Button variant="outlined" onClick={formular} color='black'
                  style={{
                    ...buttonCommonStyles,
                    backgroundColor: '#BDBDBD',
                  }}
          >
            SQRT
          </Button>
        </MuiGridCommonButton>
        <MuiGridCommonButton style={{marginBottom:'24px'}}>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black' >
            )
          </Button>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black'>
            {'>='}
          </Button>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black'>
            NOT
          </Button>
          <Button variant="outlined" onClick={formular}
                  style={buttonCommonStyles} color='black'>
            BETWEEN
          </Button>
          <Button variant="outlined" onClick={formular} color='black'
                  style={{
                    ...buttonCommonStyles,
                    backgroundColor: '#BDBDBD',
                  }}
          >
            LOG
          </Button>
          <Button variant="outlined" onClick={formular} color='black'
                  style={{
                    ...buttonCommonStyles,
                    backgroundColor: '#BDBDBD',
                  }}
          >
            ABS
          </Button>
        </MuiGridCommonButton>
      </Grid>
      <Grid>
        <MuiGridCommonButton >
          <Button variant="outlined" onClick={formular} color='black'
                  style={{
                    ...buttonCommonStyles,
                    width:110,
                    backgroundColor: '#BDBDBD',
                  }}
          >
            MAX
          </Button>
          <Button variant='outlined' onClick={deleteLastElement}
                  style={{
                    ...buttonCommonStyles,
                    backgroundColor: '#FF5549',
                    color:'#FFFFFF',
                    width:110,
                  }}>
            DELETE
          </Button>
        </MuiGridCommonButton>
        <MuiGridCommonButton>
          <Button variant="contained" onClick={formular}
                  style={{
            ...buttonCommonStyles,
            ...buttonOperationStyles,
            backgroundColor: theme.palette.primary.main,
            height:58,
          }}>
            +
          </Button>
          <Button variant="contained" onClick={formular} style={{
            ...buttonCommonStyles,
            ...buttonOperationStyles,
            backgroundColor: theme.palette.primary.main,
            height:58,
          }}>
            -
          </Button>
        </MuiGridCommonButton>
        <MuiGridCommonButton>
          <Button variant="contained" onClick={formular} style={{
            ...buttonCommonStyles,
            ...buttonOperationStyles,
            backgroundColor: theme.palette.primary.main,
          }}>
            <span style={{marginTop:5}}>*</span>
          </Button>
          <Button variant="contained" onClick={formular} style={{
            ...buttonCommonStyles,
            ...buttonOperationStyles,
            backgroundColor: theme.palette.primary.main,
            fontSize:25
          }}>
            /
          </Button>
        </MuiGridCommonButton>
      </Grid>
		</>
	)
}
