import * as React from 'react';
import { makeStyles, Grid, Container } from '@material-ui/core';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';

import Modal from 'components/common/Modal';
import Toast from 'components/common/Toast';
import { TextField } from 'components/common/ReactHooksFormFields';
import Button from 'components/common/Button';

import { useTranslation } from 'components/providers/TranslationProvider';
import useUpdateTranslation from 'api/hooks/useUpdateTranslation';
import useCreateTranslation from 'api/hooks/useCreateTranslation';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 20,
  },
}));

const FormModal = ({ action, open, onClose }) => {
  const classes = useStyles();
  const { t, setForceFetch } = useTranslation();
  const location = useLocation();
  const translation = location.state;

  const field = yup
    .string()
    .max(250, 'This field can not exceed 250 characters')
    .required('required');

  const schema = yup.object().shape({
    key: yup.string().min(4, 'min_4_characters').max(80, 'max_80_characters').required('required'),
    EN: field,
    ES: field,
    FR: field,
    PO: field,
  });

  const {
    mutateAsync: updateTranslation,
    error: updateError,
    isError: updateIsError,
    isSuccess: updateIsSuccess,
    isLoading: updateIsLoading,
  } = useUpdateTranslation();
  const {
    mutateAsync: createTranslation,
    error: createError,
    isError: createIsError,
    isSuccess: createIsSuccess,
    isLoading: createIsLoading,
  } = useCreateTranslation();

  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');

  const getDefaultValues = () => {
    return translation
      ? {
          key: translation.key,
          EN: translation.EN,
          ES: translation.ES,
          PO: translation.PO,
          FR: translation.FR,
        }
      : {};
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = methods;

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const updTranslation = (data) => {
    const translationToUpdate = {
      translation: {
        ...translation,
        EN: data.EN,
        ES: data.ES,
        FR: data.FR,
        PO: data.PO,
        key: data.key,
      },
      // eslint-disable-next-line no-underscore-dangle
      id: translation._id,
    };
    updateTranslation(translationToUpdate);
  };

  const newTranslation = (data) => {
    const translationToCreate = {
      EN: data.EN,
      ES: data.ES,
      FR: data.FR,
      PO: data.PO,
      key: data.key,
    };
    createTranslation(translationToCreate);
  };

  const onSubmit = handleSubmit((data) =>
    action === 'update' ? updTranslation(data) : newTranslation(data)
  );

  React.useEffect(() => {
    if (updateIsSuccess) {
      setForceFetch();
      onClose();
    }
    if (updateIsError) {
      setError(t(updateError.data.error));
      setOpenToast(true);
    }
  }, [updateIsSuccess, updateIsError, onClose, updateError, t, setForceFetch]);

  React.useEffect(() => {
    if (createIsSuccess) {
      setForceFetch();
      onClose();
    }
    if (createIsError) {
      setError(t(createError.data?.error));
      setOpenToast(true);
    }
  }, [createIsSuccess, createIsError, onClose, createError, t, setForceFetch]);

  const formTranslation = (
    <Container display='flex' className={classes.container}>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      <Grid item xs={12}>
        <TextField
          autoFocus
          name='key'
          margin='dense'
          type='text'
          label='Key'
          variant='outlined'
          fullWidth
          disabled={process.env.NODE_ENV !== 'development' && action === 'update'}
          error={t(errors.key?.message)}
          data-cy="translationKey"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name='EN'
          margin='dense'
          type='text'
          label='English'
          variant='outlined'
          fullWidth
          error={t(errors.EN?.message)}
          data-cy="translationEN"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name='ES'
          margin='dense'
          type='text'
          label='Español'
          variant='outlined'
          fullWidth
          error={t(errors.ES?.message)}
          data-cy="translationES"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name='FR'
          margin='dense'
          type='text'
          label='Français'
          variant='outlined'
          fullWidth
          error={t(errors.FR?.message)}
          data-cy="translationFR"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name='PO'
          margin='dense'
          type='text'
          label='Portugais'
          variant='outlined'
          fullWidth
          error={t(errors.PO?.message)}
          data-cy="translationPO"
        />
      </Grid>
    </Container>
  );

  const actions = (
    <>
      <Button onClick={onClose} variant='text' className={classes.btnClose}>
        {t('cancel_btn')}
      </Button>
      <Button
        onClick={onSubmit}
        type='submit'
        variant='contained'
        loading={updateIsLoading || createIsLoading}
        disabled={!isValid || updateIsLoading || createIsLoading || !isDirty}
        data-cy="translationSubmitData"
      >
        {t('save_changes')}
      </Button>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          onClose={onClose}
          title={
            action === 'update'
              ? `${t('update_translation')}: ${translation?.key}`
              : `${t('new_translation')}`
          }
          actions={actions}
        >
          {formTranslation}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default FormModal;
