/* eslint-disable camelcase */
import * as React from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Switch,
} from '@material-ui/core';

import SelectorGradient from 'components/common/SelectorGradient';
import { useTranslation } from 'components/providers/TranslationProvider';
import useDefaultGradients from 'api/hooks/useDefaultGradients';
import CustomSkeleton from 'components/common/CustomSkeleton';
import { useTheme } from '../../../../../../../providers/CustomThemeProvider';
import {
  divContainerStyle, keyStyle, MuiDivider,
  MuiInvertColorPallete,
  MuiSelectGradient
} from './styles/histogram';

const useStyles = makeStyles((theme) => ({
  gradientSelectorContainer: {
    display: 'flex'
  },
  customColor: {
    margin: theme.spacing(0, 2),
  },
  root: {
    width: 52,
    height: 32,
    marginLeft:8,
    marginTop:-5,
    padding: 0,
    display: 'flex',
    '&:active $thumb': {
      width: 12,
    },
    '&:active $switchBaseChecked': {
      transform: 'translateX(9px)',
    },
  },
  switchBase: {
    color:'#4D8094',
    padding: 2,
    '&$checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '&$checked + $track': {
        border:'none !important',
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 8,
    marginTop:6,
    marginLeft:4,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  track: {
    background: '#DFF2FC',
    opacity: 1,
    borderRadius: '20px',
    border:'2px solid',
    borderColor:'#4D8094',
    boxSizing: 'border-box',
  },
  thumbChecked: {
    color: '#FFFFFF',
  },
  checked: {},
  switchBaseChecked: {},
}));

// eslint-disable-next-line no-unused-vars
const Histogram = ({
  gradient,
  setGradient,
  selectedGradientId,
  palette_reverse,
  setPaletteReverse,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [checked, setChecked] = React.useState(palette_reverse);
  const {theme} = useTheme()

  // eslint-disable-next-line no-unused-vars
  const handleChange = (event) => {
    setChecked(event.target.checked);

  };
  React.useEffect(() => {
    setPaletteReverse(checked);
  }, [checked]);

  const { data: gradients, isSuccess } = useDefaultGradients();

  React.useEffect(() => {
    if (selectedGradientId && Object.keys(gradient).length === 0 && isSuccess) {
      if (gradients.find((g) => g.id === selectedGradientId)){
        setGradient(gradients.find((g) => g.id === selectedGradientId));
      }else{
        setGradient(gradients.find((g) => g.name === 'Gradient1'));
      }
    } else if (Object.keys(gradient).length === 0 && isSuccess) {
      setGradient(gradients[0]);
    }
  }, [isSuccess]);

  return (
    <>
      <Grid container style={{ margin: '16px 0' }} className={classes.gradientSelectorContainer}>
        <Grid>
          <MuiSelectGradient variant='caption'>
            {t('select_gradient_color')}
          </MuiSelectGradient>
          {isSuccess ? (
            <SelectorGradient
              name={t('gradients') || t('histogram_gradient')}
              checked={checked}
              value={gradient && Object.keys(gradient).length !== 0 ? gradient : gradients[0]}
              gradients={gradients}
              onChange={(e) => setGradient(e.target.value)}
            />
          ) : (
            <CustomSkeleton height={28} width={220} />
          )}
        </Grid>
        <Grid style={{ marginTop:24 }}>
          <MuiInvertColorPallete variant='body1'>
            {t('invert_color_palette')}
          </MuiInvertColorPallete>
        </Grid>
        <Grid style={{ marginTop:24 }}>
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
          />
        </Grid>
        <Grid style={{ marginTop:24 }}>
          <div style={divContainerStyle}>
            <Typography
              style={{
                ...keyStyle,
                color: !checked ? theme.palette.primary.main : '#4D8094',
              }}
            >
              {t('key_no')}
            </Typography>
            <MuiDivider>/</MuiDivider>
            <Typography
              style={{
                ...keyStyle,
                color: checked ? theme.palette.primary.main : '#4D8094',
              }}
            >
              {t('key_yes')}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Histogram;
