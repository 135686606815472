export const buttonCommonStyle = {
  marginRight: 16,
  borderColor:'#6793A5',
  borderRadius:20,
  height:'38px',
  width:'332px',
  padding:'10px 24px 10px 16px',
  gap:8
}

export const typographyCommonStyle = {
  color: '#005F64',
  textAlign:'center',
  fontFamily: 'Montserrat',
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: 0.25
}
