import { Grid, styled, Typography } from '@mui/material';

const styles = (theme) => ({
  root:{
    marginBottom:10,
    padding:0
  },
  navBar: {
    boxShadow: 'none',
    overflow: 'hidden',
    marginBottom: 40,
  },
  toolBar: {
    paddingLeft: '25%',
    backgroundColor: 'white',
  },
  tab: {
    minWidth: 'auto',
    padding: theme.spacing(0),
    marginLeft: theme.spacing(1),
  },
  tabs: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    '& .Mui-selected': {
      fontSize: 14,
      fontFamily:'Montserrat',
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
    height: 3,
    width: 'auto',
    borderRadius: '10px 10px 0 0',
    transition: 'width 0.3s',
    marginBottom: theme.spacing(0),
  },
  img: {
    width: 240,
    height: 300,
    objectFit: 'scale-down',
  },
  imageContainer: {
    textAlign: 'center',
  },
  container: {
    paddingTop: 20,
  },
  switchBase: {
    color: '#757575',
    '&$checked': {
      color: '#FFFFFF',
      transform: 'translateX(14px)',
    },
    '&$checked + $track': {
      border:'none',
      backgroundColor: theme.palette.primary.main,
      opacity:1
    },
  },
  checked: {},
  track: {
    background: '#FFFFFF',
    border:'1px solid',
    borderColor:'#757575',
    borderRadius: 12,
    marginTop:12,
    width: 43,
    height: 24,
  },
  thumb: {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
    marginTop:8,
  },
  inputText: {
    paddingTop: 20,
    paddingLeft: 20,
    width: 500
  },
  descriptionLabel: {
    transform: 'translate(30px, 30px) scale(1)'
  }
});
export default styles;

export const MuiOptionsTypography = styled(Typography)(({ theme }) => ({
  color:'#212121',
  fontFamily: 'Montserrat',
  letterSpacing:'0.4px',
  fontWeight:400,
  fontSize:12
}));

export const MuiSwitchTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Montserrat',
  textTransform:'uppercase',
  letterSpacing:'1.5px',
  fontWeight:400,
  fontSize:10
}));

export const MuiGridOthersTab = styled(Grid)(({ theme }) => ({
  display: 'flex',
  maxHeight: 40,
  alignItems: 'center',
  justifyContent:'space-between',
  marginLeft: 16,
  borderBottom:'1px solid',
  borderColor:'#E0E0E0'
}));

export const containerTabsStyle = {
  height: 250,
  marginTop:'16px'
}

export const containerSwitchStyle = {
  display:'flex',
  marginTop:'18px'
}

export const dividerStyle = {
  marginLeft:'5px',
  fontSize:'10px',
  marginRight:'5px'
}
