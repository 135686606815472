import { styled, Typography, Button } from '@mui/material';

export const MuiSaveButton = styled(Button)(({ theme }) => ({
  marginLeft: 16,
  borderRadius:20,
  background: localStorage.getItem('colorPrimary'),
  height:'38px',
  width:'143px',
  padding:'10px 24px 10px 16px',
  gap:8
}));

export const MuiActionTypography = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  textAlign:'center',
  fontFamily: 'Montserrat',
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: 0.25
}));

export const saveAndViewButtonStyles = {
  borderRadius:20,
  height:'38px',
  padding:'10px 24px 10px 16px',
  gap:8
}

export const iconCommonStyles = {
  color:'white'
}
