import * as React from 'react';
import {useSelector, useDispatch} from "react-redux";
import WrapperWidgetUI from "../WrapperWidgetUI";
import * as _ from "lodash";
import {
  Divider,
  FormControl,
  Grid, IconButton,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import {setParametersCatalog, setWidgets} from "../../../../store/appSlice";
import Slider from "@material-ui/core/Slider";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {useTheme} from "../../../providers/CustomThemeProvider";
import {useState} from "react";
import {Refresh} from "@material-ui/icons";
import {useTranslation} from "../../../providers/TranslationProvider";
import {makeStyles} from "@material-ui/core/styles";
import {createStyles} from "@mui/material";

const LabelTypography = withStyles(({theme}) => {
  return {
    root: {
      color: '#424242',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: 600,
      width: '100%',
      whiteSpace: 'pre-wrap', // Preserves line breaks and whitespace
      wordWrap: 'break-word', // Allows long words to break and wrap to the next line
      textAlign: 'left'
    }
  }
})(Typography);

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 2s linear infinite"
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)"
      },
      "100%": {
        transform: "rotate(360deg)"
      }
    }
  })
);

const Parameter = ({parameter, updateParameter}) => {

  const {theme} = useTheme();
  const switchEnabledStyle = {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  }

  const switchDisabledStyle = {
    color: theme.palette.primary.main,
  }
  return (
    <>
      <Grid container item direction='row' xs={12} alignItems='center'
            justifyContent='space-between'
            style={{height: '70px', width: '100%'}} spacing={2}>
        <Grid item xs={5}
              style={{display: 'flex', alignItems: 'center', height: '100%'}}>
          <LabelTypography component='div'>{parameter.label}</LabelTypography>
        </Grid>
        {parameter.type === 'Numeric' ? (
          <>
            <Grid item xs={4} style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}>
              <Slider
                value={parameter.value}
                marks={true}
                min={parameter.minValue}
                max={parameter.maxValue}
                step={(parameter.maxValue - parameter.minValue) / 10}
                onChange={(event, value) => {
                  const updatedParameter = {...parameter};
                  updatedParameter.value = value;
                  updateParameter(updatedParameter);
                }}
                style={{width: '100%'}}
              />
            </Grid>
            <Grid item xs={3} style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}>
              <FormControl style={{width: '100%'}}>
                <TextField
                  type='number'
                  variant='outlined'
                  value={parameter.value}
                  inputProps={{
                    min: parameter.minValue,
                    max: parameter.maxValue,
                    step: parameter.type === "Boolean" ? 1 : ((parameter.maxValue - parameter.minValue) / 10),
                    style: {padding: '10px 2px 10px 5px'}
                  }}
                  onChange={(event) => {
                    const updatedParameter = {...parameter}
                    updatedParameter.value = parseFloat(event.target.value);
                    updateParameter(updatedParameter);
                  }}
                />
              </FormControl>
            </Grid>
          </>
        ) : (
          <Grid item
                style={{display: 'flex', alignItems: 'center', height: '100%'}}>
            <ToggleButtonGroup
              value={Math.round(parameter.value)}
              exclusive
              onChange={(event, value) => {
                const updatedParameter = {...parameter};
                updatedParameter.value = value;
                updateParameter(updatedParameter);
              }}
              style={{
                width: '100%',
                height: '32px',
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: '16px'
              }}
            >
              <ToggleButton value={1} style={{borderRadius: '16px 0 0 16px',...(parameter.value === 1 ? switchEnabledStyle : switchDisabledStyle)}}>
                Yes
              </ToggleButton>
              <ToggleButton value={0}
                            style={{borderRadius: '0 16px 16px 0', ...(parameter.value === 0 ? switchEnabledStyle : switchDisabledStyle)}}>No</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
      </Grid>
    </>
  )
}
const ParametersWidget = ({
                            id,
                            type,
                            title,
                            wrapperProps,
                          }) => {
  const {theme} = useTheme();
  const { t } = useTranslation();
  const parameters = useSelector((state) => state.app.parametersCatalog);
  const [localParameters, setLocalParameters] = useState(parameters);
  const dispatch = useDispatch();
  const widgets = useSelector((state) => state.app.widgets);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const disabled = _.isEqual(localParameters, parameters)
  const layerLoading = useSelector((state) => state.app.layerLoadingState);
  const allLoading = Object.values(layerLoading).every(obj => obj.loadingState);
  const updateParameter = (parameter) => {
    const updatedParameterList = [...localParameters];
    const index = updatedParameterList.findIndex(p => p.propName === parameter.propName);
    updatedParameterList[index] = parameter;
    setLocalParameters(updatedParameterList);
  }

  const submitParameterSimulation=()=>{
    if (!_.isEqual(localParameters, parameters)) {
      const updatedParameterList = [...localParameters];
      const updatedWidgets = widgets.map(w => {
        if (w.type === 'parameters' && w.id === id) {
          return {...w, parametersCatalog: updatedParameterList}
        }
        return w;
      })
      setLoading(true)
      dispatch(setWidgets(updatedWidgets));
      dispatch(setParametersCatalog(updatedParameterList));
    }
  }
  React.useEffect(()=> {
    const allLoaded= Object.values(layerLoading).every(obj => !obj.loadingState);
    if (allLoaded) setLoading(false);

  },[layerLoading])
  return (
    <WrapperWidgetUI title={title} widgetId={id} isLoading={false}
                     wrapperProps={wrapperProps}>
      <Grid container direction='column' style={{width: '100%'}} spacing={3} justifyContent={'center'}>
        {
          localParameters?.map((p, i) => {
            return (
              <>
                <Parameter parameter={p} updateParameter={updateParameter}
                           key={`parameter-key-${p.propName}`}/>
                {(i < localParameters.length - 1) && (<Divider/>)}
              </>
            )
          })
        }
        <Grid container item xs={12} style={{marginTop: '10px'}} justifyContent={'center'}>
          <IconButton
            onClick={()=>submitParameterSimulation()}
            style={{border:`1px solid ${theme.palette.primary.main}`,
              borderRadius:'20px',
              fontFamily: 'Montserrat',
              fontSize: '18px',
              fontWeight: 600,
              color: disabled ? 'gray': `${localStorage.getItem('colorPrimary')}`,
              height: '38px',
            }}
            disabled={disabled}
          >
            <Refresh  className={loading ? classes.rotateIcon: ''} style={{marginRight: 8, fontSize: 18}}/>
            {loading ? t('updating') : t('update_simulation')}
          </IconButton>
        </Grid>
      </Grid>

    </WrapperWidgetUI>
  )
}

export default ParametersWidget;
