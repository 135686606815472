import * as React from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core';
import { Outlet } from 'react-router-dom';
import styles from './styles/login';
import LanguageSelector from 'components/common/LanguageSelector';

const useStyles = makeStyles(styles);

const LoginLayout = () => {
  const classes = useStyles();

  return (
    <Grid container component='main' className={classes.root}>
      <Grid item xs={false} sm={false} md={7}>
        <div className={classes.imageGrid}/>
      </Grid>
      <Grid item xs={12} sm={12} md={5} component={Paper}>
        <Grid className={classes.langContainer}>
          <LanguageSelector />
          </Grid>

        <Outlet />
      </Grid>
    </Grid>
  );
};

export default LoginLayout;
