import * as React from 'react';
import {
  Grid,
  makeStyles,
  withStyles,
  Breadcrumbs,
  IconButton,
  Tooltip as MuiTooltip,
  Link,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import AppsIcon from '@material-ui/icons/Apps';
import LayersIcon from '@material-ui/icons/Layers';
import WidgetsIcon from '@material-ui/icons/Widgets';
import PublicIcon from '@mui/icons-material/Public';
import SettingsIcon from '@material-ui/icons/Settings';

import { useAuth } from 'components/providers/AuthProvider';
import { useTranslation } from '../providers/TranslationProvider';

import styles from './styles/navigationMenu';
import { usePermissions } from 'components/hooks/usePermissions';
import { GLOBAL } from 'utils/constants';

const useStyles = makeStyles(styles);

const Tooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.light,
    fontSize: 11,
  },
}))(MuiTooltip);

const NavigationMenu = ({ location, column: vertical }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { user } = useAuth();
  const locArray = location.pathname.split('/');
  const { hasPermissionFor } = usePermissions();

  const getLastElement = () => {
    if (locArray[6] === 'custom' && locArray[8]) return 5;
    if (locArray[6] === 'custom' && locArray[7]) return 4;
    if (locArray.includes('view')) return 3;
    if (locArray.includes('specification')) return 2;
    if (locArray[2]) return 1;
    if (locArray[1]) return 0;
    return null;
  };

  const getNavigateSettings = () => {
    if (locArray.includes('view') && locArray.includes('public'))
      return `${locArray.slice(0, 3).join('/')}/maps/${locArray[6]}/specification`;

    if (locArray.includes('view') && locArray.includes('custom'))
      return `${locArray.slice(0, 3).join('/')}/maps/${locArray[6]}/specification`;

    if (locArray[6] === 'custom' && locArray[7]) return `${locArray.slice(0, 7).join('/')}`;

    return `${location.pathname}`;
  };

  return (
    <Grid
      container
      direction={vertical ? GLOBAL.POSITION.COLUMN : GLOBAL.POSITION.ROW}
      className={!vertical ? classes.navTabs : ''}
    >
      <Breadcrumbs aria-label={GLOBAL.ARIALABEL.BREADCRUMB} className={classes.breadcrumb}>
        {(user.type === GLOBAL.ROLES.SUPERADMIN || user.type === GLOBAL.ROLES.ADMIN ||
        ( user.type !== GLOBAL.ROLES.SUPERADMIN && user.type !== GLOBAL.ROLES.ADMIN && user.non_admin_users_orgs_ids_access_permissions.length > 0 )) && (
          <div className={ `${classes.displayFlex} ${classes.breadCrumbItemHover}` }>
            <IconButton
              component={NavLink}
              to={`${locArray[0]}/orgs`}
              className={
                getLastElement() === 0 && locArray[1] === 'orgs'
                  ? classes.btnIconSelected
                  : classes.btnIcon
              }
            >
              <AppsIcon
                className={
                  getLastElement() === 0 && locArray[1] === 'orgs'
                    ? classes.iconSelected
                    : classes.icon
                }
                style={{marginLeft: 0}}
              />
            </IconButton>
            <Link to={`${locArray[0]}/orgs`} className={classes.breadCrumbLocation} component={NavLink} underline='none'>
                { t('orgs') }
            </Link>
            </div>
        )}
        {getLastElement() >= 1 && locArray[2] !== 'workspace' ? (
          <div className={ `${classes.displayFlex} ${classes.breadCrumbItemHover} ${getLastElement() === 1 && classes.breadCrumbLastItem}` }>
            <IconButton
              component={NavLink}
              to={`${locArray.slice(0, 3).join('/')}/maps`}
              className={getLastElement() === 1 ? classes.btnIconSelected : classes.btnIcon}
            >
              <HomeIcon className={getLastElement() === 1 ? classes.iconSelected : classes.icon} />
            </IconButton>
              <Link to={`${locArray.slice(0, 3).join('/')}/maps`} className={classes.breadCrumbLocation} component={NavLink} underline='none'>
                {t('home_btn')}
              </Link>
          </div>
        ) : (
          locArray[2] === 'workspace' && (
            <div className={ `${classes.displayFlex} ${classes.breadCrumbItemHover} ${getLastElement() === 1 && classes.breadCrumbLastItem}` }>
              <IconButton
                component={NavLink}
                to={`${locArray.slice(0, 3).join('/')}/maps`}
                className={getLastElement() === 1 ? classes.btnIconSelected : classes.btnIcon}
              >
                <PersonIcon
                  className={getLastElement() === 1 ? classes.iconSelected : classes.icon}
                />
              </IconButton>
              <Link to={`${locArray.slice(0, 3).join('/')}/maps`} className={classes.breadCrumbLocation} component={NavLink} underline='none'>
                {t('my_workspace')}
              </Link>
              </div>
          )
        )}
        {getLastElement() === 2 && (
          <div className={ `${classes.displayFlex} ${classes.breadCrumbItemHover} ${getLastElement() === 2 && classes.breadCrumbLastItem}` }>
            <IconButton
              color={GLOBAL.COLOR.SECONDARY}
              className={classes.btnIconSelected}
              component={NavLink}
              to={`${location.pathname}`}
            >
              <SettingsIcon className={getLastElement() === 2 ? classes.iconSelected : classes.icon} />
            </IconButton>
            <Link to={`${location.pathname}`} className={classes.breadCrumbLocation} component={NavLink} underline='none'>
                { t('setting') }
              </Link>
            </div>
        )}
        {getLastElement() === 3 && (
          <div className={ `${classes.displayFlex} ${classes.breadCrumbItemHover} ${getLastElement() === 3 && classes.breadCrumbLastItem}` }>
            <IconButton
              color={GLOBAL.COLOR.SECONDARY}
              className={classes.btnIconSelected}
              component={NavLink}
              to={`${location.pathname}`}
            >
              <PublicIcon className={getLastElement() === 3 ? classes.iconSelected : classes.icon} />
            </IconButton>
            <Link to={`${location.pathname}`} className={classes.breadCrumbLocation} component={NavLink} underline='none'>
                {t('map_view_tooltips')}
              </Link>
            </div>
        )}
        {getLastElement() >= 4 && (
          <div className={ `${classes.displayFlex} ${classes.breadCrumbItemHover} ${getLastElement() === 4 && classes.breadCrumbLastItem}` }>
            <IconButton
              color={GLOBAL.COLOR.SECONDARY}
              className={getLastElement() === 4 ? classes.btnIconSelected : classes.btnIcon}
              component={NavLink}
              to={`${locArray.slice(0, 8).join('/')}`}
            >
              <LayersIcon
                className={getLastElement() === 4 ? classes.iconSelected : classes.icon}
              />
            </IconButton>
            <Link to={`${locArray.slice(0, 8).join('/')}`} className={classes.breadCrumbLocation} component={NavLink} underline='none'>
                {t('layer_view_tooltips')}
              </Link>
            </div>
        )}
        {getLastElement() === 5 && (
          <div className={ `${classes.displayFlex} ${classes.breadCrumbItemHover} ${getLastElement() === 5 && classes.breadCrumbLastItem}` }>
            <IconButton
              color={GLOBAL.COLOR.SECONDARY}
              className={classes.btnIconSelected}
              component={NavLink}
              to={`${locArray.slice(0, 9).join('/')}`}
            >
              <WidgetsIcon
                className={getLastElement() === 5 ? classes.iconSelected : classes.icon}
              />
            </IconButton>
            <Link to={`${locArray.slice(0, 9).join('/')}`} className={classes.breadCrumbLocation} component={NavLink} underline='none'>
                {t('widget_view_tooltips')}
              </Link>
            </div>
        )}
      </Breadcrumbs>
    </Grid>
  );
};
export default NavigationMenu;
