import { GLOBAL } from "utils/constants";

const styles = (theme) => ({
  input: {
    display: 'none',
  },
  image: {
    objectFit: 'scale-down',
    width: 106,
    height: 106,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 106,
    height: 106,
    marginButton: 8,
  },
  upload: {
    marginTop: theme.spacing(2),
    justifyContent: 'center',
    borderRadius: 20,
    border: `1px solid ${GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]}`,
    color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]
  },
  justifyContent: {
    justifyContent: 'center',
  },
});
export default styles;
