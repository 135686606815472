import * as React from 'react';

import { Grid, makeStyles, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'components/providers/TranslationProvider';

import LayersSort from './LayersSort';
import {
  iconCommonAddStyles,
  MuiCommonButtonAdd,
  MuiCommonTypographyAdd
} from '../styles/common';
import { Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  layerSortContainer: {
    justifyContent: 'flex-start',
    margin: '0px  0px',
    padding: 16,
    height: 280,
    alignContent: 'baseline',
    borderRadius:'12px',
    overflow: 'auto',
    backgroundColor:'#FAFAFA'
  },
}));

const Layers = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const layers = useSelector((state) => state.app.layers);

  const handleAddLayer = () => {
    navigate(`${location.pathname}?create=layer`, {
      replace: true,
    });
  };

  return (
    <>
      <Grid container justifyContent='flex-end' alignContent='center'>
        <Grid item xs={10}>
          <Typography variant='subtitle1'
                      fontSize={16}
                      color={'#616161'}
                      marginTop={1}
                      letterSpacing={0.15}
                      fontWeight={400}
                      fontFamily={'Raleway'}>
            {t('layers')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <MuiCommonButtonAdd
            variant='outlined'
            color='primary'
            startIcon={<AddIcon style={iconCommonAddStyles}/>}
            onClick={handleAddLayer}
            data-cy={'add_layer'}
          >
            <MuiCommonTypographyAdd> {t('add_layer')} </MuiCommonTypographyAdd>
          </MuiCommonButtonAdd>
        </Grid>
      </Grid>
      <Grid container component={Paper} className={classes.layerSortContainer}>
        {layers.length > 0 && <LayersSort />}
      </Grid>
    </>
  );
};

export default Layers;
