import * as React from 'react';
import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  DialogTitle as MuiDialogTitle,
} from '@material-ui/core';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import themeIncluia from 'theme/incluia'
import { useTheme } from 'components/providers/CustomThemeProvider';

const dialogTitleStyle = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  typography: {
    color: theme.palette.error.main,
  },
  title: {
    fontSize: 25
  }
});

const DialogTitle = withStyles(dialogTitleStyle)(
  ({ children, classes, onClose, isDelete, ...props }) => (
    <MuiDialogTitle disableTypography className={classes.root} {...props}>
      <Typography
        align='left'
        variant='h5'
        className={ `${isDelete && classes.typography} ${classes.title}`}
        style={{marginTop:'5px'}}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
);

const useStyles = makeStyles((theme) => ({
  generalContainer: {
    marginTop: 10,
    marginButton: 10,
  },
  btnClose: {
    marginRight: theme.spacing(2),
  },
  dialog: {
    zIndex: 1400,
  },
  dialogAction: {
    padding: '16px 16px',
  },
  generalContainerNewStyles: {
    borderRadius: 28
  },
}));

const Modal = ({ open, onClose, title, children, actions, isDelete,
                 isWidgetForm = false,width = 'xs',
                 widthInPixels=null, heightInPixels= null }) => {
  const classes = useStyles();
  const { theme } = useTheme();
  return (
    <ThemeProvider theme={themeIncluia}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={width}
        fullWidth
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        PaperProps={{
          style: {
            borderRadius: 28,
            width: widthInPixels,
            height: heightInPixels !== null ? heightInPixels : 'auto',
            maxHeight: heightInPixels !== null ? heightInPixels : 'auto',
            maxWidth: widthInPixels,
          },
        }}
      >
        <DialogTitle isDelete={isDelete} onClose={isWidgetForm ? onClose : false} id='scroll-dialog-title'>
          {title}
        </DialogTitle>
        <ThemeProvider theme={theme}>
          <DialogContent dividers style={{ maxHeight: 580, padding: '0px 16px 0px 16px', borderTop: 'none', borderBottom: 'none' }}>
            {children}
          </DialogContent>
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <DialogActions className={classes.dialogAction}>{actions}</DialogActions>
        </ThemeProvider>
      </Dialog>
    </ThemeProvider>
  );
};

export default Modal;
