import * as React from 'react';
import { Grid, makeStyles, Container } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { cleanSchemeState } from 'store/appSlice';
import { useLayers } from 'components/providers/LayersProvider';

import useScheme from 'api/hooks/useScheme';
import { useAuth } from 'components/providers/AuthProvider';
import { giveMeId } from 'utils/supportComponents';

import EmbeddedMap from './EmbeddedMap';
import DescriptiveMap from './DescriptiveMap';

import styles from './styles/specification';

const useStyles = makeStyles(styles);

const MapEvents = () => {
  const classes = useStyles();
  const location = useLocation();

  const dispatch = useDispatch();
  const { user, isAuthenticated } = useAuth();
  const { removeLayers } = useLayers();

  const [activeTab, ] = React.useState('descriptive-spatial-analysis');
  const isCreate = Boolean(location.pathname.split('/').includes('create'));
  const id = giveMeId(location.pathname, user);
  const schemeId = location.pathname.split('/')[4];

  const scenario = useScheme({
    user_id: id,
    scheme_id: schemeId,
    isAuthenticated: isAuthenticated,
    enabled: !isCreate,
  });

  React.useEffect(() => {
    return () => {
      dispatch(cleanSchemeState());
      scenario.remove();
      removeLayers();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Container className={classes.navBar}>
      <Grid container spacing={3} className={classes.containerPaper}>
        {activeTab === 'embedded' && (
          <EmbeddedMap
            type={activeTab}
            scenario={scenario}
            isCreate={isCreate}
            schemeId={schemeId}
            id={id}
          />
        )}
        {activeTab === 'descriptive-spatial-analysis' && (
          <DescriptiveMap
            type={activeTab}
            scenario={scenario}
            isCreate={isCreate}
            schemeId={schemeId}
            id={id}
          />
        )}
      </Grid>
    </Container>
  );
};

export default MapEvents;
