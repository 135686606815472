import * as React from 'react';
import { Popover, Box, makeStyles, Grid, Typography } from '@material-ui/core';
import { hexToRgb } from 'utils/colorConverter';
import { GLOBAL } from 'utils/constants';
import { IconButton } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useTranslation } from '../providers/TranslationProvider';
const useStyles = makeStyles((theme) => ({
  root: {
    width: ({ width }) => width+50,
    height: ({ height }) => height,
    margin: theme.spacing(0, 0 , 0, -1),
    borderRadius: 4,
    borderWidth: 0,
    borderStyle: GLOBAL.POSITION.SOLID,
    // outline: GLOBAL.POSITION.OUTLINESELECTORGRADIENT,
    outlineOffset: GLOBAL.POSITION._2PX,
    display: GLOBAL.POSITION.FLEX,
    justifyContent: GLOBAL.POSITION.CENTER,
    alignItems: GLOBAL.POSITION.CENTER,
    '&:hover': {
      cursor: GLOBAL.POSITION.POINTER,
    },
  },
  gradientItems: {
    width: ({ width }) => width,
    height: ({ height }) => height,
    borderRadius: 4,
    margin: '8px 0',
    borderWidth: 0,
    borderStyle: GLOBAL.POSITION.SOLID,
    // outline: ({ selected }) =>
    //   selected
    //     ? GLOBAL.POSITION.OUTLINEHOVERPRIMARY
    //     : GLOBAL.POSITION.OUTLINESELECTORGRADIENT,
    outlineOffset: ({ selected }) => (selected ? 2 : GLOBAL.POSITION._2PX),
    display: GLOBAL.POSITION.FLEX,
    justifyContent: GLOBAL.POSITION.CENTER,
    alignItems: GLOBAL.POSITION.CENTER,
    background: ({ selected }) =>
      selected
        ? `rgba( ${hexToRgb(localStorage.getItem('colorPrimary')).join(', ')} ,0.2)`
        : GLOBAL.COLOR.WHITE,
    '&:hover': {
      // outline: GLOBAL.POSITION.OUTLINEHOVERSECONDARY,
      // outlineOffset: GLOBAL.POSITION._2PX,
      cursor: GLOBAL.POSITION.POINTER,
    },
  },
  container: {
    padding: 10,
    width: ({ width }) => width + 100,
    display: GLOBAL.POSITION.FLEX,
    justifyContent: GLOBAL.POSITION.CENTER,
    alignItems: GLOBAL.POSITION.CENTER,
    border: GLOBAL.POSITION.OUTLINESELECTORGRADIENT,
    borderRadius: 4,
  },
  checkIcon: {
    position: 'absolute',
    top: '77px',
    left: '50%',
    transform: 'translateX(-50%)',
    color: '#fff',
    borderRadius: '50%',
  },
  checkValue: {
    position: 'absolute',
    top: '5px',
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'black',
    borderRadius: '50%',
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  iconValue: {
    position: 'absolute',
    transform: 'translate(-40%, 5%)',
    color: '#fff',
    borderRadius: '50%',
  },
}));

/**
 * @param {Boolean} checked - Value for checked
 * @param {Array} value - Value or default value
 * @param {string} name - Title of the Component
 * @param {Function} onChange - Function that change value state
 * @param {number} width - Width >= 220px
 * @param {number} height - Height >= 20px
 * @param {Array} gradients - Contain the Array of values
 */

const SelectorGradient = React.forwardRef(
  ({ checked, value, name, onChange = (x) => x, width = 150, height = 30, gradients }, ref) => {
    const internalWidth = width >= 220 ? width : 150;
    const internalHeight = height >= 30 ? height : 30;
    const classes = useStyles({ width: internalWidth, height: internalHeight });
    const [anchorEl, setAnchorEl] = React.useState(null);

    const { t } = useTranslation();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChange = (val) => {
      onChange({ target: { name, value: val } });
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? GLOBAL.CODECONSTANTS.SIMPLEPOPOVER : undefined;
    return (
      <div name={name} id={name} ref={ref}>
        <Box name={name} aria-describedby={id} onClick={handleClick} className={classes.root}>
          <GradientItem checked={checked} value={value} internalWidth={internalWidth} />
          <CheckCircleOutlineIcon className={classes.iconValue} />
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          transitionDuration={0}
          transformOrigin={{
            vertical: GLOBAL.POSITION.BOTTOM,
            horizontal: GLOBAL.POSITION.LEFT,
          }}
          style={{ left: '-10px' }}
        >
          <Grid container className={classes.container}>
            <Grid item xs={10}>
              <Typography variant='body2'
                          style={{
                            fontFamily:'Montserrat',
                            fontWeight:400,
                            fontSize:14,
                            color:'#212121'
                          }}>
                {name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton  onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            {gradients.map((i, index) => (
              <React.Fragment key={i.id}>
                {index === 0 && (
                  <>
                    <Typography variant='caption'
                                style={{
                                  fontFamily:'Montserrat',
                                  fontWeight:400,
                                  fontSize:10,
                                  color:'#325D6C',
                                  textTransform:'uppercase',
                                  marginRight:'7rem'
                                }}>
                      {t('theme_suggested')}
                    </Typography>
                  </>
                )}
                <GradientContainer
                  key={i.id}
                  checked={checked}
                  handleChange={handleChange}
                  selected={i.id === value.id}
                  width={internalWidth + 100}
                  height={internalHeight}
                >
                  <GradientItem checked={checked} value={i} internalWidth={internalWidth + 20} />
                </GradientContainer>
                {i.id === value.id && (
                    <CheckCircleOutlineIcon style={{marginTop:'-2.8rem', color:'white'}} />
                  )}
                {index === 0 && (<Divider className={classes.divider}/>)}
              </React.Fragment>
            ))}
          </Grid>
        </Popover>
      </div>
    );
  }
);

const GradientContainer = React.forwardRef(
  ({ checked, children, selected, handleChange, width, height, ...props }, ref) => {
    const classes = useStyles({ selected, width, height });
    return (
      <Box
        {...props}
        ref={ref}
        onClick={() => handleChange(children.props.value)}
        className={classes.gradientItems}
      >
        {children}
      </Box>
    );
  }
);

const GradientItem = React.forwardRef(({ checked, value, internalWidth }, ref) => {
  const [realColors, setRealColors] = React.useState(value.colors);
  const width = (internalWidth - internalWidth / realColors.length) / realColors.length + 6.5;

  React.useEffect(() => {
    const arrayColorReverse = [...value.colors].reverse();
    setRealColors(checked ? arrayColorReverse : value.colors);
  }, [checked, value.colors]);

  React.useEffect(() => {
    const arrayColorReverse = [...value.colors].reverse();
    setRealColors(checked ? arrayColorReverse : value.colors);
  }, [value.colors]);

  return (
    <Grid
      ref={ref}
      container
      style={{
        display: GLOBAL.POSITION.FLEX,
        justifyContent: GLOBAL.POSITION.CENTER,
        alignItems: GLOBAL.POSITION.CENTER,
      }}
    >
      <Box
        style={{
          width,
          height: GLOBAL.POSITION._30PX,
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          backgroundColor: realColors[0].color,
        }}
      />
      {realColors.map(
        (c, i) =>
          i !== 0 &&
          i !== realColors.length - 1 && (
            <Box
              key={c.id}
              style={{
                width,
                height: GLOBAL.POSITION._30PX,
                backgroundColor: c.color,
              }}
            />
          )
      )}
      <Box
        style={{
          width,
          height: GLOBAL.POSITION._30PX,
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
          backgroundColor: realColors[realColors.length - 1].color,
        }}
      />
    </Grid>
  );
});

export default SelectorGradient;
