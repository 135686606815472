const styles = (theme) => ({
  containerBtnSaves: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  xs6Container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2, 0),
  },
  containerPaper: {
    padding: theme.spacing(3, 8),
    width: '100%',
  },
  container: {
    marginTop: theme.spacing(2),
  },
  scenarioTypeContainer: {
    margin: theme.spacing(2, 0, 4),
    padding: theme.spacing(0, 0, 2),
  },
  skeletonFields: {
    width: '100%',
  },
  root: {
    width: '17vh',
    height: '21.5vh',
    cursor: ({ disabled }) => (disabled ? 'auto' : 'pointer'),
    borderStyle: 'solid',
    borderWidth: ({ active, disabled }) => (active && !disabled ? '2px' : '1px'),
    borderColor: ({ active, disabled }) => {
      if (active) return theme.palette.secondary.dark;
      if (disabled) return theme.palette.grey[300];
      return theme.palette.secondary.light;
    },
  },
  cardContent: {
    paddingBottom: '12px !important',
    cursor: ({ disabled }) => (disabled ? 'auto' : 'pointer'),
  },
  title: {
    fontSize: 16,
    color: ({ active, disabled }) => {
      if (active) return theme.palette.primary.dark;
      if (disabled) return theme.palette.grey[300];
      return theme.palette.grey[500];
    },
    margin: '16px 0px',
    textAlign: 'center',
  },
  titleContainer: {},
  imgContainer: {
    pointerEvents: 'none',
    width: 140,
    height: 140,
    margin: 'auto',
  },
  img: {
    objectFit: 'scale-down',
    height: 150,
  },
});
export default styles;
