import { GLOBAL } from 'utils/constants';

const styles = (theme) => ({
  appBar: {
    position: 'relative',
    alignItems: 'space-between',
    justifyContent: 'center',
  },
  container: {
    padding: theme.spacing(2),
  },
  colorContainer: {
    justifyContent: 'left',
    alignItems: 'baseline',
    marginTop: theme.spacing(1),
    marginButton: theme.spacing(1),
  },
  colorPickersContainer: {
    margin: theme.spacing(0, 0),
    paddingLeft: '8px',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputColor: {
    width: 24,
    height: 24,
  },
  justifyContent: {
    justifyContent: 'left',
    marginButton: 10,
    paddingLeft: 8,
  },
  justifyContentUploadImage: {
    justifyContent: 'center',
    marginButton: 10,
    marginTop: 15,
  },
  textContainer: {
    height: 24,
  },
  radios: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    padding: '0',
    position: 'relative'
  },
  lineDivider: {
    color: 'primary'
  },
  btnClose: {
    borderRadius: 20,
    color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]
  },
  btnAccept: {
    borderRadius: 20,
    color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900],
    border: `1px solid ${GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[400]}`,
  },
  uploadButtons: {
    color: GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[800]
  },
  marginAuto: {
    margin: 'auto'
  },
  orgsImageLabels: {
    marginBottom: 12,
  },
  gridMargin: {
    marginTop: -10,
    marginBottom: 12,
  },
  gridBottom: {
    marginBottom: 15,
  },
  buttonAction: {
    padding:'10px 16px 10px 16px',
    borderRadius: '20px',
    color:'#005F64',
    borderColor:'#005F64',
    height:'37px',
    width:'105px',
    '&:hover': {
      borderColor:'#005F64',
    },
  }
});

export const nameFieldStyle = {
  paddingBottom:24,
  marginBottom:0
}

export const shortNameFieldStyle = {
  marginTop:-10
}

export const gridDividerStyle = {
  marginTop:8
}

export const customDividerStyle = {
  borderColor: '#EEEEEE'
}

export default styles;
