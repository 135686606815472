import {
    makeStyles,
    Typography,
    Box,
  } from '@material-ui/core';
  import {GLOBAL} from 'utils/constants';

import AppliedFilterDisplay from './AppliedFilterDisplay';
    
  const useStyles = makeStyles((theme) => ({
      header: {
        fontWeight: 500,
        fontSize: 14,
        color: GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[800],
        marginBottom: 10,
      },
      container: {
        margin: 3
      }
  }));
  
  function IndicatorOfFiltersAppliedOnAlayer( props ) {
      const classes = useStyles();
      const { header, widgetsFilteringTargetDemmand } = props;
      const formatInformation = ({ widgetName, filter }) => {
        return `${widgetName}${!!filter ? ':' : ''} ${filter || ''}`
      }
  
      return (
          <Box>
              <Typography className={classes.header}> { header } </Typography>
              <Box className={classes.filtersInfo}>
                {
                  widgetsFilteringTargetDemmand?.map( widgetsFiltering => (
                    <Box className={classes.container} display="inline-flex">
                      <AppliedFilterDisplay 
                        information={ formatInformation({ widgetName: widgetsFiltering.widgetName, filter: widgetsFiltering.filter }) }
                        iconBgColor={ widgetsFiltering.layerColor }
                        widgetType={ widgetsFiltering.type }
                      />
                    </Box>
                  ))
                }
              </Box>
          </Box>
      );
  }

  export default IndicatorOfFiltersAppliedOnAlayer;