import * as React from 'react';
import { Grid, makeStyles, Container, Toolbar } from '@material-ui/core';
import { useLocation, Outlet } from 'react-router-dom';

import useOrganization from 'api/hooks/useOrganization';
import logo from 'assets/img/logo.0c7a85ba.png';
import { useTheme } from 'components/providers/CustomThemeProvider';
import styles, {
  MuiTitle,
  MuiGridImgContainer,
  MuiImage,
} from './styles/organization';

const useStyles = makeStyles(styles);

const Organization = () => {
  const classes = useStyles();
  const location = useLocation();
  const orgId = location.pathname.split('/')[2];
  const { data, isLoading } = useOrganization(orgId);
  const { setColors } = useTheme();
  const isSpecificationView = location.pathname.split('/').includes('specification')

  React.useEffect(() => {
    if (data) setColors(data.attrs.primary_color, data.attrs.secondary_color);
  }, [data, setColors]);

  return (
    <Container className={classes.navBar}>
      {!location.pathname.split('/').includes('specification') && (
        <Toolbar className={classes.toolBar}>
          <Grid container spacing={4}>

          </Grid>
        </Toolbar>
      )}
      <Grid container>
        {!isSpecificationView && (
          <MuiGridImgContainer item xs={3}>
            <MuiTitle>{data?.name}</MuiTitle>
            {isLoading ? (
              <>
                <MuiImage src={logo} alt={'logo'}/>
              </>
            ) : (
              <>
                <MuiImage src={data?.attrs.profile_img_url} alt={'logo'}/>
              </>
            )}
          </MuiGridImgContainer>
        )}
        <Grid className={classes.container} item xs={!isSpecificationView ? 9 : 12}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Organization;
