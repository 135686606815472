import * as React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { useAuth } from 'components/providers/AuthProvider';
import logoStatic from 'assets/img/logo.0c7a85ba.png';
import { useTheme } from 'components/providers/CustomThemeProvider';
import useOrganization from 'api/hooks/useOrganization';
import { useSelector } from 'react-redux';
import { GLOBAL } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  img: {
    height: 40,
    objectFit: GLOBAL.POSITION.SCALEDOWN,
    alignSelf: 'center'
  },
  div: {
    paddingLeft: GLOBAL.POSITION._25PX,
  },
  container: {
    maxWidth: '400px'
  },
  applicationName: {
    color: GLOBAL.COLOR.WHITE,
    alignSelf: 'center',
    marginLeft: 8
  },
  organizationlogoNameContainer: {
    display: 'flex',
  },
  organizationName: {
    alignSelf: 'center',
    marginLeft: 4,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: GLOBAL.COLOR.WHITE,
  },
  incluiaImg: {
    width: 33,
    height: 32,
    objectFit: GLOBAL.POSITION.SCALEDOWN,
    alignSelf: 'center'
  },
}));

const OrgInfo = () => {
  const classes = useStyles();
  const [display, setDisplay] = React.useState(false);
  const [logo, setLogo] = React.useState('');
  const { user } = useAuth();
  const { setColors, setSwitchColor, isCustomColor } = useTheme();

  const refetchIsPublicMap = useSelector((state) => state.app.refreshOrgInfoPublicEmbeddedMap);

  const location = useLocation();

  const hashPublic = location.pathname.split('/')[1] === 'public';
  const hashOrgAndScenario = Boolean(
    location.pathname.split('/')[1] === 'orgs' && location.pathname.split('/')[2]
  );

  const id =
    location.pathname.split('/')[2] === 'workspace' ? user.org_id : location.pathname.split('/')[2];

  const { refetch } = useOrganization(id, hashOrgAndScenario);

  const queryClient = useQueryClient();

  const orgData = queryClient.getQueryData(['organization', id]);
  const schemePublicData = queryClient.getQueryData([
    'scheme-public',
    location.pathname.split('/')[4],
  ]);
  const schemeData = queryClient.getQueryData(['scheme', location.pathname.split('/')[6]]);

  React.useEffect(() => {
    if (schemeData && !hashPublic) refetch();
    else if (hashPublic) refetch();
  }, [schemeData, refetch, hashPublic]);

  React.useEffect(() => {
    if (hashPublic && orgData){
      setColors(orgData?.attrs.primary_color, orgData?.attrs.secondary_color );
      setLogo(orgData?.attrs.logo_url);
      setDisplay(true);
    }
  },[orgData])

  React.useEffect(() => {
    if ((hashOrgAndScenario || hashPublic) && (orgData?.attrs || schemePublicData?.org)) {
      if (hashOrgAndScenario) {
        setLogo(orgData.attrs.logo_url);
        setDisplay(true);
      }
      if (hashPublic && schemePublicData?.org) {
        setLogo(schemePublicData.org.logo_url);
        if (schemePublicData.org.primary_color && schemePublicData.org.secondary_color) {
          setColors(schemePublicData.org.primary_color, schemePublicData.org.secondary_color);
          if (!isCustomColor) setSwitchColor(true);
        }
        setDisplay(true);
      }
    } else {
      setDisplay(false);
    }
  }, [
    hashOrgAndScenario,
    hashPublic,
    isCustomColor,
    location.pathname,
    orgData?.attrs,
    setColors,
    setSwitchColor,
    schemePublicData,
    refetchIsPublicMap,
  ]);

  return (
    display && (
      <div className={classes.container}>
        {hashOrgAndScenario || hashPublic ? (
          <div className={classes.organizationlogoNameContainer}>
            <img src={logo} alt={GLOBAL.ALT.LOGO} className={classes.img} />
            <Typography variant="subtitle1" className={classes.organizationName}>{ orgData?.shortName }</Typography>
          </div>
        ) : (
          <img src={logoStatic} alt={GLOBAL.ALT.LOGO} className={classes.img} />
        )}
      </div>
    )
  ) ||
  (
    <div className={classes.organizationlogoNameContainer}>
      <img src="/img/logo.png" alt={GLOBAL.ALT.LOGO} className={classes.incluiaImg} />
      <Typography variant="subtitle1" className={classes.applicationName} >
        INCLUIA
      </Typography>
    </div>
  )
};

export default OrgInfo;
