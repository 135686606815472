import { styled } from '@mui/material';
import { Grid } from '@material-ui/core';
import CheckCircleOutlineRoundedIcon
  from '@mui/icons-material/CheckCircleOutlineRounded';
import { lighten } from '@mui/material/styles';

export const MuiGridItemContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'flex-start',
  alignContent: 'flex-start',
  height: 264,
}));

export const MuiGridItemSort = styled(Grid)(({ theme }) => ({
  backgroundColor:'#FAFAFA',
  borderRadius:'12px',
  padding:'10px, 10px, 10px 0px',
  height:'auto',
  width:'930px',
  maxHeight:'105px',
  overflowY: 'auto'
}));

export const MuiCheckCircleIconDisabled = styled(CheckCircleOutlineRoundedIcon)(({ theme }) => ({
  marginTop:15,
  width: '26px',
  height: '26px',
  padding: 3,
  borderRadius:'50%',
  border: `1px solid ${lighten('#9e9e9e', 0.6)}`,
  align: 'center',
  '&:hover': {
    backgroundColor: lighten('#9e9e9e', 0.7),
  },
  backgroundColor: 'transparent',
  color:'#9e9e9e',
  borderColor:'#9e9e9e',
  marginLeft:'16px',
  pointerEvents: 'none'
}));

export const chipStyles = {
  backgroundColor: '#E0F7FA',
  color: '#254957',
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:14,
  marginRight:0.5,
  padding:'6px 8px 6px 8px',
  borderRadius:8
}
