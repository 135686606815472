import { Paper, styled, TextField } from '@mui/material';
import KeyboardDoubleArrowRightIcon
  from '@mui/icons-material/KeyboardDoubleArrowRight';
import { lighten } from '@mui/material/styles';
import KeyboardDoubleArrowLeftIcon
  from '@mui/icons-material/KeyboardDoubleArrowLeft';

export const MuiSearchColumnField = styled(TextField)(({ theme, searchWidth }) => ({
  '& .MuiInputBase-root': {
    height: '36px',
    width: searchWidth,
    borderRadius:'18px',
  },
}));

export const MuiListColumns = styled(Paper)(({ theme , listColumnsWidth}) => ({
  width: listColumnsWidth,
  height: 230,
  overflow: 'auto'
}));

export const MuiListVisibleColumns = styled(Paper)(({ theme, visibleColumnsWidth }) => ({
  width: visibleColumnsWidth,
  height: 230,
  overflow: 'auto',
}));

export const MuiTransferArrowsRightIcon = styled(KeyboardDoubleArrowRightIcon)(({ theme }) => ({
  color: '#757575',
  cursor: 'pointer',
  height: '26px',
  width: '26px',
  padding: 3,
  border: `1px solid ${lighten('#757575', 0.4)}`,
  borderRadius:'50%',
  marginBottom: 10
}));

export const MuiTransferArrowsLeftIcon = styled(KeyboardDoubleArrowLeftIcon)(({ theme }) => ({
  color: '#757575',
  cursor: 'pointer',
  height: '26px',
  width: '26px',
  padding: 3,
  border: `1px solid ${lighten('#757575', 0.4)}`,
  borderRadius:'50%',
}));

export const outlinedInputStyle = {
  width: '251px',
  marginTop:24,
  height: '56px',
}
export const dragHandleStyle = {
  cursor: 'row-resize',
  marginRight: 8,
  marginBottom: '-1.4rem',
  marginLeft:16,
  color:'#757575'
}
export const typographyCommonStyle = {
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:16,
  color:'#424242'
}
export const gridInputStyle = {
  display: 'flex',
  marginRight: '15px',
  justifyContent: 'flex-end'
}
export const gridFieldStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}
