import {
  Box,
  Grid, IconButton,
  styled,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { lighten } from '@mui/material/styles';

const primaryColor = localStorage.getItem('colorPrimary')

export const MuiBoxToggle = styled(Box)(({ theme }) => ({
  width: '365px',
  height: '32px',
  marginTop:8,
  border: `1px solid ${primaryColor}`,
  backgroundColor: `${lighten(primaryColor , 0.9)}`,
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const MuiTypographyVisuality = styled(Typography)(({ theme }) => ({
  marginTop:24,
  marginBottom:24,
  fontSize:16,
  fontWeight:400,
  fontFamily:'Raleway',
  color:'#000000'
}));

export const MuiTypographySortBy = styled(Typography)(({ theme }) => ({
  fontSize:12,
  fontWeight:400,
  fontFamily:'Montserrat',
  color:'#000000'
}));

export const MuiTypographyCategoryList = styled(Typography)(({ theme }) => ({
  fontSize:16,
  fontWeight:400,
  fontFamily:'Raleway',
  color:'#424242',
  marginBottom:10
}));

export const MuiTypographyCategory = styled(Typography)(({ theme }) => ({
  fontSize:14,
  fontWeight:500,
  fontFamily:'Montserrat',
  color:'#424242',
  marginTop:6,
  paddingLeft:10
}));

export const MuiTypographyToggle = styled(Typography)(({ theme }) => ({
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:12,
  textTransform:'initial'
}));

export const MuiTypographyInfo = styled(Typography)(({ theme }) => ({
  fontSize:16,
  fontWeight:400,
  fontFamily:'Raleway',
  color:'#000000',
  marginBottom:24
}));

export const MuiTypographyFormula = styled(Typography)(({ theme }) => ({
  marginLeft: -12,
  fontSize:20,
  fontWeight:400,
  fontFamily:'Raleway',
  textTransform:'uppercase',
  color:'#000000'
}));

export const MuiContainerSpecificInfo = styled('div')(({ theme }) => ({
  display:'flex',
  flexDirection:'column',
  width:'100%'
}));

export const MuiVisuality = styled('div')(({ theme }) => ({
  display:'flex',
  flexDirection:'column'
}));

export const MuiGridFormula = styled(Grid)(({ theme }) => ({
  display: 'flex',
  maxHeight: 40,
  alignItems: 'center',
  marginLeft: '3rem'
}));

export const MuiCalculatorDisabled = styled(Grid)(({ theme }) => ({
  borderTop:'2px solid #E0E0E0',
  marginTop:'-2.7rem',
  height:'190px',
  pointerEvents: 'none',
  opacity: 0.5,
  backgroundColor: '#f5f5f5',
  color: '#9e9e9e'
}));

export const MuiGridCategory = styled(Grid)(({ theme }) => ({
  paddingTop:8,
  paddingBottom:8,
  borderBottom: '1px solid #E0E0E0'
}));

export const MuiIconButtonCommon = styled(IconButton)(({ theme }) => ({
  marginTop:25,
  marginLeft:16,
  color:'#9E9E9E',
  height:'16px',
  width:'16px'
}));

export const MuiTypographyLimit = styled(Typography)(({ theme }) => ({
  fontWeight:400,
  fontFamily:'Montserrat',
  fontSize:12,
  color:'#424242'
}));

export const MuiTypographyCommon = styled(Typography)(({ theme }) => ({
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:16,
  color:'#000000'
}));

export const MuiGridCategoryContainer = styled(Grid)(({ theme }) => ({
  backgroundColor:'#FAFAFA',
  borderRadius:12,
  marginRight:10
}));

export const MuiToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped':{
    color:primaryColor,
    width: '120px',
    height: '25px',
    marginTop:0.3,
    border:0,
    '&:hover': {
      backgroundColor: `${lighten(primaryColor, 0.9)}`
    }
  },
  '& .Mui-selected':{
    color:'white !important',
    width:'115px',
    height:'24px',
    backgroundColor:`${primaryColor} !important`,
    borderRadius:12,
  },
  border: 0,
  color: 'red',
  '&:hover': {
    backgroundColor: `${lighten(primaryColor, 0.9)}`
  }
}));

export const stepLabelStyle = {
  cursor: 'pointer',
  fontFamily:'Montserrat',
  fontWeight:500,
  fontSize:14,
}

export const labelInputStyle = {
  color: '#424242'
}

export const inputLimitStyle = {
  marginTop: '16px',
  marginLeft: '24px',
  width:'80px'
}

export const commonStyle = {
  height:'56px',
  width:'123px'
}

export const iconButtonStyle = {
  marginTop:20,
  marginLeft:3
}

export const adornmentColorStyle = {
  color: '#9E9E9E',
  marginTop:'-1px'
}

export const inputColorCommonStyle = {
  fontFamily: 'montserrat',
  color: '#424242',
  fontSize: '16px'
}

export const adornmentPercentStyle = {
  color: '#9E9E9E',
  marginTop:'-2px',
  marginLeft:'-4.2rem'
}
