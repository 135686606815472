/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import {
  makeStyles,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlinedIcon from 'components/common/DeleteOutlinedIcon';

import { usePermissions } from 'components/hooks/usePermissions';
import styles from './styles/card';

const useStyles = makeStyles(styles);

const CardOrganization = ({ organization }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { hasPermissionFor } = usePermissions();

  const classes = useStyles();

  return (
      <Card className={classes.root }>
        <CardActionArea className={classes.cardActionArea}>
            <div className={classes.cardContainer}>
            <Link to={`/orgs/${organization._id}/maps`} className={classes.orgName} data-cy="organization-link">
                <CardMedia
                  className={classes.mediaItem}
                  image={organization.attrs.profile_img_url}
                  title={organization.name}
                />
              </Link>
              <div className={classes.organizationDescription}>
                <div className={classes.nameContainer}>
                <Typography className={classes.orgNameTxtv2}>
                  {organization.name}
                </Typography>
                </div>
                <div className={classes.iconContainer}>
                {hasPermissionFor('update_org') && (
                    <IconButton
                      onClick={( e ) => {
                        e.stopPropagation();
                        navigate(`${location.pathname}?edit-organization=${organization._id}`, {
                          replace: true,
                          state: organization,
                        })
                      }}
                      color='primary'
                    >
                      <EditIcon className={classes.icon} sx={{ fontSize: 100 }}/>
                    </IconButton>
                )}
                {hasPermissionFor('delete_org') && (
                    <IconButton
                      onClick={ ( e ) => {
                        e.stopPropagation();
                        navigate(`${location.pathname}?delete=${organization._id}`, {
                          replace: true,
                          state: organization,
                        })
                      }}
                      style={{ color: '#ff1744' }}
                      className={classes.iconButton}
                    >
                      <DeleteOutlinedIcon size={17}/>
                    </IconButton>
                )}
                </div>
              </div>
            </div>
        </CardActionArea>
      </Card>
  );
};

export default CardOrganization;
