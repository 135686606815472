import {
makeStyles,
useTheme,
ThemeProvider,
} from '@material-ui/core';

import {
  ToggleButtonGroup,
  ToggleButton,
} from '@material-ui/lab';

import themeIncluia from 'theme/incluia'
import { generateDynamicShades } from 'utils/colors'
import {GLOBAL} from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
      height: 32,
      borderRadius: 16,
      padding: '0px 1px 0px 1px',
      border: `1px solid ${generateDynamicShades( theme.palette.primary.main )[500]}`,
      height: 32,
      backgroundColor: `${generateDynamicShades( theme.palette.primary.main )[50]}`,
      paddingLeft: '1px',
      paddingRight: '1px',
  },
  toggleButton: {
    borderRadius: '16px',
    width: 31.71,
    height: 24,
    margin: 'auto',
    border: 0,
    marginLeft: '3px',
    marginRight: '3px',
  },
  groupedHorizontal: {
    borderRadius: '16px !important'
  },
  selected: {
    backgroundColor: `${generateDynamicShades( theme.palette.primary.main )[500]} !important`,
    color: `${GLOBAL.COLOR.WHITE} !important`,
  }
}));

const CustomToggleButton = ({ handleValues, values, toggleItems, exclusive, customToggleButtonClassName  }) => {
  const classes = useStyles();
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={themeIncluia}>
      <ToggleButtonGroup
      value={values}
      onChange={handleValues}
      className={ classes.root }
      exclusive={exclusive}
      classes={{
        groupedHorizontal: classes.groupedHorizontal,
        root: customToggleButtonClassName
      }}
      >
          { toggleItems.map( btn => (
              <ToggleButton 
                value={btn.value}
                classes={{
                  root: btn?.classes?.root ? btn.classes.root : classes.toggleButton,
                  selected: btn?.classes?.selected ? btn.classes.selected : classes.selected,
                }}
                >
                  { btn.icon }
              </ToggleButton>
          )) }
      </ToggleButtonGroup>
    </ThemeProvider>
  );
};

export default CustomToggleButton;
