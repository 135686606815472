import React from 'react';
import {
  makeStyles,
  Typography,
  Box
} from '@material-ui/core';
import { useTranslation } from 'components/providers/TranslationProvider';
import TypographyWithInfoIconTooltip from 'components/common/TypographyWithInfoIconTooltip';
import { GLOBAL } from 'utils/constants';

const useStyles = makeStyles((theme) => {
  return ({
    infoCard: (props) => ({
      width: props.isStatisticWidget ? '197px' : '181px',
      height: '71px',
      backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[200],
      padding: '12px 12px 12px 12px',
      borderRadius: 12,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }),
    infoCardData: {
      display: 'inline-flex',
      width: '100%',
      overflow: 'hidden'
    },
    infoCardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    variableLabel: {
      alignSelf: 'center',
      color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    variableValue: {
      color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800],
      fontSize: 21,
      letterSpacing: 0.15,
      fontWeight: 500,
      marginRight: 5
    },
    variableName: {
      marginTop: 5,
    },
    filterInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    filterValue: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '14.63px'
    },
    filterUnit: {
      color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
      marginLeft: 5,
    },
    filterDifferenceContainer: {
      width: 40,
      height: 14,
      borderRadius: 12,
      padding: '1px 6px',
    },
    filterDifference: {
      fontWeight: 500,
      fontSize: 10,
      color: GLOBAL.COLOR.WHITE
    },
    positivePercentage: {
      backgroundColor: '#10B981',
    },
    negativePercentage: {
      backgroundColor: '#D7271D',
    },
    iconTooltipContainer: {
      width: '16px',
      height: '16px',
      justifyContent: 'center',
      display: 'flex',
    },
    infoBtnContainer: {
      marginLeft: theme.spacing(0),
      backgroundColor: theme.palette.common.white,
      transform: 'scale(0.7)',
      '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.grey[100] },
      padding: '0px !important',
    }
  })});

const VariableInfo = ( props ) => {
  const {
    prefix,
    label,
    data,
    name,
    information,
    filterData,
    filterUnit,
  } = props;
  const classes = useStyles({ isStatisticWidget: !!data });
  const { t } = useTranslation();

  function safeDivide(numerator, denominator) {
    if (denominator === 0) {
      return 0;
    }
    const result = numerator / denominator;
    if (Number.isNaN(result) || !Number.isFinite(result)) {
      return 0;
    }
    return result;
  }

  const growthRate = React.useMemo(() => {
    if (data && filterData) {
      const parseData = parseFloat(data?.replace(/,/g, ''));
      const parseFilterData = parseFloat(filterData.replace(/,/g, ''));
      if (!Number.isNaN(parseData) && !Number.isNaN(parseFilterData)) {
        const rate = safeDivide((parseData - parseFilterData), parseData) * 100;
        return -1 * Math.round(rate);
      }
    }
    return 0;
  }, [ data, filterData]);

  return (
    <div className={ `${classes.infoCard}` }>
      <div className={classes.infoCardHeader}>
        <Typography variant="caption" className={classes.variableLabel}>{ label }</Typography>
        { information && <TypographyWithInfoIconTooltip className={classes.iconTooltipContainer} customBtnStyles={classes.infoBtnContainer}>
          { t( information ) }
        </TypographyWithInfoIconTooltip>}
      </div>
      <div className={classes.infoCardData}>
        {(!!prefix)&&(
          <Typography variant='overline' className={classes.variableName}>
            {prefix||''}
          </Typography>
        )}
        <Typography className={classes.variableValue}>
          { filterData || 0 }
        </Typography>
        {(!!name)&&(
          <Typography variant='overline' className={classes.variableName}>
            {`${name}`}
          </Typography>
        )}
      </div>
      { data && (<div className={classes.filterInfo}>
        <Box display="inline-flex" alignItems="center">
          <Typography className={classes.filterValue}>
            { data || 0 }
          </Typography>
          <Typography variant='overline' className={classes.filterUnit}>
            { filterUnit }
          </Typography>
        </Box>
        <Box display="inline-flex" alignItems="center" justifyContent="center" className={`${classes.filterDifferenceContainer} ${growthRate >= 0 ? classes.positivePercentage : classes.negativePercentage}`}>
          <Typography className={classes.filterDifference}>{`${growthRate>0?'+':''}${growthRate}`}%</Typography>
        </Box>
      </div>)}
    </div>
  );
};

export default VariableInfo;
