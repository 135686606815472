import { GLOBAL } from 'utils/constants';

const styles = (theme) => ({
  btnIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  btnIconSelected: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    borderStyle: 'solid',
    borderRadius: 0,
    paddingBottom:'2px',
    paddingTop:'2px',
  },
  icon: {
    width: '18px !important',
    height: '18px !important',
    marginRight: 0,
    marginLeft: theme.spacing(1),
    color: GLOBAL.COLOR.WHITE,
    fontSize:16,
  },
  iconSelected: {
    width: '18px !important',
    height: '18px !important',
    marginRight: 0,
    marginLeft: 0,
    color: GLOBAL.COLOR.WHITE,
  },
  breadcrumb: {
    '& .MuiBreadcrumbs-separator': {
      color: GLOBAL.COLOR.WHITE,
      opacity: 0.7,
    },
  },
  displayFlex: {
    display: 'flex'
  },
  breadCrumbLocation: {
    margin: 'auto',
    color: GLOBAL.COLOR.WHITE,
    paddingRight: 5,
  },
  breadCrumbItemHover: {
    '&:hover': {
      borderBottom: `0.5px solid ${ GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[100] }`,
      '& a': {
        color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[100],
      },
      '& svg': {
        fill: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[100],
      }
    },
  },
  breadCrumbLastItem: {
    borderBottom: `2px solid ${GLOBAL.COLOR.WHITE}`
  }
});
export default styles;
