import * as React from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';

import generateRandomColors from 'utils/generateRandomColors';
import { useTranslation } from 'components/providers/TranslationProvider';

import LegendItems from './LegendItems';
import AddItemLegendModal from './AddItemLegendModal';
import styles, {
  MuiGridContainer,
  MuiTypographyBorderColor,
  MuiTypographyIcon,
  MuiTypographyName
} from './styles/legend';
import generateRandomIcons from 'utils/generateRandomIcons';
import { Typography } from '@mui/material';
import {
  iconCommonAddStyles,
  MuiCommonButtonAdd,
  MuiCommonTypographyAdd
} from '../../styles/common';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(styles);
const iconCustomDefault = 'marker.svg';
const Legend = ({ datasetName, items, setItems, values, isPointDataset, action}) => {
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();
  const classes = useStyles();
  const getRandomId = () => Date.now().toString(36) + Math.random().toString(36).substr(2);

  const handleAddItem = () => setOpen(true);

  const setItem = (item) => setItems((prevVal) => prevVal.concat([item]));

  const handleClose = () => setOpen(false);

  const handleAddAll = () => {
    const colors = generateRandomColors(values.data.length);
    const icons = generateRandomIcons(values.data.length);

    const valuesToSet = values.data
      .map((c, i) => ({
        color: colors[i],
        id: getRandomId(),
        name: c?.split('_').join(' ') || c,
        value: c,
        icon: icons[i]
      }))
      .concat([{
        id: 'other',
        color: localStorage.getItem('colorPrimary'),
        value: 'Other',
        icon: iconCustomDefault}])
    setItems(valuesToSet);
  };

  return (
    <>
      <Grid container
            component={Paper}
            justifyContent='center'
            className={classes.containerLegendItems}
      >
        <MuiGridContainer container item >
          <Grid item xs={6}>
            <Typography variant='subtitle1'
                        fontSize={16}
                        style={{marginLeft:'16px'}}
                        color={'#616161'}
                        marginTop={1}
                        letterSpacing={0.15}
                        fontWeight={400}
                        fontFamily={'Raleway'}>
              {t('list_values')}
            </Typography>
          </Grid>
          <Grid item  style={{marginLeft:'25px'}}>
            <MuiCommonButtonAdd
              variant='outlined'
              color='primary'
              startIcon={<AddIcon style={iconCommonAddStyles}/>}
              onClick={handleAddAll}
              disabled={!values.isSuccess || values.data?.length < 1}
            >
              <MuiCommonTypographyAdd> {t('add_all')} </MuiCommonTypographyAdd>
            </MuiCommonButtonAdd>
          </Grid>
          <Grid item xs={2} style={{marginLeft:'5px'}}>
            <MuiCommonButtonAdd
              variant='outlined'
              color='primary'
              startIcon={<AddIcon style={iconCommonAddStyles}/>}
              onClick={handleAddItem}
              disabled={
                !values.isSuccess ||
                values.data?.length < 1 ||
                items?.length - 1 === values.data?.length
              }
            >
              <MuiCommonTypographyAdd> {t('add_item')} </MuiCommonTypographyAdd>
            </MuiCommonButtonAdd>
          </Grid>
        </MuiGridContainer>
        <Grid container item style={{marginTop:'10px'}}>
          <Grid item xs={7}>
            <MuiTypographyName
              variant='caption'
              fontSize={12}
              letterSpacing={0.4}
              fontWeight={400}
              fontFamily={'Montserrat'}>
              {t('name')}
            </MuiTypographyName>
          </Grid>
          <Grid item xs={3}>
            <MuiTypographyBorderColor
              variant='caption'
              fontSize={12}
              letterSpacing={0.4}
              fontWeight={400}
              fontFamily={'Montserrat'}>
              {t('border_color')}
            </MuiTypographyBorderColor>
          </Grid>
          <Grid item xs={2}>
            <MuiTypographyIcon
              variant='caption'
              fontSize={12}
              letterSpacing={0.4}
              fontWeight={400}
              fontFamily={'Montserrat'}>
              {t('icon')}
            </MuiTypographyIcon>
          </Grid>
        </Grid>
        <LegendItems
          setItems={setItems}
          items={items}
          setItem={setItem}
          showOthers={
            values.isSuccess &&
            items?.length - 1 !== values.data?.length &&
            values.data?.length > 0 &&
            items?.length > 1
          }
          isPointDataset={isPointDataset}
          action={action}
        />
      </Grid>
      <AddItemLegendModal
        open={open}
        items={items}
        onClose={handleClose}
        datasetName={datasetName}
        setItem={setItem}
        values={values.data}
        isPointDataset={isPointDataset}
      />
    </>
  );
};

export default Legend;
