import { Typography, Grid, FormControl, Popover } from '@mui/material';
import * as React from 'react';
import Modal from "./Modal";
import {FormControlLabel, RadioGroup} from "@mui/material";
import ops from 'assets/img/ops-validate.svg'
import {useTranslation} from "../providers/TranslationProvider";
import {MuiButton} from "../views/organizations/styles/user";
import {
  descriptionStyle, MuiAlertValidate, MuiBoxAction, styles,
  MuiBoxAlternative, MuiBoxContainer, MuiBoxExpression,
  MuiButtonAction, MuiCheckCircleOutlineIcon, MuiRadio
} from './styles/toastValidation';

const ToastValidation = ({ headerText, textMessage, handleClose,createAlias,
                           anchorEl, open = false, setSelectedValues}) => {

  const message = textMessage ? textMessage?.message : '';
  const alternatives = textMessage ? textMessage?.alternatives : [];
  const status = textMessage?.status;
  const [selectedValue, setSelectedValue] = React.useState('');
  const {t} = useTranslation();
  const classes = styles();

  const handleChange = (event) => setSelectedValue(event.target.value);

  const handleSave = () => {
    createAlias()
    handleClose()
  }

  React.useEffect(() => {
    const alternativeValue = selectedValue.split(/(\+|\-|\*|\/)/).map(item => item.trim());
    setSelectedValues(alternativeValue)

  },[selectedValue])

  return (
    <>
    {status === 'success' &&
      (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <MuiAlertValidate
            severity="success"
            iconMapping={{
              success: <MuiCheckCircleOutlineIcon fontSize="inherit" />,
            }}
            >
            <Typography variant='subtitle2'
                        fontFamily='Raleway'
                        fontSize={20}
                        sx={{color: `${localStorage.getItem('colorPrimary')}`}}>
              {headerText}
            </Typography>
            <Typography variant='body1'
                        fontFamily='Montserrat'
                        sx={{width: '351px'}}
            >
              {message}
            </Typography>
          </MuiAlertValidate>
        </Popover>
      )}
      {status === 'failed' &&(
        <Modal open={open} widthInPixels={848} heightInPixels={501}>
          <Grid >
            <Grid container columnSpacing={4.5}>
              <Grid item xs={6} container direction="column" alignItems="center" gap={'18px'}>
                <Typography id="modal-title"
                            variant="h3"
                            fontFamily='Raleway'
                            sx={{color: '#FF5549'}}>
                  Ops!
                </Typography>
                <img src={ops}/>
                <Typography id="modal-description"
                            variant={'body1'}
                            fontFamily='Montserrat'
                            sx={{textAlign: 'center'}}>
                  {message}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6"
                            fontFamily='Montserrat'
                            fontSize={16}
                            textTransform='uppercase'
                            sx={{ marginBottom:'18px' }}>
                  {t('alternatives')}
                </Typography>
                <FormControl>
                  <RadioGroup value={selectedValue} onChange={handleChange}>
                    <Grid container direction="column" spacing={2}>
                      {alternatives.map((alt, index) => (
                        <Grid item key={index}>
                          <MuiBoxContainer>
                            <FormControlLabel
                              value={alt.expression}
                              control={<MuiRadio />}
                              label={
                                <MuiBoxAlternative>
                                  <MuiBoxExpression>
                                    <Typography variant="body2"
                                                fontFamily='Montserrat'
                                                sx={{color: '#254957' }}>
                                      {alt.expression}
                                    </Typography>
                                  </MuiBoxExpression>
                                  <Typography variant="caption"
                                              fontFamily='Montserrat'
                                              sx={descriptionStyle}>
                                    {alt.description}
                                  </Typography>
                                </MuiBoxAlternative>
                              }
                            />
                          </MuiBoxContainer>
                        </Grid>
                      ))}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <MuiBoxAction>
            <MuiButton onClick={handleClose}
                       color='primary'
                       variant='text'
                       className={classes.btnClose}>
              <Typography  fontFamily='Montserrat'
                           fontSize={14}
                           letterSpacing={0.25}>
                {t('cancel_btn')}
              </Typography>
            </MuiButton>
            <MuiButtonAction
              onClick={handleSave}
              color='primary'
              variant='outlined'
              >
              <Typography  fontFamily='Montserrat'
                           fontSize={14}
                           letterSpacing={0.25}>
                {t('save_changes')}
              </Typography>
            </MuiButtonAction>
          </MuiBoxAction>
        </Modal>
      )}
    </>
);
};
export default ToastValidation;
