import * as React from 'react';
import {
  makeStyles,
  Button as MuiButton,
  Grid,
  Container,
  MenuItem,
  TextField as MuiTextField,
  Switch
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as yup from 'yup';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';

import Modal from 'components/common/Modal';
import Toast from 'components/common/Toast';
import { TextField, SelectField } from 'components/common/ReactHooksFormFields';
import Button from 'components/common/Button';

import { useTranslation } from 'components/providers/TranslationProvider';
import useUpdateUser from 'api/hooks/useUpdateUser';
import useCreateUser from 'api/hooks/useCreateUser';
import useOrganizations from 'api/hooks/useOrganizations';
import { useAuth } from 'components/providers/AuthProvider';
import { giveMeId } from 'utils/supportComponents';
import useRandomId from 'components/hooks/useRandomId';
import { GLOBAL } from 'utils/constants';
import {
  setMessageToast as setMessageToastSlice,
  setOpenToast as setOpenToastSlice
} from 'store/appSlice';
import {useDispatch} from 'react-redux';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 20,
  },
  displayFlex: {
    display: 'flex'
  },
  isUserAllowedDownloadLayersContainer: {
    marginTop: 30
  },
  isUserAllowedDownloadLayersSwitch: {
    marginLeft: 10
  }
}));

const selectUserTypeInputValues = [
  {
    value: 'level_1',
    label: 'Level 1',
  },
  {
    value: 'level_2',
    label: 'Level 2',
  },
  {
    value: 'level_3',
    label: 'Level 3',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
];

const FormModal = ({ action, open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: organizations, isLoading } = useOrganizations();
  const { user: authUser } = useAuth();
  const randomId = useRandomId();
  const dispatch = useDispatch();

  const location = useLocation();
  const orgId = giveMeId(location.pathname, authUser);
  const user = location.state;

  const {
    mutateAsync: updateUser,
    status: updateStatus,
    error: updateError,
    isLoading: updateIsLoading,
  } = useUpdateUser();
  const {
    mutateAsync: createUser,
    status: createStatus,
    error: createError,
    isLoading: createIsLoading,
  } = useCreateUser();

  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');

  const getDefaultValues = () => {
    return user
      ? {
          name: user.name,
          lastname: user.lastname,
          email: user.email,
          userType: user.type,
          is_user_allowed_download_layers: user.is_user_allowed_download_layers
        }
      : {};
  };

  const schema = yup.object().shape({
    name: yup.string()
    .min(3, 'min_3_characters')
    .max(40, 'max_40_characters')
    .matches('[a-zA-Z]+', 'only_letters')
    .required('required'),
    lastname: yup
      .string()
      .min(3, 'min_3_characters')
      .max(40, 'max_40_characters')
      .matches('[a-zA-Z]+', 'only_letters')
      .required('required'),
    email: yup.string().email('invalid_email').when('updating', {
      is: action === 'create',
      then: yup.string().email('email_required').required('required'),
      otherwise: yup.string().notRequired(),
    }),
    password: yup.string().when('updating', {
      is: action === 'create',
      then: yup.string().min(4, 'min_4_characters').required('required'),
      otherwise: yup.string().notRequired(),
    }),
    userType: yup.string().required('Required'),
    is_user_allowed_download_layers: yup.boolean().notRequired(),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
    control,
    setValue,
    watch,
  } = methods;

  const userType = watch('userType' , '');
  const is_user_allowed_download_layers = watch('is_user_allowed_download_layers');

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const updUser = (data) => {
    let adminRight = [];
    let nonAdminUserOrgIdsAccessPermissions = [];

    if ( data.userType === 'admin' ) {
      adminRight = data.organizations ? data.organizations.map((o) => o.id) : [];
    } else {
      nonAdminUserOrgIdsAccessPermissions = data.organizations ? data.organizations.map((o) => o.id) : [];
    }
    const userToUpdate = {
      ...user,
      name: data.name,
      lastname: data.lastname,
      type: data.userType,
      admin_rights: adminRight,
      non_admin_users_orgs_ids_access_permissions: nonAdminUserOrgIdsAccessPermissions,
      org_id: orgId,
      user_id: user._id,
      is_user_allowed_download_layers: data.is_user_allowed_download_layers
    };
    updateUser(userToUpdate);
  };

  const newUser = (data) => {
    let adminRight = [];
    let nonAdminUserOrgIdsAccessPermissions = [];

    if ( data.userType === 'admin' ) {
      adminRight = data.organizations ? data.organizations.map((o) => o.id) : [];
    } else {
      nonAdminUserOrgIdsAccessPermissions = data.organizations ? data.organizations.map((o) => o.id) : [];
    }
    const userToCreate = {
      user: {
        id: randomId,
        name: data.name,
        lastname: data.lastname,
        email: data.email,
        password: data.password,
        type: data.userType,
        org_id: orgId,
        admin_rights: adminRight,
        non_admin_users_orgs_ids_access_permissions: nonAdminUserOrgIdsAccessPermissions,
        is_user_allowed_download_layers: data.is_user_allowed_download_layers,
      },
      user_id: giveMeId(location.pathname, authUser),
    };
    createUser(userToCreate).then( (response) => {
      if ( response.data.error === 'user_email_already_exist_in_org' ) {
        dispatch(setOpenToastSlice(true));
        dispatch(setMessageToastSlice( t('user_email_already_exist_in_org').replace('{{organization}}', response.data.error_value) ));
      }
    } );
  };

  const onSubmit = handleSubmit((data) => {
    data.name = data.name.trim();
    data.lastname = data.lastname.trim();
    return action === 'update'
      ? updUser(data)
      : newUser(data);
  });

  React.useEffect(() => {
    if (updateStatus === 'success') {
      onClose();
    }
    if (updateStatus === 'error') {
      setError(t(updateError.data.error));
      setOpenToast(true);
    }
  }, [updateStatus, t, onClose, updateError?.data.error]);

  React.useEffect(() => {
    if (createStatus === 'success') {
      onClose();
    }
    if (createStatus === 'error') {
      setError(t(createError?.data.error));
      setOpenToast(true);
    }
  }, [createStatus, t, onClose, createError?.data.error]);

  React.useEffect(() => {
    if ( (user?.type === GLOBAL.ROLES.ADMIN || user?.type === GLOBAL.ROLES.SUPERADMIN) && organizations) {
      const orgsArr = organizations
        .filter((o) => user.myOrgs.includes(o.name))
        // eslint-disable-next-line no-underscore-dangle
        .map((o) => ({ name: o.name, id: o._id }));
      setValue('organizations', orgsArr);
    } else if ( (user?.type !== GLOBAL.ROLES.ADMIN && user?.type !== GLOBAL.ROLES.SUPERADMIN) && organizations ) {
      const relatedOrgs = user?.myOrgs ? user?.myOrgs : user?.relatedOrgs;
      const orgsArr = organizations
        .filter((o) => relatedOrgs?.split(', ').includes(o.name))
        // eslint-disable-next-line no-underscore-dangle
        .map((o) => ({ name: o.name, id: o._id }));
      setValue('organizations', orgsArr);
    }
  }, [user, organizations ]);

  const [inputValue, setInputValue] = React.useState('');

  const formUser = (
    <Container display='flex' className={classes.container}>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      <Grid item xs={12} className={classes.inputText}>
        <TextField
          autoFocus
          name='name'
          margin='dense'
          type='text'
          label={t('user_name')}
          variant='outlined'
          fullWidth
          error={t(errors.name?.message)}
          data-cy="userName"
        />
      </Grid>
      <Grid item xs={12} className={classes.inputText}>
        <TextField
          name='lastname'
          margin='dense'
          type='text'
          label={t('lastname')}
          variant='outlined'
          fullWidth
          error={t(errors.lastname?.message)}
          data-cy="userLastName"
        />
      </Grid>
      {action === 'create' && (
        <>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              fullWidth
              label={t('email')}
              name='email'
              autoComplete='email'
              error={t(errors.email?.message)}
              data-cy="userEmail"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              fullWidth
              name='password'
              autoComplete='current-password'
              error={t(errors.password?.message)}
              label={t('password')}
              data-cy="userPassword"
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <SelectField
          name='userType'
          select
          label={t('user_type_label')}
          variant='outlined'
          value={userType}
          error={t(errors.userType?.message)}
          fullWidth
          data-cy="userRole"
        >
          {selectUserTypeInputValues
            .filter((o) => (authUser.type === 'admin' ? o.value !== 'admin' : o.value))
            .map((o) => (
              <MenuItem key={o.value} value={o.value} data-cy="userRoleOption">
                {o.label}
              </MenuItem>
            ))}
        </SelectField>
      </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id='organizations-id'
                multiple
                options={
                  // eslint-disable-next-line no-underscore-dangle
                  organizations ? organizations.map((o) => ({ id: o._id, name: o.name })) : []
                }
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                disabled={isLoading}
                filterSelectedOptions
                // eslint-disable-next-line react/prop-types
                value={value}
                onChange={(_, values) => {
                  setValue('organizations', values);
                  onChange(values);
                }}
                inputValue={inputValue}
                onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label={ userType === GLOBAL.ROLES.ADMIN || userType === GLOBAL.ROLES.SUPERADMIN  ? t('select_org_label') : t('select_non_user_org_label') }
                    name='organizations'
                    variant='outlined'
                    fullWidth
                  />
                )}
              />
            )}
            control={control}
            name='organizations'
            defaultValue={[]}
          />
        </Grid>
      { (userType === 'level_1' || userType === 'level_2') && <Grid item xs={12}>
        <div className={`${classes.displayFlex} ${classes.isUserAllowedDownloadLayersContainer}`}>
      <Typography>{ 'Is the user allowed to download layers?' }</Typography>
      <Switch
        name='is_user_allowed_download_layers'
        checked={is_user_allowed_download_layers}
        onChange={ (e) => setValue('is_user_allowed_download_layers', e.target.checked, { shouldDirty: true }) }
        inputProps={{ 'aria-label': 'controlled' }}
        size='small'
        color='primary'
        className={classes.isUserAllowedDownloadLayersSwitch}
      />
      </div>
      </Grid> }
    </Container>
  );

  const actions = (
    <>
      <MuiButton onClick={onClose} color='primary' variant='text' className={classes.btnClose}>
        {t('cancel_btn')}
      </MuiButton>
      <Button
        onClick={onSubmit}
        color='primary'
        loading={updateIsLoading || createIsLoading || isLoading}
        disabled={!isValid || updateIsLoading || createIsLoading || isLoading || (action === 'update' ? Object.keys(dirtyFields).length === 0 : false)}
        data-cy="userSubmitData"
      >
        {t('save_changes')}
      </Button>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          onClose={onClose}
          title={action === 'update' ? `${t('update')}: ${user?.name}` : `${t('create_user')}`}
          actions={actions}
        >
          {formUser}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default FormModal;
