import * as React from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';

import { useTranslation } from 'components/providers/TranslationProvider';

import styles from '../styles/uploadImage';

const useStyles = makeStyles(styles);

const UploadFile = ({ handleChange, name }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container className={classes.justifyContent}>
      <Grid container className={classes.justifyContent}>
        <input
          name={name}
          accept='application/geo+json'
          id={`contained-button-file-logo-${name}`}
          className={classes.input}
          type='file'
          onChange={handleChange}
        />
        <label htmlFor={`contained-button-file-logo-${name}`}>
          <Button variant='outlined' color='primary' component='span' className={classes.upload}>
            {t('upload')}
          </Button>
        </label>
      </Grid>
    </Grid>
  );
};

export default UploadFile;
