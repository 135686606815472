import { Container, Typography,  Grid, Button, styled, Tabs, Tab } from '@mui/material';


export const MuiButton = styled(Button)(({ theme }) => ({
  justifySelf: 'end',
  borderRadius:'16px',
  fontFamily:'Montserrat',
  fontSize:14,
  color: theme.palette.primary,
  borderColor: theme.palette.primary,
  padding:'5px 12px 5px 12px'
}));

export const MuiContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(-1),
  width:'1050px',
  paddingLeft:'15px !important',
  padding: theme.spacing(4),
  alignItems: 'left',
  backgroundColor:'#FAFAFA',
  borderRadius:'28px',
  '& .MuiContainer-root': {
    maxWidth: '1360px !important',
  },
}));

export const MuiDivContainer = styled("div")(({ theme }) => ({
  height: '94vh',
  position: 'relative',
  left: '-60px',
}));

export const MuiGridButtonsContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(0, 2, 2, 2),
  justifyContent: 'flex-end',
}));

export const MuiGridTitle = styled(Grid)(({ theme }) => ({
  paddingLeft:'0px !important',
  paddingTop:'0px !important'
}));

export const MuiTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize:21,
  fontFamily:'Raleway',
  textAlign:'left',
  fontWeight:500
}));
