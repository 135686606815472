import * as React from 'react';
import { Typography, Grid, Button as MuiButton, makeStyles, IconButton, Box } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NavLink, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useTranslation } from 'components/providers/TranslationProvider';
import { useAuth } from 'components/providers/AuthProvider';
import { TextField } from 'components/common/ReactHooksFormFields';
import Toast from 'components/common/Toast';
import styles from './styles/login';

const useStyles = makeStyles(styles);

const schema = yup.object().shape({
  email: yup.string().email('email_required').required('required'),
});

const ForgotPassword = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [successfully, setSuccessfully] = React.useState(false);
  const location = useLocation();

  const getDefaultValues = () => {
    return location.state
      ? {
          email: location.state,
        }
      : {};
  };

  const { forgotPassword, isPendingAuth } = useAuth();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const handleCloseToast = () => {
    setOpenToast(false);
  };
  const handleSuccess = () => {
    setSuccessfully(true);
  };

  const handleError = (e) => {
    setError(t(e));
    setOpenToast(true);
  };

  const onSubmit = (data) =>
    forgotPassword({ email: data.email, redirectUrl: '/' }, handleError, handleSuccess);

  return (
    <div className={classes.paper}>
      <Toast message={error} handleClose={handleCloseToast} severity='error' open={openToast} />
      <Box display='flex' sx={{ justifyContent: 'flex-start', width: '100%', marginBottom: '16px' }}>
        <IconButton
        component={NavLink}
        to="/login"
        aria-label="back"
      >
        <ArrowBackIcon style={{color: 'black'}} />
      </IconButton>
    </Box>
      <Typography className={classes.forgot}>
        {t('forgot_password')}
      </Typography>
     {!successfully && <Typography component='p' variant='body1' className={classes.text} >
       {t('password_forgot_info')}
      </Typography>
     }
      <FormProvider {...methods}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {successfully && <Typography>{t('pasword_forgot_success')}</Typography>}
              <TextField
                autoFocus
                variant='outlined'
                fullWidth
                label={t('email')}
                name='email'
                autoComplete='email'
                error={t(errors.email?.message)}
                disabled={successfully}
                data-cy="forgotPasswordEmail"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.containerButtons}>

            <Grid item xs={12} className={classes.containerButtons}>
              <MuiButton
                loading={isPendingAuth}
                type='submit'
                fullWidth
                className={`${classes.submitLogin} ${!isValid ? classes.submitLoginDisabled : '' }`}
                disabled={!isValid || isPendingAuth || successfully}
                data-cy="forgotPasswordSubmit"
              >
                {isPendingAuth ? t('sending...') : t('send_email')}
              </MuiButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default ForgotPassword;
