import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Grid,
  Link as MaterialLink,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'components/providers/TranslationProvider';
import { useAuth } from 'components/providers/AuthProvider';
import { GLOBAL } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  avatar: {
    cursor: 'pointer',
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    marginLeft: theme.spacing(1),
  },
  typography: {
    cursor: 'pointer',
    color: GLOBAL.COLOR.WHITE,
  },
  userInfo: {
    flexDirection: 'column',
    display: 'flex',
    margin: 8
  }
}));

const UserMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const { logout, user } = useAuth();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    logout();
    handleClose();
  };
  const handleTranslation = () => {
    navigate('/translations');
    handleClose();
  };

  const userFriendlyRoleName = () => {
    if ( user.type === GLOBAL.ROLES.SUPERADMIN || user.type === GLOBAL.ROLES.ADMIN ) {
      return t(`user_role_${user.type}`);
    }
    const userRoleName = user.type;
    return `${t('user_role_level')} ${ userRoleName.charAt(userRoleName.length - 1) }`;
  }

  return (
    <>
      <MaterialLink
        edge='end'
        aria-label='account of current user'
        aria-controls='menu-login'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
        data-cy="userMenu"
        underline="none"
      >
        <Grid container alignItems='center' item wrap='nowrap'>
         <Avatar className={classes.avatar}>
            <PersonIcon />
          </Avatar>
          <div className={classes.userInfo}>
          <Typography variant='caption' color='inherit' noWrap className={classes.typography} data-cy="userInfo">
              {user.name}
            </Typography>
            <Typography variant='caption' color='inherit' noWrap className={classes.typography} data-cy="userInfo">
              { userFriendlyRoleName() }
            </Typography>
          </div>
        </Grid>
      </MaterialLink>
      <Menu
        id='menu-login'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {user.type === 'super_admin' && (
          <MenuItem onClick={handleTranslation} data-cy="manageTranslations" >{t('translations')}</MenuItem>
        )}
        <MenuItem onClick={handleLogout}>{t('log_out')}</MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
