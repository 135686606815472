import * as React from 'react';
import { Snackbar, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { GLOBAL } from 'utils/constants';

const useStyles = makeStyles(() => ({
  success: {
    backgroundColor: '#e4ffe5',
  },
}));

const Toast = ({ message, handleClose, severity, open = false, showTime = 6000, title = '' }) => {
  const classes = useStyles();
  return (
    <Snackbar
      open={open}
      autoHideDuration={showTime}
      onClose={handleClose}
      anchorOrigin={{
        vertical: GLOBAL.POSITION.TOP,
        horizontal: GLOBAL.POSITION.RIGHT,
      }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        classes={{ standardSuccess: classes.success }}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
