import * as React from 'react';
import { Popover, Box, makeStyles } from '@material-ui/core';
import { ChromePicker } from 'react-color';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
    borderRadius: 4,
    borderWidth: 0,
    outlineOffset: '2px',
    '&:hover': {
      outline: '1.5px ridge black',
      outlineOffset: '2px',
    },
  },
}));

const CustomInputColorGradient = React.forwardRef(
  (
    {
      value,
      name,
      defaultValue,
      className = {},
      onChange = (x) => x,
      width = 30,
      height = 30,
      gradient = ['', ''],
      hidden = false,
    },
    ref
  ) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [color, setColor] = React.useState(value || defaultValue);
    const [gradientArr, setGradientArr] = React.useState(gradient);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChange = (val) => {
      setColor(val);
      onChange({ target: { name, value: val } });
      setGradientArr(['', '']);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <div name={name} id={name} className={className}>
        <Box
          name={name}
          ref={ref}
          aria-describedby={id}
          onClick={handleClick}
          className={classes.root}
          style={{
            width,
            height,
            background: gradientArr[0]
              ? `linear-gradient(to right, ${gradientArr[0]} , ${gradientArr[1]})`
              : color,
            display: hidden ? 'none' : 'inherit',
          }}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ChromePicker
            ref={ref}
            id={name}
            name={name}
            onChange={(e) => handleChange(e.hex)}
            color={color}
            disableAlpha
          />
        </Popover>
      </div>
    );
  }
);

export default CustomInputColorGradient;
