import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, makeStyles, Typography } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import { setViewState } from 'lib/react-geo-tool/packages/react-redux/src/';
import { GLOBAL } from 'utils/constants';
import {IconButton} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: GLOBAL.POSITION.FLEX,
    alignItems: GLOBAL.POSITION.CENTER,
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    width: 120,
    height: 40,
    justifyContent: 'center',
    padding: '0px 8px',
  },
  typography: {
    placeSelf: GLOBAL.POSITION.CENTER,
    display: GLOBAL.POSITION.FLEX,
    justifyContent: GLOBAL.POSITION.SPACEAROUND,
    alignItems: GLOBAL.POSITION.CENTER,
    backgroundColor: theme.palette.common.white,
    minHeight: theme.spacing(4.5),
  },
  btn: {
    padding: 10,
    '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.grey[100] },
    width: '24px',
    height: '24px',
  },
  divider: {
    width: '1px',
    height: '24px',
    backgroundColor: '#e0e0e0',
    margin: '0 9px',
  },
}));

function ZoomControl() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const zoomLevel = useSelector((state) => Math.floor(state.carto.viewState.zoom));

  const increaseZoom = React.useCallback(() => {
    dispatch(setViewState({ zoom: zoomLevel + 1 }));
  }, [dispatch, zoomLevel]);

  const decreaseZoom = React.useCallback(() => {
    dispatch(setViewState({ zoom: zoomLevel - 1 }));
  }, [dispatch, zoomLevel]);

  return (
    <div className={classes.root}>
      <IconButton onClick={decreaseZoom} className={classes.btn} aria-label={GLOBAL.ARIALABEL.INCREASEZOOM}>
        <RemoveOutlinedIcon color={GLOBAL.COLOR.PRIMARY} style={{width: 14, height: 14}} />
      </IconButton>
      <div className={classes.divider}></div>
      <Typography variant='body1' color={GLOBAL.COLOR.PRIMARY} className={classes.typography}>
        {zoomLevel}
      </Typography>
      <div className={classes.divider}></div>
      <IconButton onClick={increaseZoom} className={classes.btn} aria-label={GLOBAL.ARIALABEL.INCREASEZOOM}>
        <AddOutlinedIcon color={GLOBAL.COLOR.PRIMARY} style={{width: 14, height: 14}}/>
      </IconButton>
    </div>
  );
}

export default ZoomControl;
