import { styled } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { lighten } from '@mui/material/styles';
import VisibilityOffOutlinedIcon
  from '@mui/icons-material/VisibilityOffOutlined';
import { Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

export const MuiVisibilityIcon = styled(VisibilityOutlinedIcon)(({ theme }) => ({
  color:'#757575',
  minWidth: 25,
  width: '26px',
  height: '26px',
  padding: 3,
  border: `1px solid ${lighten('#757575', 0.6)}`,
  borderRadius:'50%',
}));

export const MuiVisibilityOffIcon = styled(VisibilityOffOutlinedIcon)(({ theme }) => ({
  color:'#757575',
  minWidth: 25,
  width: '26px',
  height: '26px',
  padding: 3,
  border: `1px solid ${lighten('#757575', 0.6)}`,
  borderRadius:'50%',
}));

export const MuiEditIcon = styled(EditIcon)(({ theme }) => ({
  color: '#757575',
  minWidth: 25,
  marginTop: '9px',
  marginRight: '2px',
  width: '26px',
  height: '26px',
  padding: 3,
  border: `1px solid ${lighten('#757575', 0.6)}`,
  borderRadius:'50%',
  align: 'center',
  cursor:'pointer'
}));

export const MuiGridRowContainer = styled(Grid)(({ theme }) => ({
  borderBottom:'1px solid #E0E0E0',
  paddingTop: '5px',
  height: '52px',
  paddingBottom: '5px'
}));

export const MuiGridContent = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

export const MuiGridInput = styled(Grid)(({ theme }) => ({
  display: 'grid',
  alignItems: 'center'
}));

export const dragWidgetListStyles = {
  cursor: 'row-resize',
  color:'#757575',
  marginRight: 8,
  marginTop:'10px'
}

export const fontStyles = {
  fontFamily:'Montserrat',
  fontWeight:500,
  fontSize:14,
  color:'#424242'
}

export const inputStyles = {
  width: '250px',
  height: '28px',
  paddingLeft: 10,
  marginLeft:'-3rem'
}

export const typographyCommonStyles = {
  marginTop:'10px'
}
