import Button from '../../../../common/Button';
import BackupIcon from '@material-ui/icons/Backup';
import * as React from 'react';
import useSchemeBulkWidgets
  from '../../../../../api/hooks/useSchemeBulkWidgets';
import { useLocation } from 'react-router';
import { useTranslation } from 'components/providers/TranslationProvider';
import { setPolygonDataLoading } from 'store/appSlice';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import {
  buttonCommonStyle,
  typographyCommonStyle
} from './styles/uploadDownloadJson';

const nameFile = 'upload-JSON';
const UploadJSON = ({ id, values, randomId, layers, tabs, widgets, setErrors, setState }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { mutate, isLoading } = useSchemeBulkWidgets( setState, setErrors);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if ( !isLoading ) {
      dispatch(setPolygonDataLoading(false));
    }
  }, [ isLoading ]);

  const handleUpload = ({ target }) => {
    const fileReader = new FileReader();
    try {
      fileReader.readAsText(target.files[0]);

      fileReader.onload = async (e) => {
        await mutate({
          userId: id,
          scheme: {
            ...values,
            id: location.pathname.split('/')[4] === 'create' ? randomId : location.pathname.split('/')[4],
            layers,
            widgets,
            tabs,
            type: 'descriptive-spatial-analysis',
          },
          jsonFileContent: e.target.result,
        });
        dispatch(setPolygonDataLoading(true));
      };
    } catch (e) {
      setErrors( t('error_importing_file') );
    }
  };
  return (
    <>
      <input
        name={nameFile}
        accept='.json,application/json'
        id={`upload-JSON-${nameFile}`}
        style={{ display: 'none' }}
        type='file'
        disabled={layers.length < 1}
        onChange={handleUpload}
      />
      <label htmlFor={`upload-JSON-${nameFile}`}>
        <Button
          startIcon={<BackupIcon />}
          disabled={layers.length < 1}
          style={{
            ...buttonCommonStyle,
            marginRight: 16,
            marginTop: 11,
          }}
          variant='outlined'
          component='span'
        >
          <Typography style={typographyCommonStyle}>
            Upload JSON
          </Typography>
        </Button>
      </label>
    </>
  );
};

export default UploadJSON;
