import * as React from 'react';
import { IconButton } from '@material-ui/core';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const EditIcon = ({ onClick, className }) => (
  <IconButton aria-controls="edit-button" color='primary' className={className} onClick={onClick}>
    <EditOutlinedIcon />
  </IconButton>
);

export default EditIcon;
