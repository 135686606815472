import { styled, TextField } from '@mui/material';

export const MuiTextAreaField = styled(TextField)(({ theme }) => ({
  marginTop:'25px',
  marginLeft:'30px',
  width:'330px',
  height: '370px',
  '& .MuiInputBase-root': {
    borderRadius: '12px',
    height: '100%',
  },
  '& .MuiOutlinedInput-root': {
    height: '100%',
    '& textarea': {
      height: 'calc(100% - 32px)',
    },
    '& fieldset': {
      borderColor: '#C0DBE5',
    },
    '&:hover fieldset': {
      borderColor: '#C0DBE5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#C0DBE5',
    },
  },
}));

export const iconButtonStyle = {
  marginTop:'-20rem'
};

const styles = (theme) => ({
  xs6Container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 0, 1),
  },
  xs12Container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5, 0.5),
  },
  nameField: {
    padding: theme.spacing(0),
  },
  containerPaper: {
    width: '100%',
  },
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(0),
  },
  selectSkeleton: {
    height: 80,
    margin: theme.spacing(0, 0, 1),
  },
  title: {
    color: '#212121',
    fontFamily: 'Raleway',
    fontSize: 29,
    fontWeight: 700
  },
  subTitle: {
    color: '#616161',
    fontFamily: 'Raleway',
    fontSize: 21,
    fontWeight: 500,
    letterSpacing: 0.15
  },
  descriptionLabel: {
    color: '#616161',
    marginTop: '5px',
    fontFamily: 'Raleway',
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.15
  }
});
export default styles;
