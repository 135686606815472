import * as React from 'react';
import { Grid, Paper, ThemeProvider, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';

import themeIncluia from 'theme/incluia';

import ItemSortStats from './ItemSortStats';
import {
  MuiGridAddStat, MuiGridHeaders,
  MuiGridStatContainer,
  MuiGridStatSort
} from '../styles/stat';
import { Typography } from '@mui/material';
import { useTranslation } from '../../../../../../../providers/TranslationProvider';
import { MuiCheckCircleIconDisabled } from '../styles/list';

const Stat = ({ columns, setNewOrder, realColumns,formula, calculator,
                action, setIdForm, setSelectedValues, columnSelect }) => {
  const { t } = useTranslation();

  return (
    <MuiGridStatContainer container >
      {!calculator && (
        <MuiGridAddStat item xs={12} justifyContent='center'>
          <AddItem setNewOrder={setNewOrder} realColumns={realColumns} columns={columns} />
        </MuiGridAddStat>
      )}
      <MuiGridHeaders container item xs={12}>
          <Grid item xs={3}>
            <Typography variant='caption'
                        fontSize={12}
                        letterSpacing={0.4}
                        fontWeight={400}
                        fontFamily={'Montserrat'}
            >
              {t('column_definition')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='caption'
                        fontSize={12}
                        letterSpacing={0.4}
                        fontWeight={400}
                        fontFamily={'Montserrat'}
                        style={{marginLeft: '-2rem'}}
            >
              {t('name')}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant='caption'
                        fontSize={12}
                        letterSpacing={0.4}
                        fontWeight={400}
                        fontFamily={'Montserrat'}
                        style={{marginLeft: '-0.8rem'}}
            >
              {t('prefix')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='caption'
                        fontSize={12}
                        letterSpacing={0.4}
                        fontWeight={400}
                        fontFamily={'Montserrat'}
                        style={{marginLeft: '-0.2rem'}}
            >
              {t('operation')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='caption'
                        fontSize={12}
                        letterSpacing={0.4}
                        fontWeight={400}
                        fontFamily={'Montserrat'}
                        style={{marginLeft: '-0.8rem'}}
            >
              {t('format')}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant='caption'
                        fontSize={12}
                        letterSpacing={0.4}
                        fontWeight={400}
                        fontFamily={'Montserrat'}
                        style={{marginLeft: '-2.1rem'}}
            >
              {t('suffix')}
            </Typography>
          </Grid>
      </MuiGridHeaders>
      <MuiGridStatSort item xs={12} component={Paper}>
        <ItemSortStats
          items={columns}
          setItems={setNewOrder}
          formula={formula}
          setSelectedValues={setSelectedValues}
          action={action}
          setIdForm={setIdForm}
          calculator={calculator}
          columns={columnSelect}
        />
      </MuiGridStatSort>
    </MuiGridStatContainer>
  );
};

const AddItem = ({ setNewOrder, realColumns }) => {
  const [inputValue, setInputValue] = React.useState('');

  const handleChange = (event, newValue, reason, details) => {
    const fieldToAdd = details.option;

      setNewOrder(prevOrder => [
        ...prevOrder,
        {
          name: fieldToAdd.match(/^(formula\d+)_/) ? fieldToAdd.match(/^(formula\d+)_/)[1] : fieldToAdd,
          field: fieldToAdd,
          visible: true,
          id: Date.now().toString(36) + Math.random().toString(36),
        },
      ]);
  };

  return (
    <ThemeProvider theme={themeIncluia}>
      <Grid container>
        <Autocomplete
          id='organizations-id'
          multiple
          options={realColumns || []}
          disableClearable
          getOptionLabel={(option) => option}
          value={[]}
          onChange={(event, values, reason,details) => {
            handleChange(event, values, reason,details);
          }}
          inputValue={inputValue}
          onInputChange={(__, newInputValue) => {
            setInputValue(newInputValue);
          }}
          fullWidth
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                style={{width:'880px'}}
                label='Please select one or more columns to add'
                name='columns'
                variant='outlined'
                fullWidth
              />
              <MuiCheckCircleIconDisabled fontSize='medium' />
            </>
          )}
        />
      </Grid>
    </ThemeProvider>
  );
};

export default Stat;
