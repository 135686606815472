/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button as MuiButton,
  Container,
  Grid,
  makeStyles,
  LinearProgress,
  MenuItem,
  Divider,
} from '@material-ui/core';

import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import { useTranslation } from 'components/providers/TranslationProvider';
import Toast from 'components/common/Toast';
import useOrganizations from 'api/hooks/useOrganizations';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from '../styles/formModal';
import CustomSkeleton from '../../../common/CustomSkeleton';
import { SelectField } from '../../../common/ReactHooksFormFields';
import { useAuth } from '../../../providers/AuthProvider';
import { giveMeId } from '../../../../utils/supportComponents';
import useSchemes from '../../../../api/hooks/useSchemes';
import useCopyScheme from '../../../../api/hooks/useCopyScheme';
import { setPrivateLoading } from '../../../../store/appSlice';
import { Switch, Typography,styled } from '@mui/material';
import { useTheme } from '../../../providers/CustomThemeProvider';
import useUsers from '../../../../api/hooks/useUsers';

const schema = yup.object().shape({
  userToId: yup.string().required(),
  selectedSchemeId: yup.string().required(),
});

const useStyles = makeStyles(styles);

const CopyModal = ({ scenario, open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const orgId = location.pathname.split('/')[2];
  const { data: orgs, isLoading: isLoadingOrgs } = useOrganizations();
  const [error, setError] = React.useState('');
  const [copyTarget,setCopyTarget] = React.useState('organizations');
  const [openToast, setOpenToast] = React.useState(false);
  const { user } = useAuth();
  const id = giveMeId(location.pathname, user);
  const { data: schemes, isLoading: isLoadingSchemes } = useSchemes(id);
  const {data: users, isLoading: isLoadingUsers} = useUsers(orgId);
  const {theme} = useTheme()
  const [targetCantidates, setTargetCantidates] = React.useState(users);

  const MuiSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme, colorSecondary }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: colorSecondary,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: theme.palette.primary.main,
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  const commonStyle = {
    fontFamily:'Montserrat',
    fontWeight:400,
    textTransform:'uppercase',
    fontSize:'12px'
  }
  const getDefaultValues = () => {
    return scenario
      ? {
          userToId: scenario.userToId,
          selectedSchemeId: scenario.selectedSchemeId,
        }
      : {
          userToId: '',
          selectedSchemeId: '',
        };
  };

  const {
    mutateAsync: copyScheme,
    isError: copyIsError,
    isSuccess: copyIsSuccess,
    error: copyError,
    isLoading: copyIsLoading,
  } = useCopyScheme();

  React.useEffect(() => {
    if (copyIsError) {
      dispatch(setPrivateLoading(false));
      setError(copyError.data?.error ? t(copyError.data.error) : JSON.stringify(copyError));
      setOpenToast(true);
    }
  }, [copyIsError, copyError, t]);

  React.useEffect(() => {
    if (copyIsSuccess) {
      onClose(copyIsSuccess);
    }
  }, [copyIsSuccess]);

  React.useEffect(() => {
    if(copyTarget==='organizations'){
      setTargetCantidates(orgs);
    }else {
      setTargetCantidates(users);
    }
  },[copyTarget,isLoadingOrgs,isLoadingUsers]);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const copyScenario = (data) => {
    const scenarioToCopy = {
      userFromId: id,
      userToId: data.userToId,
      selectedSchemeId: data.selectedSchemeId,
    };

    copyScheme(scenarioToCopy);
  };

  const copyForm = (
    <Container display='flex' className={classes.container}>
      <Grid item xs={12} className={classes.inputText}>
        {isLoadingOrgs ? (
          <CustomSkeleton className={classes.selectSkeleton} />
        ) : (
          <Grid container>
            <Grid container  className={classes.gridMargin} alignContent='center' item xs={12}>
              <Typography  variant='caption'>{t('copy_target')}</Typography>
            </Grid>
            <Grid container className={classes.gridBottom} alignContent='left' item xs={3}>
              <MuiSwitch colorSecondary={theme.palette.secondary.main}
                         checked={copyTarget === 'users'}
                         onChange={() => setCopyTarget(prevState => prevState==='users'?'organizations':'users')}
              />
            </Grid>
            <Grid container alignContent={'left'} item xs={9} paddingTop={1}>
              <Typography sx={{
                ...commonStyle,
                color: copyTarget === 'organizations' ? theme.palette.secondary.main : 'black'}}>
                {t('orgs')}
              </Typography>
              <span style={{...commonStyle, marginLeft:'3px'}}>/</span>
              <Typography sx={{
                ...commonStyle,
                marginLeft:'3px',
                color: copyTarget === 'users' ? theme.palette.secondary.main : 'black'}}>
                {t('users')}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.inputText}>
              {isLoadingSchemes ? (
                <CustomSkeleton className={classes.selectSkeleton} />
              ) : (
                <SelectField name='selectedSchemeId' variant='outlined' label={t('select_scenario')} fullWidth>
                  <Divider light value=' ' />
                  {schemes?.schemes.map((o) => {
                    return (
                      <MenuItem key={o.id} value={o.id}>
                        {o.name}
                      </MenuItem>
                    );
                  })}
                </SelectField>
              )}
            </Grid>
            <SelectField name='userToId' variant='outlined' label={t('copy_to')} fullWidth>
              <Divider light value=' ' />
              {targetCantidates?.map((u) => {
                return (
                  <MenuItem key={u._id} value={u._id}>
                    <Typography  variant='body1' fontFamily='Montserrat' fontSize={16}  letterSpacing={0.5}>
                      {copyTarget==='users'?u.email:u.name}
                    </Typography>
                  </MenuItem>
                );
              })}
            </SelectField>
          </Grid>
        )}
      </Grid>
    </Container>
  );

  const handleCloseToast = () => setOpenToast(false);

  const onSubmit = handleSubmit((data) => copyScenario(data));

  const actions = (
    <>
      {copyIsLoading ? <LinearProgress className={classes.loading} /> : null}
      <MuiButton onClick={onClose} color='primary' variant='text' className={classes.btnClose}>
        {t('cancel_btn')}
      </MuiButton>
      <Button
        variant='outlined'
        className={classes.buttonAction}
        onClick={onSubmit}
        loading={copyIsLoading}
        disabled={isLoadingSchemes || isLoadingOrgs || !isValid || copyIsLoading}
      >
        {!isLoadingSchemes || !isLoadingOrgs ?
          <Typography  fontFamily='Montserrat' fontSize={14}  letterSpacing={0.25}>
            {t('copy')}
          </Typography>
          : <Typography  fontFamily='Montserrat' fontSize={14}  letterSpacing={0.25}>
            {t('processing')}
          </Typography>}
      </Button>
    </>
  );

  return (
    <FormProvider {...methods}>
      <Toast
        open={openToast}
        handleClose={handleCloseToast}
        severity='error'
        vertical='top'
        horizontal='center'
        message={error}
      />
      <form>
        <Modal open={open} onClose={onClose} title={t('copy_schemes')} actions={actions}>
          {copyForm}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default CopyModal;
