export const labelSelectStyle = {
  color: '#424242',
  marginTop:4
}

export const labelTextStyle = {
  color: '#424242',
}

export const textFieldStyle = {
  width: '250px',
  height: '56px',
}

export const colorFieldStyle = {
  width: 30,
  height: 25
}

export const divMenuItemStyle = {
  borderRadius: '16px',
  width: '24px',
  height: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 4,
}

export const selectStyle = {
  height: '56px',
  marginTop: '8px',
  width: '90px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#757575',
  },
  '& .MuiListItem-root': {
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}
