import * as React from 'react';
import { Grid, Button, makeStyles, Typography } from '@material-ui/core';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import { useTranslation } from 'components/providers/TranslationProvider';
import PublicIcon from '@mui/icons-material/Public';
import {
  iconCommonStyles,
  MuiActionTypography,
  MuiSaveButton,
  saveAndViewButtonStyles
} from './styles/actions';
import Loader from "../../common/Loader";

const useStyles = makeStyles((theme) => ({
  btnActionSave: {
    marginRight: theme.spacing(0),
  },
  btnActionsSaveAndView: {
    marginLeft: theme.spacing(4),
  },
  containerBtnSaves: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop:'10px'
  },
}));

const Actions = ({ isLoading, handleSave, handleSaveAndView, isValid, isDirty }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12} className={classes.containerBtnSaves}>
      <Loader
        loading={isLoading}
        loaderType={'infinite'}
        notificationMessage={t('saving_map_info')}
        titleMessage={t('saving_map')}
      />
      <MuiSaveButton
        onClick={handleSave}
        variant='contained'
        startIcon={isLoading ? <MuiCircularProgress size={20} /> : <SaveIcon style={iconCommonStyles} />}
        disabled={!isValid || isLoading || !isDirty}
        data-cy = {'button_save_map'}
      >
        <MuiActionTypography> {t('save_changes')} </MuiActionTypography>
      </MuiSaveButton>
      <Button
        onClick={handleSaveAndView}
        style={{
          ...saveAndViewButtonStyles,
          width: !isDirty ? '143px' : '185px',
        }}
        variant='contained'
        color='primary'
        startIcon={isLoading ? <MuiCircularProgress size={20} /> : <PublicIcon style={iconCommonStyles} />}
        className={classes.btnActionsSaveAndView}
        disabled={!isValid || isLoading}
      >
        {isDirty ? (<MuiActionTypography> {t('save_and_view')} </MuiActionTypography>) :
                   (<MuiActionTypography> {t('view_map')} </MuiActionTypography>)}
      </Button>
    </Grid>
  );
};

export default Actions;
