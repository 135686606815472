import { styled } from '@mui/material';
import { Grid } from '@material-ui/core';

export const MuiGridStatContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'flex-start',
  alignContent: 'flex-start',
  height: 264,
}));

export const MuiGridStatSort = styled(Grid)(({ theme }) => ({
  backgroundColor:'#FAFAFA',
  borderRadius:'12px',
  marginRight:10,
  padding:'10px, 10px, 10px 0px',
  height:'auto',
  width:'930px',
  maxHeight:'105px',
  overflowY: 'auto'
}));

export const MuiGridAddStat = styled(Grid)(({ theme }) => ({
  marginBottom: 12,
  marginRight:15
}));

export const MuiGridHeaders = styled(Grid)(({ theme }) => ({
  marginTop: '10px',
  marginBottom:'10px'
}));
