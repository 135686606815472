import React, {useState} from "react";
import {useTranslation} from "../../../providers/TranslationProvider";
import Popover from "@mui/material/Popover";
import {
  Box, Button,
  FormControl,
  InputLabel,
  Select,
  Slider,
  Typography
} from "@mui/material";
import {btnCancel} from "./DeleteIsochroneModal";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {AccessTime, PinDrop, SquareFoot} from "@material-ui/icons";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import DrawIcon from "@mui/icons-material/Draw";
import {SystemUpdateAlt} from "@mui/icons-material";
import {lighten} from "@mui/material/styles";
import {makeStyles} from "@material-ui/core";
import {
  SliderFieldCustom,
  SliderFieldCustomTwo
} from "../../ReactHooksFormFields";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

const useStyles = makeStyles((theme)=>({
  root_toogle: {
    color: `${theme.palette.primary.main} !important`,
    borderColor: `${theme.palette.primary.main} !important`,
    '&:hover': {
      backgroundColor: `${lighten(theme.palette.primary.main, 0.9)} `,
    }
  },
  root_switch: {
    color: `${theme.palette.primary.main} !important`,
    // '&:hover': {
    //   boxShadow: `0 0 0 8px ${theme.palette.primary.main} !important`,
    // }
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "white !important",
  },
  grouped: {
    border: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      // boxShadow: `0 0 0 8px ${lighten( theme.palette.primary.main , 0.9)} !important`,
      backgroundColor: `${lighten( theme.palette.primary.main , 0.9)}`,
    }
  },
  swt: {
    width: '312px',
    height: '32px',
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: `${lighten( theme.palette.primary.main , 0.9)}`,
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center'
  }
}));

const FilterPopoverPolygon =  ({open, min , max ,onClose, handleFilter, anchorEl}) => {
  const { t } = useTranslation();
  const [polygonType, setPolygonType] = useState(['isochrone','drawn','from_layer']);
  const classes = useStyles()

  const handleClose = () => {
    onClose(false);
  };
  const handlePolygonChange = (event, newSelection) => {
    setPolygonType(newSelection);
  };
  const formMethods = useForm();
  const submitFilter = () => {
    const formData = formMethods.getValues();
    handleFilter({ polygonType, areaRange: formData.area });
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      sx={stylePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <FormProvider {...formMethods}>
      <Box sx={{ml: '16px' , mt: '16px'}}>
        <Typography variant="h6">
          {t('filter_by')}
        </Typography>
        <Box sx={{display: 'flex', alignItems: 'center', gap: 2, mb: '18px' }}>
          <Typography variant='caption' sx={{fontFamily: 'Montserrat'}}>{t('polygon_type')}</Typography>
          <Box sx={swt}>
              <ToggleButtonGroup
                value={polygonType}
                onChange={handlePolygonChange}
                classes={{grouped: classes.grouped}}
              >
                <ToggleButton value="isochrone" style={{width: '32px', height: '24px', borderRadius: '12px'}}
                              classes={{ root: classes.root_switch, selected: classes.selected }}
                >
                  <PinDrop style={{width: '16px' , height: '16px'}}/></ToggleButton>
                <ToggleButton value="drawn"
                              style={{width: '32px', height: '24px', borderRadius: '12px' }}
                              classes={{ root: classes.switch, selected: classes.selected }}
                >
                  <DrawIcon style={{width: '16px', height: '16px', borderRadius: '12px'}}/></ToggleButton>
                <ToggleButton value="from_layer"
                              style={{width: '32px', height: '24px', borderRadius: '12px'}}
                              classes={{ root: classes.switch, selected: classes.selected }}
                >
                  <SystemUpdateAlt style={{width: '16px', height: '16px', borderRadius: '12px'}}/></ToggleButton>
              </ToggleButtonGroup>
          </Box>
        </Box>
          <Box>
            <Typography variant='caption' sx={{fontFamily: 'Montserrat'}} >
              {t('area')}
            </Typography>
              <SliderFieldCustomTwo
                name='area'
                defaultValue={[min , max]}
                step={1}
                min={min}
                max={max}
                valueLabelDisplay='auto'
              />
          </Box>
      </Box>
      <Box sx={{mt: '20px', mb: '12px', mr: '16px', display: 'flex', justifyContent: 'flex-end' }}>
        <Button sx={btnCancel} onClick={handleClose}> {t('cancel')}</Button>
        <Button sx={btnCancel} variant="outlined" color="primary" onClick={submitFilter}>{t('filter')}</Button>
      </Box>
      </FormProvider>
    </Popover>
  )

}
const stylePopover = {
  '& .MuiPopover-paper': {
    width: '298px',
    height: '233px',
    borderRadius: '12px'
  }
}
const swt = {
  width: '104px',
  height: '32px',
  border: `1px solid #0096A7`,
  backgroundColor: `${lighten( '#00BBD4' , 0.9)}`,
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}
export default FilterPopoverPolygon;
