import {
  Button as MuiButton,
  Grid,
  ThemeProvider,
  Switch,
  makeStyles
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import {
  AccessTime,
  DirectionsBike,
  DirectionsCar,
  DirectionsWalk, SquareFoot
} from '@material-ui/icons';
import {SliderField, SliderFieldCustom} from '../../ReactHooksFormFields';
import React from 'react';
import Button from '@material-ui/core/Button';
import Modal from '../../Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTheme } from '../../../providers/CustomThemeProvider';
import {useSelector} from 'react-redux';
import {useTranslation} from 'components/providers/TranslationProvider';
import {Box, IconButton} from "@mui/material";
import {Landscape, LocationCity, Square} from "@mui/icons-material";
import {lighten} from "@mui/material/styles";
const routingProfileOptions = ['walking', 'cycling' , 'driving' ];

const useStyles = makeStyles((theme)=>({
  root_toogle: {
    color: `${theme.palette.primary.main} !important`,
    borderColor: `${theme.palette.primary.main} !important`,
    fontFamily: 'Montserrat',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: `${lighten(theme.palette.primary.main, 0.9)} `,
    }
  },
  root_switch: {
    color: `${theme.palette.primary.main} !important`,
    fontFamily: 'Montserrat',
    fontSize: '12px',
    '&:hover': {
      boxShadow: `0 0 0 8px ${theme.palette.primary.main} !important`,
    }
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "white !important",
  },
  grouped: {
    border: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      boxShadow: `0 0 0 8px ${lighten( theme.palette.primary.main , 0.9)} !important`,
      backgroundColor: `${lighten( theme.palette.primary.main , 0.9)}`,
    }
  },
  swt: {
    width: '312px',
    height: '32px',
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: `${lighten( theme.palette.primary.main , 0.9)}`,
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center'
  }
}));
function IsochroneModal(props) {

  const {open, isochrone, action, handleClose, saveOnSubmitIsochrone, id } = props;
  const {t} = useTranslation()
  const { theme } = useTheme();
  const [routingProfile, setRoutingProfile] =
    React.useState( action === 'update'? isochrone.mode :  routingProfileOptions[1]);
  const [region, setRegion] = React.useState('urban');
  const [type, setType] = React.useState(action === 'update'? isochrone.isochroneType : 'distance');
  const schema = yup.object().shape({
    time: yup.number(),
    distanceKm: yup.number(),
    isochroneType: yup.string().default('distance'),
    isRural: yup.boolean()
  });
  const widgetSelect = useSelector((state) => state.app.selectWidgetId);
  const classes = useStyles();


  const getDefaultValues = () => {

    return action === 'update'
      ? {
        time: isochrone.timeMin,
        routing: isochrone.mode,
        distanceKm: isochrone.distanceKm * 1000,
        isochroneType: isochrone.isochroneType,
        isRural: isochrone.generalize ,

      } : {
        time: 10,
        routing: routingProfileOptions[1],
        distanceKm: 500,
        isochroneType: 'distance',
        isRural: 'false',
        circuchroneTable: ''
      }
  }

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: {  errors, isValid, isDirty },
  } = methods;

  const handleChangeRoutingProfile = (event, routingP) => {
    setRoutingProfile(routingP);
  };


  const onSubmit = handleSubmit(async (data) =>
    action === 'update' ?
      await saveOnSubmitIsochrone({...data, routingProfile, isochrone, action}):
    await saveOnSubmitIsochrone({...data, routingProfile, action})
  );

  const handleType = (event, newType) => {
    if (newType !== null) {
      setType(newType);
      methods.setValue('isochroneType', newType)
    }
  };

  const handleRegion = (event, newRegion) => {
    if (newRegion !== null) {
      setRegion(newRegion);
      methods.setValue('isRural', newRegion === 'rural')
    }
  };

  const formIsochrone = (
    <Grid container style={{width: '312px', height: '100%'}}>
      <Grid container justifyContent='center' direction={'row'}>
        <Grid container>
          <Typography variant='body1' style={{color: '#325D6C'}}>{t('metric_type')}</Typography>
          <Typography variant='caption' style={{color: '#325D6C'}}>{t('metric_type_info')}</Typography>
        </Grid>
        <Grid style={{marginTop: 16}}>
          <Box className={classes.swt}>
            <ToggleButtonGroup
              value={type}
              exclusive
              onChange={handleType}
              style={{padding: '2px'}}
              classes={{grouped: classes.grouped}}
            >
              <ToggleButton value="distance" style={{width: '150px', height: '26px', borderRadius: '12px', marginRight: '5px'}}
                            classes={{ root: classes.root_switch, selected: classes.selected }}>
                <SquareFoot style={{width: '16px' , height: '16px'}}/>{t('distance')}</ToggleButton>
              <ToggleButton value="time"
                            style={{width: '150px', height: '26px', borderRadius: '12px'}}
                            classes={{ root: classes.switch, selected: classes.selected }}>
                <AccessTime style={buttonSwitch}/>{t('time')}</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
        <Grid container justifyContent='flex-start' direction={'row'}>
          {type === 'time' && (
            <Grid item>
              <div style={{width: 300, marginTop: '20px'}}>
                <SliderFieldCustom
                  name='time'
                  defaultValue={10}
                  step={5}
                  min={5}
                  customMax={720}
                  valueLabelDisplay='auto'
                  custom
                />
              </div>
            </Grid>
          )}
          {type === 'distance' && (
          <Grid item>
            <div style={{width: 300 , marginTop: '20px'}}>
              <SliderFieldCustom
                name='distanceKm'
                defaultValue={200}
                step={1}
                min={200}
                customMax={20000}
                custom
                valueLabelDisplay='auto'
              />
            </div>
          </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <hr style={{ border: '0.5px solid #E0E0E0' , marginTop: 16, marginBottom: 16}}/>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant='body1' style={{color: '#325D6C'}}>{t('routing_profile')}</Typography>
            <Typography variant='caption' style={{color: '#325D6C'}}>{t('routing_profile_info')}</Typography>
          </Grid>
          <Grid style={{marginTop: 16 , width: '100%'}}>
          <ToggleButtonGroup
              name='routing'
              value={routingProfile}
              exclusive
              onChange={handleChangeRoutingProfile}
              aria-label="Platform"
              sx={toogleGroup}
            >
              <ToggleButton
                value={routingProfileOptions[0]}
                classes={{ root: classes.root_toogle, selected: classes.selected }} style={toogle3}>
                <DirectionsWalk/>
              </ToggleButton>
              <ToggleButton
                value={routingProfileOptions[1]}
                classes={{ root: classes.root_toogle, selected: classes.selected }} style={toogle3}>
                <DirectionsBike />
              </ToggleButton>
              <ToggleButton
                value={routingProfileOptions[2]}
                classes={{ root: classes.root_toogle, selected: classes.selected }} style={toogle3}>
                <DirectionsCar />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr style={{ border: '0.5px solid #E0E0E0' , marginTop: 16, marginBottom: 16}}/>
        </Grid>
          <Grid container justifyContent='flex-start' direction={'row'} style={{marginBottom: '56px'}}>
            <Grid item>
              <Typography variant='body1' style={{color: '#325D6C'}}>{t('is_rural')}</Typography>
              <Typography variant='caption' style={{color: '#325D6C'}}>{t('is_rural_info')}</Typography>
            </Grid>
            <Grid item style={{marginTop: 16}}>
              <Box className={classes.swt}>
                <ToggleButtonGroup
                  value={region}
                  exclusive
                  onChange={handleRegion}
                  style={{padding: '2px', border: 0}}
                  classes={{grouped: classes.grouped}}
                >
                  <ToggleButton value="urban" style={{width: '150px', height: '26px', borderRadius: '12px', marginRight: '5px'}}
                                classes={{ root: classes.root, selected: classes.selected }}>
                    <LocationCity style={buttonSwitch} />{t('urban')}</ToggleButton>
                  <ToggleButton value="rural"
                                style={{width: '150px', height: '26px', borderRadius: '12px'}}
                                classes={{ root: classes.root, selected: classes.selected }}>
                    <Landscape style={buttonSwitch}/>{t('rural')}</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid>
          </Grid>
      </Grid>
    </Grid>
  );

  const actions = (
    <>
      <MuiButton
        color='primary'
        variant='text'
        onClick={handleClose}>
        {'cancel'}
      </MuiButton>
      <Button
        color='primary'
        loading={false}
        disabled={action === 'update' ?  (!isDirty && routingProfile === isochrone.mode) : !isValid }
        style={{marginRight: 15, borderRadius: '20px', border: '1px solid'}}
        onClick={onSubmit}
      >
        {action}
      </Button>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          onClose={handleClose}
          title={t('add_isochrone')}
          actions={actions}
          widthInPixels={360}
          disableEscapeKeyDown={true}
        >
          <ThemeProvider theme={theme}>{formIsochrone}</ThemeProvider>
        </Modal>
      </form>
    </FormProvider>
  )
}

const toogleGroup = {
  width: '312px',
  height: '62px'
}
const toogle3 = {
  width: '104px',
  height: '62px',
}
const buttonSwitch ={
  width: '16px' ,
  height: '16px'
}

export default IsochroneModal;
