const styles = (theme) => ({
  navBar: {
    boxShadow: 'none',
    overflow: 'hidden',
    marginBottom: 35,
    maxWidth:'1350px !important'
  },
  toolBar: {
    paddingLeft: '35vh',
    backgroundColor: 'white',
  },
  tab: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary,
  },
  tabs: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    '& .Mui-selected': {
      fontSize: 15,
      fontWeight: 700,
      color: theme.palette.primary.main,
      transition: 'font-size 10ms, font-weight 10ms',
    },
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
  img: {
    width: 240,
    height: 300,
    objectFit: 'scale-down',
  },
  imageContainer: {
    textAlign: 'center',
  },
  container: {
    paddingTop: 20,
  },
  scenarioTypeContainer: {
    margin: '8px 80px',
  },
  containerPaper: {
    padding: theme.spacing(3),
    paddingTop: '30px',
    margin: 0,
    width: '100%',
    marginLeft: 20
  },
});
export default styles;
