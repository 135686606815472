import {
  makeStyles,
  Typography,
  Box,
  Tooltip,
} from '@material-ui/core';
import { 
    GLOBAL, 
    widgetType as widgetTypeConstant
} from 'utils/constants';

import {
    BarChart as BarChartIcon,
    Category as PolygonIcon,
    Tune as TuneIcon,
    ThumbUp as ThumbUpIcon
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    circularBorder: {
        borderRadius: '50%',
        display: "flex",
        width: 16,
        height: 16,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 8,
      },
      widget: {
        width: 16,
        height: 16,
        color: GLOBAL.COLOR.WHITE,
        padding: 2,
      },
      categoryWidget: {
        width: 16,
        height: 16,
        color: GLOBAL.COLOR.WHITE,
        transform: 'rotate(90deg)',
        padding: 2,
      },
      container: {
        minHeight: 24,
        height: '100%',
        background: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[200],
        padding: '4px 6px 4px 6px',
        borderRadius: 15,
      },
      information: {
        color: '#424242',
        lineHeight: 1.2,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '290px',
        cursor: 'default',
      }
}));

function AppliedFilterDisplay( props ) {
    const classes = useStyles();
    const { information, iconBgColor="orange", widgetType, additionalClasses = {} } = props;

    const renderIconByWidgetType = ( widgetType = widgetTypeConstant.HISTOGRAM ) => {
        if ( widgetType === widgetTypeConstant.CATEGORY ) {
            return <BarChartIcon className={classes.categoryWidget} />;
        }
        if ( widgetType === widgetTypeConstant.POLYGON ) {
            return <PolygonIcon className={classes.widget} />;
        }
        if ( widgetType === widgetTypeConstant.PARAMETERS ) {
            return <TuneIcon className={classes.widget} />;
        }
        if ( widgetType === widgetTypeConstant.RECOMMENDATIONS ) {
            return <ThumbUpIcon className={classes.widget} />;
        }
        return <BarChartIcon className={classes.widget} />;
    }

    return (
        <Box 
            display="inline-flex"
            className={ `${classes.container} ${additionalClasses.container || ''}` }
            alignItems="center"
        >
            <Box style={{ backgroundColor: iconBgColor }} className={classes.circularBorder}>
                { renderIconByWidgetType( widgetType ) }
            </Box>
            <Tooltip title={information}>
                <Typography variant='overline' className={classes.information}>
                    { information }
                </Typography>
            </Tooltip>
        </Box>
    );
}

export default AppliedFilterDisplay;