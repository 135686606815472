import * as React from 'react';
import {
  Grid,
  makeStyles,
  Card as MaterialCard,
  CardActionArea,
  Typography,
} from '@material-ui/core';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import ListIcon from '@mui/icons-material/List';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CategoryIcon from '@mui/icons-material/Category';
import ParametersIcon from "@mui/icons-material/Tune"
import { useFormContext, Controller } from 'react-hook-form';
import { hexToRgb } from 'utils/colorConverter';
import {useSelector} from "react-redux";

const commonIconsWidgetStyle = {
  width: 55,
  height: 50,
  color: '#616161',
  fontSize: 40,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 180,
    height: 180,
    cursor: ({ disabled }) => (disabled ? 'auto' : 'pointer'),
    borderStyle: 'solid',
    borderRadius:12,
    borderWidth: ({ active, disabled }) => (active && !disabled ? '2px' : '0px'),
    borderColor: ({ active, disabled }) => {
      if (active) return theme.palette.primary.dark;
      if (disabled) return theme.palette.grey[300];
      return theme.palette.grey[300];
    },
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    margin: theme.spacing(1),
  },
  cardContent: {
    cursor: ({ disabled }) => (disabled ? 'auto' : 'pointer'),
    background: ({ active }) => (active ?
      `rgba(${hexToRgb(localStorage.getItem('colorPrimary')).join(', ')},0.2)` : '#F5F5F5'),
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    objectFit: 'scale-down',
    height: '5vw',
    borderWidth: 10,
    borderColor: ({ active }) => (active ? theme.palette.primary.dark : theme.palette.grey[300]),
  },
  title: {
    marginTop: 10,
    fontSize: 16,
    fontWeight:700,
    fontFamily:'Raleway',
  },
  container: {
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  icons: {
    width: 55,
    height: 50,
    color: '#616161',
    fontSize: 40,
  },
}));

const Card = ({ imgSrc, title, active, onClick, disabled }) => {
  const classes = useStyles({ active, disabled });
  return (
    <MaterialCard elevation={0} className={classes.root} style={{ background: disabled ? 'lightgray' : 'initial'}}>
      <CardActionArea className={classes.cardContent} onClick={onClick}>
        <Grid
          container
          direction='column'
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {imgSrc}
          <Typography align='center' className={classes.title}>
            {title}
          </Typography>
        </Grid>
      </CardActionArea>
    </MaterialCard>
  );
};

const SelectWidgetType = React.forwardRef(({ name, allDisabled}, ref) => {
  const { control } = useFormContext();
  const widgets = useSelector((state) => state.app.widgets);
  const selectInputValues = [
    {
      value: 'recommendations',
      label: 'Recommendations',
      disabled: allDisabled,
      src: <ThumbUpAltIcon color='primary' sx={commonIconsWidgetStyle} />,
    },
    {
      value: 'histogram',
      label: 'Histogram',
      disabled: allDisabled,
      src: <BarChartOutlinedIcon color='primary' sx={commonIconsWidgetStyle} />,
    },
    {
      value: 'category',
      label: 'Category',
      disabled: allDisabled,
      src: <BarChartOutlinedIcon color='primary'
                                 sx={{...commonIconsWidgetStyle, transform: 'rotate(90deg)'}} />,
    },
    {
      value: 'list',
      label: 'List',
      disabled: allDisabled,
      src: <ListIcon color='primary' sx={commonIconsWidgetStyle} />,
    },
    {
      value: 'statistics',
      label: 'Statistics',
      disabled: allDisabled,
      src: <ViewStreamIcon color='primary' sx={commonIconsWidgetStyle} />,
    },
    {
      value: 'polygon',
      label: 'Polygon',
      disabled: allDisabled,
      src: <CategoryIcon color='primary' sx={commonIconsWidgetStyle} />,
    },
    {
      value:'parameters',
      label:'Simulation',
      disabled: allDisabled,
      src: <ParametersIcon color='primary' sx={commonIconsWidgetStyle} />,
    }
  ];
  const getDisableAvailability = (i) =>{
    if (i.value === 'parameters'){
      return widgets.some(widget => widget.type === 'parameters');
    }
    return i.disabled
  }

  return (
    <>
      <Controller
        control={control}
        ref={ref}
        name={name}
        render={({ field: { value, onChange } }) => (
          <Grid container spacing={3}>
            {selectInputValues.map((i) => (
              <Card
                key={i.value}
                active={value === i.value}
                onClick={getDisableAvailability(i) ? undefined : () => onChange(i.value)}
                title={i.label}
                imgSrc={i.src}
                disabled={getDisableAvailability(i)}
              />
            ))}
          </Grid>
        )}
      />
    </>
  );
});

export default SelectWidgetType;
