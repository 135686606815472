import {useState, useMemo} from 'react';
import {
  makeStyles,
  Typography,
  IconButton,
  Box,
  Button,
  MenuItem,
  Select,
  FormControl,
} from '@material-ui/core';
import Popover from '@mui/material/Popover';
import {GLOBAL} from 'utils/constants';

import {
    Tune as TuneIcon,
  } from '@material-ui/icons';

import { useTranslation } from 'components/providers/TranslationProvider';
import CustomToggleButton from 'components/common/CustomToggleButton';
import SortByAlphaIconInverted from 'components/common/icons/SortByAlphaIconInverted'
import {
  ThumbUp as ThumbUpIcon,
  PinDrop as PinDropIcon,
  SortByAlpha as SortByAlphaIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from '@material-ui/icons';

import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { generateDynamicShades } from 'utils/colors'

const useStyles = makeStyles((theme) => ({
    btnHover: {
        width: 32,
        height: 32
    },
    tuneIcon: {
        color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800]
    },
    mainContainer: {
        width: 298,
        height: 242,
        backgroundColor: 'white',
        padding: 16
    },
    header: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '17.07px',
        marginBottom: 12,
    },
    identifier: {
        marginTop: 12,
        marginBottom: 12,
    },
    labelCommons: {
        color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[900],
    },
    commonButton: {
        width: 108,
        height: 37,
        borderRadius: 20,
        padding: '10px 16px',
        fontSize: '14px',
    },
    buttonsContainer: {
        marginTop: 24,
    },
    popover: {
        '& .MuiPaper-root': {
            borderRadius: 12,
        }
    },
    filterBox: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    columnsSelect: {
      '&.MuiInputBase-root': {
        width: 154,
        height: 32,
        marginRight: '8px',
      }
    },
    columnsSelectCommon: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 400,
      fontSize: 12,
    },
    btnIcon: {
      width: '16px !important', 
      height: '16px !important',
    },
    toggleButtonLarge: {
      borderRadius: '12px !important',
      width: 48,
      height: 24,
      margin: 'auto',
      padding: '4px 8px 4px 8px',
      border: 0,
    },
    selected: {
      backgroundColor: `${generateDynamicShades( theme.palette.primary.main )[500]} !important`,
      color: `${GLOBAL.COLOR.WHITE} !important`,
    },
    customToggleButtonClassName: {
      width: '104px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    customToggleOfferTypeClassName: {
      paddingLeft: '4px',
      paddingRight: '4px',
    },
    offerTypesItemsClass: {
      width: '31.71px',
      height: '24px',
    },
}));

function FilterRecommendationsPopover( props ) {
  const classes = useStyles();
const [anchorEl, setAnchorEl] = useState(null);
const { t } = useTranslation();

const { 
  offerTypes, 
  setOfferTypes, 
  idSortedType, 
  setIdSortedType,
  selectedColumnToOrder,
  setSelectedColumnToOrder,
  columnsToOrder,
  columnSortedType,
  setColumnSortedType,
  runFilterHandler,
} = props;

const orderByIdItemDescColor = useMemo(
  () => {
    return idSortedType === 'DESC' ? 'white' : '#2c303261';
  },
  [idSortedType]
);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOfferTypesValues = (event, selectedValues) => {
    setOfferTypes( selectedValues );
  };

  const handleOrderByIdValues = (event, selectedValues) => {
    setIdSortedType( selectedValues );
  };

  const handleOrderBySelectedColumn = ( event ) => {
    setSelectedColumnToOrder( event.target.value );
  }

  const handleOrderSelectedColumnValues = (event, selectedValues) => {
    setColumnSortedType( selectedValues );
  };

  const defaultActionsAfterRunFilterHandler = () => {
    runFilterHandler();
    handleClose();
  }

  const offerTypesItems = [
    {
      value: 'recommendation',
      icon: <ThumbUpIcon className={classes.btnIcon}/>,
      classes: {
        root: classes.offerTypesItemsClass
      }
    },
    {
      value: 'current_offer',
      icon: <PinDropIcon className={classes.btnIcon}/>,
      classes: {
        root: classes.offerTypesItemsClass
      }
    },
    {
      value: 'recommended_expansion',
      icon: <OpenInFullIcon className={classes.btnIcon}/>,
      classes: {
        root: classes.offerTypesItemsClass
      }
    }
  ];

  const orderByIdItems = [
    {
      value: 'ASC',
      icon: <SortByAlphaIcon className={classes.btnIcon}/>,
      classes: {
        root: classes.toggleButtonLarge,
        selected: classes.selected,
      }
    },
    {
      value: 'DESC',
      icon: <SortByAlphaIconInverted color={orderByIdItemDescColor}/>,
      classes: {
        root: classes.toggleButtonLarge,
        selected: classes.selected,
      }
    },
  ];

  const orderBySelectedColumn = [
    {
      value: 'ASC',
      icon: <ArrowUpwardIcon className={classes.btnIcon} />,
      classes: {
        root: classes.toggleButtonLarge,
        selected: classes.selected,
      }
    },
    {
      value: 'DESC',
      icon: <ArrowDownwardIcon className={classes.btnIcon} />,
      classes: {
        root: classes.toggleButtonLarge,
        selected: classes.selected,
      }
    },
  ];

  const open = Boolean(anchorEl);
  const id = open ? 'filter-recommendations-popover' : undefined;

  return (
    <>
        <IconButton    
            onClick={handleClick}
            className={`${classes.btnHover} ${classes.tuneIcon}`}>
        <TuneIcon />
        </IconButton>
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.popover}
      >
        <Box className={classes.mainContainer}>
            <Box display='flex' flexDirection='column'>
                <Typography className={classes.header}>{ t('filter_by') }</Typography>
            <Box  className={classes.filterBox}>
              <Typography variant='caption' className={classes.labelCommons}>{ t('type_of_service') }</Typography>
              <CustomToggleButton
                toggleItems={offerTypesItems}
                handleValues = {handleOfferTypesValues}
                values={offerTypes}
                customToggleButtonClassName = { `${classes.customToggleButtonClassName} ${classes.customToggleOfferTypeClassName}` }
              />
              
            </Box>
            <Box display='flex' justifyContent='space-between' className={classes.identifier}>
              <Typography variant='caption' className={classes.labelCommons}>{t('identifier')}</Typography>
              <CustomToggleButton
                  toggleItems={orderByIdItems}
                  handleValues = {handleOrderByIdValues}
                  values={idSortedType}
                  exclusive
                  customToggleButtonClassName = { classes.customToggleButtonClassName }
                  />
            </Box>
            <Box display='flex' justifyContent='space-between'>
                <FormControl variant="outlined" >
                  <Select
                    id="recommendations-popover-coloumns"
                    value={selectedColumnToOrder}
                    onChange={handleOrderBySelectedColumn}
                    defaultValue='quant_var_covered'
                    className={`${classes.columnsSelect} ${classes.columnsSelectCommon}`}
                  >
                    {
                      columnsToOrder.map( (col) => (
                        <MenuItem className={`${classes.columnsSelectCommon}`} value={col.value}>{ col.label }</MenuItem>
                      ))
                    }
                  </Select>
                  </FormControl>
                  <CustomToggleButton
                    toggleItems={orderBySelectedColumn}
                    handleValues = {handleOrderSelectedColumnValues}
                    values={columnSortedType}
                    exclusive
                    customToggleButtonClassName = { classes.customToggleButtonClassName }
                    />
            </Box>
            <Box display='flex' justifyContent='end' className={classes.buttonsContainer}>
                <Button color='primary' variant='text' className={classes.commonButton} onClick={ handleClose }>
                    {t('cancel_btn')}
                </Button>
                <Button color='primary' variant='outlined' className={classes.commonButton} onClick={ defaultActionsAfterRunFilterHandler }>
                    {t('filter_btn')}
                </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default FilterRecommendationsPopover;
