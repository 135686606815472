import * as React from 'react';
import CustomSkeleton from 'components/common/CustomSkeleton';
import { Grid, Paper, makeStyles } from '@material-ui/core';

import styles from './styles/skeleton';

const useStyles = makeStyles(styles);

const Skeletons = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid container component={Paper} className={classes.containerPaper}>
        <Grid container spacing={3} className={classes.scenarioTypeContainer}>
          <Grid item xs={12} className={classes.xs6Container}>
            <CustomSkeleton
              variant='rect'
              animation='wave'
              height={40}
              className={classes.skeletonFields}
            />
          </Grid>
          <Grid item xs={12} className={classes.xs6Container}>
            <CustomSkeleton
              variant='rect'
              animation='wave'
              height={40}
              className={classes.skeletonFields}
            />
          </Grid>
          <Grid item xs={12} className={classes.xs6Container}>
            <CustomSkeleton
              variant='rect'
              animation='wave'
              height={40}
              className={classes.skeletonFields}
            />
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.xs6Container}>
          <CustomSkeleton
            variant='rect'
            animation='wave'
            height={50}
            className={classes.skeletonFields}
          />
        </Grid>
        <Grid item xs={6} className={classes.xs6Container}>
          <CustomSkeleton
            variant='rect'
            animation='wave'
            height={50}
            className={classes.skeletonFields}
          />
        </Grid>
        <Grid item xs={12} className={classes.containerBtnSaves}>
          <CustomSkeleton
            variant='rect'
            animation='wave'
            style={{ margin: '16px 10px' }}
            height={40}
            width={100}
          />
          <CustomSkeleton
            variant='rect'
            animation='wave'
            style={{ margin: '16px 10px' }}
            height={40}
            width={100}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Skeletons;
