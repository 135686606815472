import {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from 'components/views/organizations/common/CloseIcon';
import {
  makeStyles,
  Typography,
} from '@material-ui/core';
import Popover from '@mui/material/Popover';

const useStyles = makeStyles((theme) => ({

  btn: {
    marginLeft: theme.spacing(0),
    backgroundColor: theme.palette.common.white,
    transform: 'scale(0.7)',
    '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.grey[100] },
  },

  popoverContainer: {
    '& .MuiPopover-paper': { display: 'inline-table', padding: 20, width: 500 },
  },

}));

function TypographyWithInfoIconTooltip( props ) {
  const classes = useStyles();
  const {
    customBtnStyles,
    className,
    popoverContainerStyles,
    popoverTextStyles,
  } = props

const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={className}>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className={ customBtnStyles ? customBtnStyles : `${classes.btn}`}
      >
        <InfoIcon sx={{width: '16px', height: '16px', fontSize: '16px'}}/>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={popoverContainerStyles ? popoverContainerStyles : classes.popoverContainer}
      >
      <CloseIcon
          onClose={handleClose}
          style={{
            borderRadius: 25,
            position: 'absolute',
            right: -10,
            top: -10,
            transform: 'scale(0.7)',
          }}
        />
        <Typography className={ popoverTextStyles ? popoverTextStyles : undefined } >{ props.children }</Typography>
      </Popover>
    </div>
  );
}

export default TypographyWithInfoIconTooltip;
