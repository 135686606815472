import * as React from 'react';
import {
  Typography,
  Grid,
  Button as MuiButton,
  IconButton,
  makeStyles,
  InputAdornment
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';

import { useTranslation } from 'components/providers/TranslationProvider';
import { useAuth } from 'components/providers/AuthProvider';
import { TextField } from 'components/common/ReactHooksFormFields';
import Button from 'components/common/Button';
import Toast from 'components/common/Toast';
import styles, { orStyle } from './styles/login';
import CircularProgress from "@mui/material/CircularProgress";
import { providerCodes } from '../../../utils/constants';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {MdLockOutline, MdOutlinePerson} from "react-icons/md";

const useStyles = makeStyles(styles);

const schema = yup.object().shape({
  email: yup.string().email('email_required').required('required'),
  password: yup.string().min(4, 'min_4_characters').required('required'),
});

const Login = () => {
  const classes = useStyles();
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [redirectURL, setRedirectURL] = React.useState('/orgs');
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { login, isPending, loginWithSocial, openDialog, setOpenDialog } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath !== '/login') {
      setRedirectURL(currentPath);
      navigate('/login', { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleError = (e) => {
    setError(t(e));
    setOpenToast(true);
  };

  const onSubmit = (data) => login(data, redirectURL, handleError);
  const loginSocial = (provider) => loginWithSocial(provider, redirectURL, handleError);
  const handleClose = () => {
    setOpenDialog(false)
  };
  return (
    <div className={classes.paper}>
      <Typography className={classes.loginText}>
        Inclu<span style={{fontWeight:300}}>ia</span>
      </Typography>
      <Typography variant="h6" className={classes.loginSubtitle}>
        {t('login_subtitle')}
      </Typography>
      <Grid>
        <MuiButton
          variant="outlined"
          fullWidth
          color="primary"
          size="large"
          disabled={isPending}
          startIcon={<img src='/social/google.svg' className={classes.socialIcon}/> }
          className={classes.socialButton}
          onClick={() => loginSocial(providerCodes.GOOGLE)}>
          {isPending ? <CircularProgress style={{ color: 'black'}}  size={26} /> : 'Login with Google'}
        </MuiButton>

        <MuiButton
          variant="outlined"
          fullWidth
          color="primary"
          size="large"
          disabled={isPending}
          startIcon={<img src='/social/microsoft.svg' className={classes.socialIcon}/>}
          className={classes.socialButton}
          onClick={() => loginSocial(providerCodes.MICROSOFT)}>
          {isPending ? <CircularProgress style={{ color: 'black'}}  size={26} /> : 'Login with Microsoft'}
        </MuiButton>
      </Grid>

      <p style={orStyle}><Typography className={classes.or}>{t('login_or')}</Typography></p>


      <Toast message={error} handleClose={handleCloseToast} severity='error' open={openToast} />
      <FormProvider {...methods}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.loginGridEmail} >
              <TextField
                InputLabelProps={{
                  style: { color: 'rgba(50, 93, 108, 1)' },
                }}
                InputProps={{
                  style: { color: 'rgba(50, 93, 108, 1)' },
                  startAdornment: (
                  <InputAdornment position="start">
                   <MdOutlinePerson style={{color: '#6793A5', height: '48px'}}/>
                  </InputAdornment>
                ),

                }}
                className={classes.loginField}
                autoFocus
                variant='outlined'
                fullWidth
                label={t('email')}
                name='email'
                placeholder="email@example.com"
                autoComplete='email'
                error={t(errors.email?.message)}
              />
            </Grid>
            <Grid item xs={12} className={classes.loginGrid}>
                <TextField
                 InputLabelProps={{
                  style: { color: 'rgba(50, 93, 108, 1)' },
                }}
                InputProps={{
                  style: { color: 'rgba(50, 93, 108, 1)' },
                  startAdornment: (
                  <InputAdornment position="start" >
                    <MdLockOutline style={{color: '#6793A5'}}/>
                  </InputAdornment>
                ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        edge="end">
                        {showPassword ? <Visibility style={{color: '#6793A5'}}/> : <VisibilityOff style={{color: '#6793A5'}} />}
                      </IconButton>
                    </InputAdornment>
                )}}
                className={classes.loginField}
                  variant='outlined'
                  fullWidth
                  name='password'
                  placeholder="*******"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete='current-password'
                  error={t(errors.password?.message)}
                  label={t('password')}
                />

            </Grid>
          </Grid>
          <Grid container className={`${classes.containerButtons} ${errors.password?.message ? classes.containerButtonsError : ''}`} >
            <Grid item xs={8} className={classes.containerButtons}  >

              <MuiButton
                variant='text'
                component={NavLink}
                to='/forgot-password'
                className={classes.forgotPassword}
                data-cy="forgotPageLink"
              >
                {t('forgot_password')}
              </MuiButton>
            </Grid>
            <Grid item xs={12} className={classes.loginGrid}>
              <MuiButton
                loading={isPending}
                type='submit'
                fullWidth
                data-cy='login'
                className={`${classes.submitLogin} ${!isValid ? classes.submitLoginDisabled : '' }`}
                disabled={!isValid || isPending}
              >
                {isPending ? t('login...') : t('login')}
              </MuiButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('login_user_not_allowed')
              ? t('login_user_not_allowed')
              : 'The user is not register on the platform. Please contact support'
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Login;
