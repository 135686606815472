import { GLOBAL } from 'utils/constants';

const styles = (theme) => ({
  container: {
    display: 'grid',
    marginTop: 30,
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '23px',
    width: '100%',
    [theme.breakpoints.only('xl')]: {
      columnGap: '23px',
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
    [theme.breakpoints.only('lg')]: {
      columnGap: '23px',
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.only('xs')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  btnWorkSpace: {
    justifySelf: 'end',
    borderRadius: 20,
    backgroundColor: '#ffffff',
    borderColor: GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[400],
    color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900],
    '& svg': {
      fill: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[500]
    }
  },
  headerContainer: {
    justifyContent: 'space-between',
    marginTop: 56,
    marginLeft:'-1.5rem'
  },
  card: {
    justifyContent: 'center',
    alignContent: 'center',
    margin: 'auto',
  },
  horizontalPadding: {
    paddingLeft:124,
    paddingRight: 124,
  }
});
export default styles;
