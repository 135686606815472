import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Button from '../../../../common/Button';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import useExportSchemaBulkWidgets
  from '../../../../../api/hooks/useExportSchemeBulkWidgets';
import { useLocation } from 'react-router';
import { Typography } from '@material-ui/core';
import {
  buttonCommonStyle,
  typographyCommonStyle
} from './styles/uploadDownloadJson';

const DownloadJSON = ({id, values, layers, tabs, widgets, setErrors}) => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { mutateAsync } = useExportSchemaBulkWidgets(setErrors);

  const handleExport = () => {
    try {
      mutateAsync({
        userId: id,
        scheme: {
          ...values,
          id: location.pathname.split('/')[4] === 'create' ? '' : location.pathname.split('/')[4],
          layers,
          widgets,
          tabs,
          type: 'descriptive-spatial-analysis',
        },
      }).then(async () => {
        const data = queryClient.getQueryData('export_scheme_bulk_widgets');
        const json = JSON.stringify(data);
        const blob = new Blob([json], { type: 'application/json' });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = `${data.name} schema.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } catch (e) {
      setErrors('testing');
    }
  };

  return  (
    <Button
      startIcon={<CloudDownloadIcon />}
      style={buttonCommonStyle}
      component='span'
      variant='outlined'
      onClick={handleExport}
    >
      <Typography style={typographyCommonStyle}>
        Download JSON
      </Typography>
    </Button>
  );
}

export default DownloadJSON
