import * as React from 'react';
import CustomSkeleton from 'components/common/CustomSkeleton';
import { Card, CardActionArea, CardContent, makeStyles } from '@material-ui/core';
import styles from './styles/card';

const useStyles = makeStyles(styles);

const CardSkeleton = () => {
  const classes = useStyles();

  return (
    <Card className={`${classes.root} ${classes.marginAuto}`}>
      <CardActionArea className={classes.cardActionArea}>
        <div className={classes.orgName}>
         <CustomSkeleton variant='rect' height={200} width={219} className={`${classes.skeleton} ${classes.media} ${classes.marginAuto}`} />
          <CardContent>
            <CustomSkeleton variant='text' height={40} className={classes.orgNameTxt} />
          </CardContent>
        </div>
      </CardActionArea>
    </Card>
  );
};
export default CardSkeleton;
