import logo from '../../../../assets/img/logo.0c7a85ba.png';
import logoIncluia from '../../../../assets/img/prosperia_4.png'

const styles = (theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: logo,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'str',
    width: '100%'
  },
  imageGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundImage: `url(${logoIncluia})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
  },
  loginText: {
    marginBottom: theme.spacing(1),
    fontSize: '45px',
    color: 'rgba(0, 187, 212, 1)',
    fontFamily: 'Raleway',
    fontWeight:600,
    textTransform:'uppercase'
  },
  loginSubtitle: {
    maxWidth:'62%',
    fontSize: '21px',
    fontFamily: 'Raleway',
    fontWeight:500,
    width: '62%',
    textAlign: 'center',
    lineHeight: '20px',
    marginBottom: '38px'
  },
  text: {
    width: '312px'
  },
  paper: {
    width:'556px',
    margin: theme.spacing(10, 5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '312px',
  },
  submitLogin: {
    backgroundColor: 'rgba(0, 187, 212, 1)',
    borderRadius: '20px',
    width: '312px',
    height: '40px',
    color: 'white !important',
    fontSize: '14px',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: 'rgba(0, 150, 170, 1)',

    },
  },
  submitLoginDisabled : {
    backgroundColor: 'rgba(0, 187, 212, .5)',
  },

  forgotPassword: {
    color: 'rgba(103, 147, 165, 1)',
    fontSize: '12px',
    cursor: 'pointer',
    marginLeft: '4px',
    padding: theme.spacing(0),
    textTransform: 'none',
    fontWeight: 400
  },
  containerButtons: {
    display: 'flex',
    gap: '12px',
    marginTop: '6px'

  },
  containerButtonsError: {
    display: 'flex',
    gap: '12px',
    marginTop: '36px',

  },
  loginField: {
    border: '5px',
    width: '312px',
    height: '56px'
  },
  loginGridEmail: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom:'8px !important',
    paddingTop:'0px !important'
  },
  loginGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  socialButton: {
    textTransform: 'none',
    color: '#212121',
    border: '1px solid #6793A5',
    "&:hover": {
      backgroundColor: '#F6FEFF',
      border: '1px solid #6793A5',
    },
    paddingY: '12px 64px',
    marginBottom: '9px',
    display: 'flex',
    borderRadius: '20.5px',
    width: '312px',
    height: '40px',
    fontSize: '13px',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'

  },
  socialIcon: {
    position: 'absolute',
    left: '12px',
    top: '8px'
  },

  dividerLogin: {
    width: '100%'
  },
  languageSelectorContainer: {
    display: 'flex', // Ensures flexbox is used
    alignItems: 'center', // Centers vertically
    justifyContent: 'flex-end', // Aligns content to the end (right side)
    fontSize: '14px',
    marginTop: '24px',
    marginRight: '15vw', // Ensures the margin to the right is applied
    width: 'auto' // Adjust the width as needed, 'auto' to take as much space as needed
  },
    langContainer :{
    position: 'absolute',
    right: '10%',
    top: '28px'
  },
  or:{
    fontFamily: 'Raleway',
    fontSize: '16px'
  },
  forgot: {
    fontFamily: 'Raleway',
    fontSize: '26px',
    fontWeight: 'bold',
    textAlign: 'start'
  }
});

export const orStyle = {
  marginTop:'3px',
  marginBottom:'12px'
}
export default styles;
