import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  Divider,
  MenuItem,
  makeStyles,
  Grid,
  Typography
} from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import Modal from 'components/common/Modal';
import { useTranslation } from 'components/providers/TranslationProvider';
import { SelectField, TextField, ColorField } from 'components/common/ReactHooksFormFields';
import Toast from 'components/common/Toast';
import { setIsValidLayerForm } from 'store/appSlice';
import { ReactSVG } from 'react-svg';
import { GLOBAL } from '../../../../../../../utils/constants';
import { iconsName } from './iconsName';
import { Select } from '@mui/material';
import { useTheme } from '../../../../../../providers/CustomThemeProvider';
import {
  colorFieldStyle, divMenuItemStyle,
  labelSelectStyle,
  labelTextStyle, selectStyle,
  textFieldStyle
} from './styles/addItemLegendModal';

const iconCustomDefault = 'circle.svg';
const useStyles = makeStyles((theme) => ({
  selectSkeleton: {
    height: 80,
    margin: theme.spacing(0, 0, 1),
  },
  btnClose: {
    borderRadius: 20,
    color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]
  },
  buttonAction: {
    padding:'10px 16px 10px 16px',
    borderRadius: '20px',
    color:'#005F64',
    borderColor:'#005F64',
    height:'37px',
    width:'105px',
    '&:hover': {
      borderColor:'#005F64',
    },
  },
}));

const schema = yup.object().shape({
  name: yup.string().min(4, 'min_4_characters').required('url_required'),
  value: yup.string().required('required'),
  color: yup.string().required('required'),
});

const AddItemLegendModal = ({ items, setItem, open, onClose, values, isPointDataset }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [error, setError] = React.useState('');
  const [openToast, setOpenToast] = React.useState(false);
  const dispatch = useDispatch();
  const getRandomId = () => Date.now().toString(36) + Math.random().toString(36).substr(2);
  const { theme } = useTheme();
  const defaultIcon = iconsName.find(i => i.name === 'marker')?.name;

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: { color: localStorage.getItem('colorPrimary') },
    mode: 'all',
  });
  const {
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = methods;

  const findRepeatElements = (name, value) => {
    const foundItemName = _.find(items, { name });
    const foundItemColumn = _.find(items, { value });
    return !!(foundItemName?.id || foundItemColumn?.id);
  };

  const onSubmit = handleSubmit((data) => {
    if (findRepeatElements(data.name, data.value)) {
      setError(t('error_adding_new_item'));
      setOpenToast(true);
      return;
    }
    setItem({ id: getRandomId(), ...data, icon: valueIconItem });
    dispatch(setIsValidLayerForm(true));
    onClose();
    reset({ name: '', value: '', color: localStorage.getItem('colorPrimary') });
  });

  const handleCloseToast = () => setOpenToast(false);
  const [valueIconItem, setValueIconItem] = React.useState( iconCustomDefault);

  const handleInputIcon = (value) => {
    if (value!=='') {
      setValueIconItem(value + '.svg');
    }
  }

  const actions = (
    <>
      <Button onClick={onClose}
              color='primary'
              variant='text'
              className={classes.btnClose}>
        <Typography  fontFamily='Montserrat'
                     fontSize={14}
                     letterSpacing={0.25}>
          {t('cancel_btn')}
        </Typography>
      </Button>
      <Button onClick={onSubmit}
              color='primary'
              disabled={!isValid}
              variant='outlined'
              className={classes.buttonAction}>
        <Typography  fontFamily='Montserrat'
                     fontSize={14}
                     letterSpacing={0.25}>
          {t('save_changes')}
        </Typography>
      </Button>
    </>
  );

  const itemForm = (
    <>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      <Grid item xs={12}>
        <SelectField
          name='value'
          error={t(errors.value?.message)}
          variant='outlined'
          label={t('column')}
          fullWidth
          InputLabelProps={{
            style: labelSelectStyle
          }}
        >
          <Divider light value=' ' />
          {values?.map((d) => (
            <MenuItem key={d} value={d}>
              {d}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
      <Grid item xs={12}>
      <TextField
        name='name'
        margin='dense'
        type='text'
        label={t('item_name')}
        variant='outlined'
        fullWidth
        error={t(errors.name?.message)}
        InputLabelProps={{
          style: labelTextStyle
        }}
      />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <div>
            <TextField
              fullWidth
              style={textFieldStyle}
              name="color"
              variant="outlined"
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <ColorField
                    name='color'
                    error={t(errors.color?.message)}
                    style={colorFieldStyle}
                  />
                ),
              }}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          {isPointDataset && (
            <Select
              labelId="icon-select-label"
              id="icon-select"
              defaultValue={defaultIcon}
              onChange={handleInputIcon}
              fullWidth
              sx={selectStyle}
            >
              {iconsName.map((key) => (
                <MenuItem value={key.name} key={key.name}>
                  <div style={{
                    ...divMenuItemStyle,
                    background: theme.palette.primary.main,
                  }}>
                    <ReactSVG
                      src={`${process.env.PUBLIC_URL}/maki-icons/${key.name}.svg`}
                      beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name');
                        svg.setAttribute('style',
                          'margin-top:6px; width: 100%; height: 100%;');
                      }}
                      afterInjection={(svg) => {
                        const path = svg.querySelector('path');
                        if (path) {
                          path.setAttribute('fill', 'white');
                        }
                      }}
                    />
                  </div>
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
      </Grid>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form style={{ width: '100%' }}>
        <Modal open={open} onClose={onClose} title={t('add_new_item')} actions={actions}>
          {itemForm}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default AddItemLegendModal;
