import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import {
  alpha,
  Box,
  capitalize,
  IconButton,
  Menu,
  MenuItem,
  styled,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import { ClickAwayListener, Paper, Popper, makeStyles } from '@material-ui/core';
import RectangleIcon from 'lib/react-geo-tool/packages/react-ui/src/assets/icons/RectangleIcon';
import CircleIcon from 'lib/react-geo-tool/packages/react-ui/src/assets/icons/CircleIcon';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import DrawIcon from "@mui/icons-material/Draw";
import {lighten} from "@mui/material/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";


function FeatureSelectionWidgetUI({
  className,
  sx,
  selectionModes,
  selectedMode,
  onSelectMode,
  enabled,
  onEnabledChange,
  geometry,
  onSelectGeometry,
  onDeleteGeometry,
  tooltipPlacement
}) {
  return (
    <Wrapper sx={sx} className={className} enabled={enabled}>
      <Helper
        hasMode={!!selectedMode}
        enabled={enabled}
      >
    <SelectedModeViewer
          modes={[
            ...selectionModes
          ]}
          selectedMode={selectedMode}
          enabled={enabled}
          onEnabledChange={onEnabledChange}
          tooltipPlacement={tooltipPlacement}
        />
      </Helper>
      <ModesSelector
        selectionModes={selectionModes}
        selectedMode={selectedMode}
        onSelectMode={onSelectMode}
        enabled={enabled}
        tooltipPlacement={tooltipPlacement}
      />
    </Wrapper>
  );
}

FeatureSelectionWidgetUI.defaultProps = {
  sx: undefined,
  enabled: false,
  tooltipPlacement: 'bottom',
  className: ''
};

const MODE_SHAPE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired
});

FeatureSelectionWidgetUI.propTypes = {
  sx: PropTypes.any,
  className: PropTypes.any,
  selectionModes: PropTypes.arrayOf(MODE_SHAPE.isRequired).isRequired,
  selectedMode: PropTypes.string.isRequired,
  onSelectMode: PropTypes.func,
  enabled: PropTypes.bool,
  onEnabledChange: PropTypes.func,
  geometry: PropTypes.any,
  onSelectGeometry: PropTypes.func,
  tooltipPlacement: PropTypes.string
};

export default FeatureSelectionWidgetUI;

const useSelectedModeViewerStyles = makeStyles((theme) => ({
  btn: {
    color: ({ enabled }) =>
      enabled ? theme.palette.primary.main : theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    padding: '0px !important'

  },
  items: {
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

// Aux
function Helper({ hasMode, enabled, isEdit, children }) {
  const alreadyOpenedRef = useRef({});
  const shouldOpen = hasMode && enabled;

  const [open, setOpen] = useState(shouldOpen);

  useEffect(() => {
    const wasAlreadyOpened = alreadyOpenedRef.current[isEdit];
    if (wasAlreadyOpened) {
      setOpen(false);
      return;
    }

    if (shouldOpen) {
      alreadyOpenedRef.current = {
        ...alreadyOpenedRef.current,
        [isEdit]: true
      };
      setOpen(true);
      const timeout = setTimeout(() => setOpen(false), 5000);

      return () => clearTimeout(timeout);
    }
    // Logic around show/hide helper tooltip is manage by shouldOpen var
  }, [shouldOpen, isEdit]);
}

function SelectedModeViewer({
  modes,
  selectedMode,
}) {
  const { label, icon, isEdit } = useMemo(() => {
    if (modes?.length && selectedMode) {
      const foundMode = modes.find(({ id: modeId }) => modeId === selectedMode);
      if (!foundMode) {
        throw new Error('Selected mode not supported');
      }
      return foundMode;
    } else {
      return {};
    }
  }, [modes, selectedMode]);
}

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'enabled'
})(({ theme: { palette }, enabled }) => ({
  color: '#616161',
  '&:hover': {
    backgroundColor: `${lighten(palette.primary.main, 0.90)}`
  }
}));

function ModesSelector({
  selectionModes,
  selectedMode,
  onSelectMode,
  enabled,
}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useSelectedModeViewerStyles({ enabled });
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectMode = (newSelectedMode) => {
    onSelectMode(newSelectedMode);
    setSelection(newSelectedMode);
    handleClose();
  };

  const hasSelectionModes = !!selectionModes.length;

  const MenuItemWrapper = forwardRef(({ mode, isEnabled }, ref) => (
    <StyledMenuItem
      enabled={isEnabled}
      ref={ref}
      onClick={() => handleSelectMode(mode.id)}
    >
      <Box>
        {mode.icon}
      </Box>
    </StyledMenuItem>
  ));

  const createMenuItemWrapper = (mode) => (
    <MenuItemWrapper
      key={mode.label}
      mode={mode}
      isEnabled={enabled && selectedMode === mode.id}
    />
  );
  const [selection, setSelection] = useState('IsochroneFromMapMode');
  let Icon;
  switch (selection) {
    case 'DrawRectangleMode':
      Icon = RectangleIcon;
      break;
    case 'DrawCircleFromCenterMode':
      Icon = CircleIcon;
      break;
    case 'IsochroneFromMapMode':
      Icon = AddLocationAltIcon;
      break;
    case 'DrawPolygonByDraggingMode':
      Icon = DrawIcon;
  }

  return (
    <Box >
      <IconButton onClick={handleClick} className={classes.btn}>
        {Icon && <Icon className={classes.btn} />}
        <ArrowDropDownIcon className={classes.btn}/>
      </IconButton>
        <Box sx={{width: '56px'}}>
          <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <Menu
            style={{ zIndex: theme.zIndex.tooltip + 1}}
            id='fade-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'fade-button'
            }}
          >
            {hasSelectionModes && selectionModes.map(createMenuItemWrapper)}
          </Menu>
        </ClickAwayListener>
        </Popper>
        </Box>
    </Box>
  );
}

const StylesWrapper = styled('div')(({ theme: { spacing, palette, shape, enabled } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: spacing(0.5),
  borderRadius: '8px',
  }));

const sinHover ={
  '&:hover': {
    backgroundColor: 'transparent'
  },

}
function Wrapper({ sx, className, children }) {
  return (
    <StylesWrapper className={className} sx={sx}>
      {children}
    </StylesWrapper>
  );
}
