import * as React from 'react';
import { 
  makeStyles, 
  Grid, 
  Container,
  Typography,
  Box,
  Divider
} from '@material-ui/core';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { 
  setRecomputedRecommendationsWidgets, 
  setWidgetFiltersForCurrentOffer, 
  setWidgetFiltersForTargetDemand  } 
from 'store/appSlice';

import Modal from 'components/common/Modal';
import Toast from 'components/common/Toast';
import { TextField, SliderFieldCustom } from 'components/common/ReactHooksFormFields';
import Button from 'components/common/Button';
import TypographyWithInfoIconTooltip from 'components/common/TypographyWithInfoIconTooltip';

import { useTranslation } from 'components/providers/TranslationProvider';
import { GLOBAL } from 'utils/constants';
import IOSSwitch from 'components/common/IOSSwitch';
import { generateDynamicShades } from 'utils/colors';
import { useTheme } from 'components/providers/CustomThemeProvider';
import { useDispatch, useSelector } from 'react-redux';
import { getWidgetsWithFiltersByLayerId } from 'utils/supportComponents';

const useStyles = makeStyles( (theme) => {
  return ({
    btnClose: {
      borderRadius: 20,
      color: generateDynamicShades(theme.palette.primary.main)[900]
    },
    btnAccept: {
      borderRadius: 20,
      color: generateDynamicShades(theme.palette.primary.main)[900],
      border: `1px solid ${generateDynamicShades(theme.palette.primary.main)[400]}`,
      '&:hover': {
        backgroundColor: generateDynamicShades(theme.palette.primary.main)[50],
      },
    },
    container: {
      padding: theme.spacing(2),
      marginBottom: 56
    },
    labelColor: {
      color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700] 
    },
    variableName: {
      margin: 'auto',
      marginLeft: 4,
      width: 70,
      overflow: 'hidden',
      wordWrap: 'break-word'
    },
    alignSelfCenter: {
      alignSelf: 'center'
    },
    customSwitchLabel: {
        marginLeft: '10px !important',
        marginRight: '10px !important',
        '& .MuiTypography-root': {
          fontFamily: 'Montserrat !important',
          fontSize: 16,
          fontWeight: 400,
          lineHeight: '19.5px',
          letterSpacing: '0.5px',
          color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700]
        }
    },
    marginAuto: {
      margin: 'auto',
    },
    generateFormModal: {
      '& .Component-root': {
        paddingLeft: '24px !important',
      }
    }
})});

const ComputeRecommendationsFormModal = ({ action, open, onClose, defaultValues, dataForOP, setDataForOP, widget, widgetId }) => {
  const theme = useTheme();
  const classes = useStyles( theme );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [recommendedExpansion, setRecommendedExpansion] = React.useState(defaultValues.recommended_expansion);
  const selectedWidgetId = useSelector((state) => state.app.selectWidgetId);
  const selectedWidgetEntity = useSelector((state) => ( state.app.recomputedRecommendationsWidgets.find(w => w.id === selectedWidgetId) || state.app.widgets.find(w => w.id === selectedWidgetId )));
  const sources = useSelector((state)=> state.carto.dataSources);
  const layers = useSelector((state)=> state.app.layers);
  const widgets = useSelector((state)=> state.app.widgets);

  const getDefaultValues = () => {
    return {
      offerCoverageRadius: (selectedWidgetEntity?.params?.coverage || selectedWidgetEntity?.params?.coverage === 0) ? selectedWidgetEntity?.params?.coverage : selectedWidgetEntity?.params.offerCoverageRadius,
      offerCost: ( selectedWidgetEntity?.params?.offer_cost ) ? selectedWidgetEntity?.params?.offer_cost || 1 : selectedWidgetEntity?.params?.offerCost || 1,
      isOfferCapacitySelected: ( selectedWidgetEntity?.params?.is_offer_capacity_selected ) ? selectedWidgetEntity?.params?.is_offer_capacity_selected : selectedWidgetEntity?.params?.isOfferSelected,
      offerCapacity: ( selectedWidgetEntity?.params?.offer_capacity ) ? selectedWidgetEntity?.params?.offer_capacity || 1 : selectedWidgetEntity?.params?.offerCapacity || 1
    }
  };

  const clearComputedSelectedWidgetFilters = () => {
    dispatch(setWidgetFiltersForTargetDemand( [] ));
    dispatch(setWidgetFiltersForCurrentOffer( [] ));    
  }

  const methods = useForm({
    // resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    watch,
    setValue,
  } = methods;

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const offerCoverageRadius = watch('offerCoverageRadius');
  const offerCost = watch('offerCost');
  const isOfferCapacitySelected = watch('isOfferCapacitySelected');
  const offerCapacity = watch('offerCapacity');


  const recomputeAndUpdateOPData = () => {
    const potentialOffersParams = {
      ...dataForOP,
      offerCoverageRadius,
      offerCost,
      isOfferCapacitySelected,
      offerCapacity
    }
    defaultValues.createPotentialOffers(potentialOffersParams);
    const updatedWidget = {
      id: selectedWidgetEntity.id,
      layer: selectedWidgetEntity.layer,
      params: {
        ...selectedWidgetEntity.params,
      },
    };
    updatedWidget.params.offerCoverageRadius = offerCoverageRadius;
    updatedWidget.params.offerCost = offerCost;
    updatedWidget.params.isOfferCapacitySelected = isOfferCapacitySelected;
    updatedWidget.params.offerCapacity = offerCapacity;
    updatedWidget.params.coverage = offerCoverageRadius;
    updatedWidget.params.offer_cost = offerCost;
    updatedWidget.params.offer_capacity = offerCapacity;
    dispatch(setRecomputedRecommendationsWidgets( updatedWidget ));
    clearComputedSelectedWidgetFilters();
        
  }

  const formComputeRecommendations = (
    <Container display='flex' className={classes.container}>
      
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      <Grid item xs={12}>
        <Box justifyContent="space-between" display="flex">
          <Typography variant="body1" className={` ${classes.labelColor} ${classes.alignSelfCenter} `}>
            { defaultValues?.customLabels?.coverageRadius || t('coverage_radius') }:
          </Typography>
          <TypographyWithInfoIconTooltip >
            { t('recommendations_offer_coverage_radius') }
          </TypographyWithInfoIconTooltip>
        </Box>
      </Grid>      
      <Grid item xs={12}>
          <Box justifyContent="space-between" display="flex">
          <SliderFieldCustom
            name='offerCoverageRadius'
            defaultValue={200}
            min={200}
            customMax={20000}
            valueLabelDisplay='auto'
            custom
            gridSliderInputColumn= {5}
            gridSliderColumn= {7}
          />
          <Typography variant='caption' className={`${classes.labelColor} ${classes.variableName}`}>
            (coverage)
          </Typography>
          </Box>
          <Divider style={{ marginTop: 20, marginBottom: 20 }}/>          
      </Grid>
      <Grid item xs={12}>
        <Box justifyContent="space-between" display="flex">
          <Typography variant="body1" className={` ${classes.labelColor} ${classes.alignSelfCenter} `}>
            { defaultValues?.customLabels?.cost || t('cost') }:
          </Typography>
          <TypographyWithInfoIconTooltip >
            { t('recommendations_offer_cost') }
          </TypographyWithInfoIconTooltip>
        </Box>
      </Grid>      
      <Grid item xs={12}>
          <Box justifyContent="space-between" display="flex">
          <SliderFieldCustom
            name='offerCost'
            defaultValue={1}
            step={1}
            min={1}
            max={100}
            valueLabelDisplay='auto'
            custom
            gridSliderInputColumn= {5}
            gridSliderColumn= {7}
          />
          <Typography variant='caption' className={` ${classes.labelColor} ${classes.variableName} `}>
            {`(${ t('unit').toLowerCase()})`}
          </Typography>

          </Box>
          <Divider style={{ marginTop: 20, marginBottom: 20 }}/>          
      </Grid>
      <Grid item xs={12}>
        <Box justifyContent="space-between" display="flex">
        <Typography variant='body1' className={` ${classes.labelColor} ${classes.alignSelfCenter} `}>
            {t('recommendations_is_offer_capacity_selected')}
          </Typography>
          <IOSSwitch
            name="isOfferCapacitySelected"
            className={classes.customSwitch} 
            labelPlacement="start" 
            labelClasses={ classes.customSwitchLabel }
            checked={isOfferCapacitySelected}
            onChange={(e) => setValue('isOfferCapacitySelected', e.target.checked)}
          />
          <Typography variant='caption' className={`${classes.labelColor} ${classes.marginAuto}`}>
            { t('no_yes') }
          </Typography>
          <TypographyWithInfoIconTooltip className={ classes.marginAuto }>
            { t('recommendations_offercapacity_tooltip') }
          </TypographyWithInfoIconTooltip>
        </Box>
      </Grid>      
      {isOfferCapacitySelected ? (
        <Grid item xs={12}>
          <Box justifyContent="space-between" display="flex" style={{marginTop: 5, width: '100%'}}>
            <SliderFieldCustom
              name='offerCapacity'
              defaultValue={1}
              step={1}
              min={1}
              max={1000}
              valueLabelDisplay='auto'
              custom
              gridSliderInputColumn= {5}
              gridSliderColumn= {7}
              
              
            />
          <Typography variant='caption' className={` ${classes.labelColor} ${classes.variableName} `}>
            (capacity_lower_bound)
          </Typography>
          </Box>
        </Grid> 
      ) : null }
    </Container>
  );

  const actions = (
    <>
      <Button onClick={onClose} variant='text' className={classes.btnClose}>
        {t('cancel_btn')}
      </Button>
      <Button
        type='submit'
        variant='outlined'
        className={classes.btnAccept}
        onClick={ recomputeAndUpdateOPData }
      >
        { t('widget_button_generate_label') }
      </Button>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          title={
            t('compute_recommendations_modal_header')
          }
          actions={actions}
          widthInPixels={360}
          className={classes.generateFormModal}
        >
          {formComputeRecommendations}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default ComputeRecommendationsFormModal;
