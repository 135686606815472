import { styled, Typography, Grid } from '@mui/material';

export const MuiGridTitle = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start'
}));

export const MuiGridCalculator = styled(Grid)(({ theme, type }) => ({
  borderTop:'2px solid #E0E0E0',
  marginRight:'10px',
  marginTop: type === 'histogram' || type === 'recommendations' ? '24px' : '-6.5rem',
  height:'180px',
  maxWidth:'none',
  display:'flex'
}));

export const MuiGridExpression = styled(Grid)(({ theme }) => ({
  maxHeight:'350px',
  display:'flex'
}));

export const MuiTypographyCommon = styled(Typography)(({ theme }) => ({
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:16,
  color:'#000000'
}));

export const iconButtonStyle = {
  color: '#9E9E9E',
  marginTop: -0.5,
  marginLeft: 3
}

export const listElementsStyle = {
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:16,
  color:'#000000'
}

export const closeIconStyle = {
  border: '1px solid',
  borderRadius: '50%',
  cursor: 'pointer',
  marginRight: 1
}
