const styles = (theme) => ({
  color: {
    height: 20,
    width: 70,
    marginRight: 8,
    '& .MuiOutlinedInput-input': {
      padding: '10px 2px',
    },
  },
  divContainer: {
    marginTop:25
  },
  containerControls: {
    justifyContent: 'flex-end',
    justifySelf: 'end',
    display: 'flex',
  },
  containerItem: {
    justifyContent: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    width: '610px',
    height: theme.spacing(3),
    padding: 0,
  },
  zIndex: {
    zIndex: theme.zIndex.tooltip,
  },
});

export const divContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: '1px'
}

export const outlinedInputStyle = {
  width: '50vh',
  height: '56px'
}

export const dragHandleStyle = {
  cursor: 'row-resize',
  marginRight: 8,
  marginLeft:16,
  color:'#757575'
}

export const gridDeleteStyle = {
  marginLeft:'8px',
  marginRight:'6px'
}

export const divMenuItemStyle = {
  borderRadius:'50%',
  width:'24px',
  height:'24px',
  padding:2
}

export const selectStyle = {
  height: '55px',
  width:'108px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#757575',
  },
}

export const divColorValueStyle = {
  width: '100px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start'
}

export const outlinedColorStyle = {
  width: '25vh',
  height: '56px',
  paddingLeft:'8px',
  paddingRight:'10px'
}

export const inputTextStyle = {
  fontSize: '14px',
  fontWeight: 400,
  color: '#424242',
  fontFamily: 'Montserrat',
  marginLeft: '10px',
}

export default styles;
