import { Grid, styled } from '@mui/material';

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  borderBottom:'1px solid',
  borderColor:'#E0E0E0',
  padding:1,
  marginTop:'7px',
  marginBottom:'7px'
}));

export const MuiGridSorteableList = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  alignContent: 'flex-start',
  overflow: 'auto',
  paddingLeft: '10px',
  paddingRight: '10px',
}));

export const MuiContainerIcons = styled("div")(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '19.1rem'
}));

export const commonIconsWidgetStyle = {
  color:'#FFFFFF',
  padding:'2px',
  borderRadius:'50%',
  marginRight:'5px'
}

export const dragHandleStyle = {
  cursor: 'row-resize',
  marginRight: 8,
  color:'#757575'
}
