// External library imports
import {styled, Typography} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';

// Internal component and function imports
import Button from 'components/common/Button';
import {lighten} from "@mui/material/styles";

const styles = (theme) => ({
  btnClose: {
    marginRight: theme.spacing(1),
    color:'#005F64',
    borderRadius: '20px',
    width:'105px',
    height:'37px',
    '&:hover':{
      backgroundColor: lighten('#005F64', 0.8)
    }
  },
  btnDelete: {
    padding:'10px 16px 10px 16px',
    borderRadius: '20px',
    width:'105px',
    height:'37px',
    borderColor:'#C71A0F',
    color: '#C71A0F',
    '&:hover':{
      backgroundColor: lighten('#F63B2B', 0.8),
      borderColor:'#C71A0F',
    }
  },
  typographyContent: {
    textAlign: 'center',
    margin: theme.spacing(5, 1),
    fontSize: theme.spacing(2.5),
  },
  typographyOrgInfo: {
    textAlign: 'justify',
    margin: theme.spacing(2, 1),
    fontSize: theme.spacing(2.5),
  },
  typographyName: {
    textAlign: 'center',
    margin: theme.spacing(1, 2, 3, 2),
    fontWeight: 900,
    fontSize: theme.spacing(3),
  },
  containerIcon: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  warningIcon: {
    color: theme.palette.warning.main,
    width: theme.spacing(16),
    height: '50px !important',
    padding: theme.spacing(1),
  },
});

export default styles;

export const MuiButtonClose = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const MuiButtonDelete = styled(Button)(({ theme }) => ({
  backgroundColor: '#FF6464 !important',
  '&:hover': {
    backgroundColor: '#ff2020 !important',
    borderColor: '#ff2020 !important',
  },
}));

export const MuiWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: '#f29e02',
  width: '100px !important',
  height: '100px !important',
  padding: '8px !important'
}));

export const MuiTypographyContent = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(5, 1),
  fontSize: theme.spacing(2.5),
}));

export const MuiTypographyOrgInfo = styled(Typography)(({ theme }) => ({
  textAlign: 'justify',
  margin: theme.spacing(2, 1),
  fontSize: theme.spacing(2.5),
}));

export const MuiTypographyName = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(1, 2, 3, 2),
  fontWeight: 700,
  fontSize: theme.spacing(3),
}));
