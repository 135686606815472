import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { styled } from '@mui/material/styles';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VariableInfo from 'components/common/VariableInfo';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({

  firstLine: {
    margin: theme.spacing(1, 1, 1, 0),
    fontWeight: '600',
    fontSize: '0.875rem',
    fontFamily: 'Open Sans, sans-serif',
    lineHeight: '1.429',
    letterSpacing: '0.018em',
    webkitFontSmoothing: 'antialiased',
    color: 'rgb(44, 48, 50)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  valueParam: {
    color: '#000000e3',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  subContainerElem: {
    display: 'flex',
    fontWeight: '600',
    fontSize: '16px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  containerInfo: {
    flexBasis: 'unset',
    padding: '0px !important',
  },
  gridContainer: {
    gap: '12px',
  }
}));

function StatisticsWidgetUI(props) {
  const { data, unfilteredData } = props;
  const classes = useStyles();
  return (
    <div style={{ width: '406px', marginBottom: '10px' }}>
      <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className={classes.gridContainer}>
        {data?.map(
          (elem) => {
              const unfilteredItem = unfilteredData.find( ( d ) => elem.field === d.field );
              return elem.visible && (
              <Grid item xs={6} className={classes.containerInfo}>
                <VariableInfo
                  prefix = { elem.prefix||null }
                  label = { elem.name}
                  data = { unfilteredItem?.result}
                  name = { elem?.suffix||null }
                  filterData = { elem?.result }
                  filterUnit = { elem?.suffix }
                />
              </Grid>
            ) }
        )}
      </Grid>
    </div>
  );
}

export default StatisticsWidgetUI;
