import * as React from 'react';
import {
  makeStyles,
  Grid,
  Container,
  Typography,
  Box,
  Divider,
  MenuItem
} from '@material-ui/core';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from 'components/common/Modal';
import Toast from 'components/common/Toast';
import { TextField, SliderField, SelectField, SliderFieldCustom } from 'components/common/ReactHooksFormFields';
import Button from 'components/common/Button';
import TypographyWithInfoIconTooltip from 'components/common/TypographyWithInfoIconTooltip';
import { setRecomputedRecommendationsWidgets, setWidgetFiltersForCurrentOffer, setWidgetFiltersForTargetDemand } from 'store/appSlice';

import { useTranslation } from 'components/providers/TranslationProvider';
import { GLOBAL } from 'utils/constants';
import IOSSwitch from 'components/common/IOSSwitch';
import { generateDynamicShades } from 'utils/colors';
import { useTheme } from 'components/providers/CustomThemeProvider';
import CustomSkeleton from 'components/common/CustomSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getWidgetsWithFiltersByLayerId } from 'utils/supportComponents';
import useDataSetColumns from "../../../../../api/hooks/useDataSetColumns";

const useStyles = makeStyles( (theme) => {
  return ({
    btnClose: {
      borderRadius: 20,
      color: generateDynamicShades(theme.palette.primary.main)[900]
    },
    btnAccept: {
      borderRadius: 20,
      color: generateDynamicShades(theme.palette.primary.main)[900],
      border: `1px solid ${generateDynamicShades(theme.palette.primary.main)[400]}`,
      '&:hover': {
        backgroundColor: generateDynamicShades(theme.palette.primary.main)[50],
      },
    },
    container: {
      padding: theme.spacing(2),
      paddingTop: 8,
      marginBottom: 56
    },
    labelColor: {
      color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700]
    },
    variableNameCommon: {
      margin: 'auto',
      marginLeft: 4,
    },
    variableName: {
      wordWrap: 'break-word',
      overflow: 'hidden',
      maxWidth: 60,
    },
    alignSelfCenter: {
      alignSelf: 'center'
    },
    customSwitchLabel: {
        marginLeft: '0px !important',
        marginRight: '10px !important',
        '& .MuiTypography-root': {
          fontFamily: 'Montserrat !important',
          fontSize: 16,
          fontWeight: 400,
          lineHeight: '19.5px',
          letterSpacing: '0.5px',
          color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700]
        },
        '& .MuiFormControlLabel-root': {
          marginLeft: '0px !important',
        }
    },
    servicePointLabel: {
      marginTop: 8,
      marginBottom: 4
    },
    marginAuto: {
      margin: 'auto',
    },
    yesNoLabel: {
      marginLeft: 10,
      marginRight: 11
    },
    maxCapacityToExpand: {
      fontWeight: 500,
      fontSize: 12,
      marginTop: 25,
      marginBottom: 6,
    },
    maxCostToExpand: {
      fontWeight: 500,
      fontSize: 12,
      marginBottom: 6,
      marginTop: 15,
    },
    demmandAccountLabel: {
      marginBottom: 16.5,
    },
    densityVariableStyles: {
      paddingBottom: 16
    }
})});

const GenerateRecommendationsFormModal = ({ action, open, onClose, defaultValues, dataForRecom, setDataForRecom  }) => {
  const theme = useTheme();
  const classes = useStyles( theme );
  const { t } = useTranslation();

  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const selectedWidgetId = useSelector((state) => state.app.selectWidgetId);
  const selectedWidgetEntity = useSelector((state) => ( state.app.recomputedRecommendationsWidgets.find(w => w.id === selectedWidgetId) || state.app.widgets.find(w => w.id === selectedWidgetId)));
  const dispatch = useDispatch();
  const sources = useSelector((state)=> state.carto.dataSources);
  const layers = useSelector((state)=> state.app.layers);
  const widgets = useSelector((state)=> state.app.widgets);
  const [demandDatasetName,] = React.useState(selectedWidgetEntity?.params?.demandDatasetName);
  const getDefaultValues = () => {
    return {
      budget: selectedWidgetEntity?.params?.budget ? selectedWidgetEntity?.params?.budget :  1,
      expanxionCapacity: selectedWidgetEntity?.params?.expansion_capacity ? selectedWidgetEntity?.params?.expansion_capacity : selectedWidgetEntity?.expanxionCapacity,
      expanxionCost: selectedWidgetEntity?.params?.expansion_cost ? selectedWidgetEntity?.params?.expansion_cost :  selectedWidgetEntity?.expanxionCost,
      welfareVariable:  selectedWidgetEntity?.params?.w_var ? selectedWidgetEntity?.params?.w_var : selectedWidgetEntity?.params?.welfareVariable,
      densityVariable: selectedWidgetEntity?.params?.quant_var ? selectedWidgetEntity?.params?.quant_var : selectedWidgetEntity?.params?.densityVariable,
      isWelfareFormulaSelected: selectedWidgetEntity?.params?.is_welfare_formula_selected !== null && selectedWidgetEntity?.params?.is_welfare_formula_selected !== undefined ? selectedWidgetEntity?.params?.is_welfare_formula_selected : !!selectedWidgetEntity?.params?.isFormulaFieldSelected,
      welfareFormula: selectedWidgetEntity?.params?.custom_w_var ? selectedWidgetEntity?.params?.custom_w_var : selectedWidgetEntity?.params?.formulaWelfare,
      recommendedExpansion: selectedWidgetEntity?.params?.recommended_expansion ? selectedWidgetEntity?.params?.recommended_expansion : selectedWidgetEntity?.params?.recommendedExpansion,
      coverage_type: selectedWidgetEntity?.params?.coverage_type ? selectedWidgetEntity?.params?.coverage_type : false
    }
  };

  const methods = useForm({
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    setValue,
  } = methods;

  const {
    data: demandDatasetColumns,
    isSuccess: demandDatasetColumnsIsSuccess
  } = useDataSetColumns(
    demandDatasetName,
    'NUMERICAL'
  );

  const recommendedExpansion = watch('recommendedExpansion');
  const budget = watch('budget');
  const expanxionCapacity = watch('expanxionCapacity');
  const expanxionCost = watch('expanxionCost');
  const isWelfareFormulaSelected = watch('isWelfareFormulaSelected');
  const welfareFormula = watch('welfareFormula');
  const welfareVariable = watch('welfareVariable');
  const densityVariable = watch('densityVariable');
  const coverageType = watch('coverageType');

  React.useEffect(()=> {
    setValue('coverageType', selectedWidgetEntity?.params?.coverage_type ? selectedWidgetEntity?.params?.coverage_type : false)
  },[])

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const generateAndUpdateRecommendationsData = () => {
    setDataForRecom({
      ...dataForRecom,
      recommendedExpansion,
      budget,
      expanxionCapacity,
      expanxionCost,
      isWelfareFormulaSelected,
      welfareFormula,
      welfareVariable,
      densityVariable,
      coverageType
    });
    dataForRecom.handleGenerateRecommendations(budget, densityVariable, recommendedExpansion, expanxionCost, expanxionCapacity, isWelfareFormulaSelected, welfareFormula, welfareVariable, coverageType)

    const updatedWidget = {
      id: selectedWidgetEntity.id,
      layer: dataForRecom.widget.layer,
      params: {
        ...selectedWidgetEntity.params,
      },
    };
    updatedWidget.params.budget = budget;
    updatedWidget.params.expansion_capacity = expanxionCapacity;
    updatedWidget.params.expansion_cost = expanxionCost;
    updatedWidget.params.w_var = welfareVariable;
    updatedWidget.params.quant_var = densityVariable;
    updatedWidget.params.custom_w_var = welfareFormula;
    updatedWidget.recommended_expansion = recommendedExpansion
    updatedWidget.params.is_welfare_formula_selected = isWelfareFormulaSelected;
    updatedWidget.params.coverage_type = coverageType;
    dispatch(setRecomputedRecommendationsWidgets( updatedWidget ));
    const widgetFiltersForCurrentOfferResp = getWidgetsWithFiltersByLayerId( updatedWidget.params.currentOfferLayerName, sources, layers, widgets );

    const widgetFiltersForTargetDemandResp = getWidgetsWithFiltersByLayerId( selectedWidgetEntity.layer, sources, layers, widgets );
    dispatch(setWidgetFiltersForTargetDemand(
      widgetFiltersForTargetDemandResp.map(obj => ({ ...obj }))
    ));

    dispatch(setWidgetFiltersForCurrentOffer(
      widgetFiltersForCurrentOfferResp.map(obj => ({ ...obj }))
    ));
  }

  const formGenerateRecommendations = (
    <Container display='flex' className={classes.container}>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      <Grid item xs={12}>
        <Box justifyContent="space-between" display="flex">
          <Typography variant="body1" className={` ${classes.labelColor} ${classes.alignSelfCenter} `}>
            {t('budget')}:
          </Typography>
          <TypographyWithInfoIconTooltip >
            { t('recommendations_budget_info') }
          </TypographyWithInfoIconTooltip>
        </Box>
      </Grid>
      <Grid item xs={12}>
          <Box justifyContent="space-between" display="flex">
          <SliderFieldCustom
            name='budget'
            min={1}
            step={1}
            valueLabelDisplay='auto'
            max={500}
            custom
            gridSliderInputColumn= {5}
            gridSliderColumn= {7}
          />
          <Typography variant='caption' className={`${classes.labelColor} ${classes.variableNameCommon}`}>
            {`(${ t('unit').toLowerCase()})`}
          </Typography>
          </Box>
          <Divider style={{ marginTop: 20, marginBottom: 20 }}/>
      </Grid>
      { !!defaultValues?.isOfferSelected && (<>
      <Grid item xs={12}>
        <Typography variant="body1" className={` ${classes.labelColor} ${classes.alignSelfCenter} ${classes.servicePointLabel}`}>
            {t('recommendations_recommended_expansion')}
          </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="inline-flex">
          <IOSSwitch
            className={classes.customSwitch}
            labelPlacement="start"
            labelClasses={ classes.customSwitchLabel }
            checked={recommendedExpansion}
            onChange={(e) => setValue('recommendedExpansion', e.target.checked)}
          />
          <Typography variant='caption' className={`${classes.labelColor} ${classes.marginAuto} ${classes.yesNoLabel}`}>
            { t('no_yes') }
          </Typography>
          <TypographyWithInfoIconTooltip className={ classes.marginAuto }>
            { t('recommendations_expansion_capacity_info') }
          </TypographyWithInfoIconTooltip>
        </Box>
      </Grid>
      {recommendedExpansion ? (<Grid item xs={12}>
          <Box display={'flex'} flexDirection={'column'}>
          <Typography className={` ${classes.labelColor} ${classes.maxCapacityToExpand}`}>
          { defaultValues?.customLabels?.recommendationsExpansionCapacity || t('recommendations_expansion_capacity') }:
          </Typography>
          </Box>
          <Box justifyContent="space-between" display="flex">
          <SliderFieldCustom
            name='expanxionCapacity'
            step={1}
            min={1}
            max={500}
            valueLabelDisplay='auto'
            custom
            gridSliderInputColumn= {5}
            gridSliderColumn= {7}
          />
          <Typography variant='caption' className={`${classes.labelColor} ${classes.variableNameCommon} ${classes.variableName}`}>
            (max_expansion_capacity)
          </Typography>
          </Box>
      </Grid>) : null}
      {recommendedExpansion ? (<Grid item xs={12}>
          <Box display={'flex'} flexDirection={'column'}>
          <Typography className={` ${classes.labelColor} ${classes.maxCostToExpand}`}>
          { defaultValues?.customLabels?.recommendationsExpansionCost || t('recommendations_expansion_cost') }:
          </Typography>
          </Box>
          <Box justifyContent="space-between" display="flex">
          <SliderFieldCustom
            name='expanxionCost'
            step={1}
            min={1}
            max={500}
            valueLabelDisplay='auto'
            custom
            gridSliderInputColumn= {5}
            gridSliderColumn= {7}
          />
          <Typography variant='caption' className={`${classes.labelColor} ${classes.variableNameCommon} ${classes.variableName}`}>
          {`(${ t('unit').toLowerCase()})`}
          </Typography>
          </Box>
      </Grid>):null}

      <Divider style={{ marginTop: 20, marginBottom: 20 }}/>

      </>)}

      <Grid item xs={12}>
          <Box display={'flex'} flexDirection={'column'}>
          <Typography variant="body2" className={` ${classes.labelColor} ${classes.demmandAccountLabel}`}>
            {t('demmand_config')}
          </Typography>
          </Box>
          <Box justifyContent="space-between" display="flex">
          { demandDatasetColumnsIsSuccess ? (
                    <SelectField
                      name='densityVariable'
                      variant='outlined'
                      label={  dataForRecom?.widget?.params?.customLabels?.recommendationsDemandDensity || t('recommendations_demand_density')}
                      fullWidth
                      className={classes.densityVariableStyles}
                    >
                      <Divider light value=' ' />
                      { demandDatasetColumns?.map((ddc) => (
                        <MenuItem
                          key={ddc}
                          value={ddc}
                          selected={ densityVariable === ddc}
                        >
                          {ddc}
                        </MenuItem>
                      )) }
                    </SelectField>
                    ) : (
                      <CustomSkeleton className={classes.selectSkeleton} />
                    )}
          <TypographyWithInfoIconTooltip className={ classes.marginAuto } >
            { t('recommendations_demand_density_info') }
          </TypographyWithInfoIconTooltip>
          </Box>
      </Grid>
      <Grid item xs={12}>
        <Box justifyContent="space-between" display="flex" style={{marginBottom: 8}}>
        <Typography variant='caption' className={` ${classes.labelColor} ${classes.alignSelfCenter} `}>
            { t('recommendations_specify_welfare_formula') }
          </Typography>
          <IOSSwitch
            className={classes.customSwitch}
            labelPlacement="start"
            labelClasses={ classes.customSwitchLabel }
            checked={isWelfareFormulaSelected}
            onChange={(e) => setValue('isWelfareFormulaSelected', e.target.checked)}
            name="isWelfareFormulaSelected"
          />
          <Typography variant='caption' className={`${classes.labelColor} ${classes.marginAuto}`}>
            { t('no_yes') }
          </Typography>
          <TypographyWithInfoIconTooltip className={ classes.marginAuto }>
            { t('recommendations_priorization_variable_info') }
          </TypographyWithInfoIconTooltip>
        </Box>
      </Grid>
      <Grid item xs={12}>
      {!isWelfareFormulaSelected && <>
                     {demandDatasetColumnsIsSuccess ? (
                      <div className={ classes.displayFlex }>
                    <SelectField
                      name='welfareVariable'
                      variant='outlined'
                      label={  dataForRecom?.widget?.params?.customLabels?.variableWelfare || t('variable_welfare') }
                      fullWidth
                      style={{ paddingBottom: 5 }}
                    >
                      <Divider light value=' ' />
                      { demandDatasetColumns?.map((ddc) => (
                        <MenuItem key={ddc} value={ddc}>
                          {ddc}
                        </MenuItem>
                      )) }
                    </SelectField>
                                                                </div>
                     ) : (
                      <CustomSkeleton className={classes.selectSkeleton} />
                    )
                    }</>}
                    {isWelfareFormulaSelected && (
                    <div className={ `${classes.displayFlex} ${classes.boxSizingContentBox}` }>
                    <TextField
                      name='welfareFormula'
                      margin='dense'
                      type='text'
                      label={ dataForRecom?.widget?.params?.customLabels?.recommendationsFormulaPriorizationVariable || t('recommendations_formula_priorization_variable') }
                      variant='outlined'
                      fullWidth
                    />
                    </div>
                   ) }
      </Grid>
      <Grid item xs={12}>
        <Box justifyContent="space-between" display="flex" style={{marginBottom: 8}}>
          <Typography variant='caption' className={` ${classes.labelColor} ${classes.alignSelfCenter} `}>
            { t('recommendations_specify_coverage_type') }
          </Typography>
          <IOSSwitch
            labelPlacement="start"
            labelClasses={ classes.customSwitchLabel }
            checked={coverageType}
            onChange={(e) => setValue('coverageType', e.target.checked)}
            name="coverageType"
            sx={{ml: '8px'}}
          />
          <Typography variant='caption' className={`${classes.labelColor} ${classes.marginAuto}`}>
            { t('isochrone_circle') }
          </Typography>
        </Box>
      </Grid>
    </Container>
  );

  const actions = (
    <>
      <Button onClick={onClose} variant='text' className={classes.btnClose}>
        {t('cancel_btn')}
      </Button>
      <Button
        type='submit'
        variant='outlined'
        className={classes.btnAccept}
        onClick={ () => generateAndUpdateRecommendationsData() }
      >
        { t('widget_button_generate_label') }
      </Button>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          title={ t('generate_recommendations_modal_header') }
          actions={actions}
          widthInPixels={360}
        >
          {formGenerateRecommendations}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default GenerateRecommendationsFormModal;
