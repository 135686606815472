/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { Container, Grid, makeStyles, Button, Toolbar, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from 'components/providers/AuthProvider';
import { usePermissions } from 'components/hooks/usePermissions';
import useOrganizations from 'api/hooks/useOrganizations';
import { useTranslation } from '../../providers/TranslationProvider';

import DeleteOrganization from './DeleteOrganization';
import styles from './styles/organizations';
import CardOrg from './Card';
import CardSkeleton from './CardSkeleton';
import AddOrUpdateOrganization from './AddOrUpdateOrganization';
import { GLOBAL } from 'utils/constants';
import { useTheme } from 'components/providers/CustomThemeProvider';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles(styles);

const ActionBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const { hasPermissionFor } = usePermissions();

  const handleMyWorkSpace = () => navigate('/orgs/workspace/maps');

  const handleNewOrganization = () =>
    navigate(`${location.pathname}?create=organization`, {
      replace: true,
    });

  return (
    <Grid container spacing={2}>
      <Grid item>
        {hasPermissionFor('create_org') && (
          <Button
            variant='outlined'
            className={classes.btnWorkSpace}
            onClick={handleNewOrganization}
            startIcon={<AddIcon />}
          >
            {t('new_org')}
          </Button>
        )}
      </Grid>
      <Grid item>
        <Button
          variant='outlined'
          color='primary'
          className={classes.btnWorkSpace}
          onClick={handleMyWorkSpace}
          startIcon={<PersonIcon color='#00BBD4'/>}
        >
          {t('my_workspace')}
        </Button>
      </Grid>
    </Grid>
  );
};

const Organizations = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data, isLoading } = useOrganizations();
  const { user } = useAuth();
  const [ allowedOrganizations, setAllowedOrganizations ] = React.useState( [] );

  const { setColors, theme } = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  React.useEffect( () => {
    if (!isLoading) {
      if ( user.type === 'admin' || user.type === 'super_admin' ) {
        setAllowedOrganizations(data);
      } else {
        setAllowedOrganizations( data.filter( org => user.non_admin_users_orgs_ids_access_permissions.includes( org._id ) || org._id === user.org_id ) );
      }
    }
  }, [ data, user, isLoading ]);

  React.useEffect( () => {
    setColors( GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[500], GLOBAL.CUSTOM_SECONDARY_COLOR_PALETTE[400]);
  }, [ ]);

  const cardSkeletons = Array.from(Array(5).keys());

  return (
    <Container  maxWidth='xl' className={isLargeScreen ? classes.horizontalPadding : ''}>
      <Toolbar >
        <Grid container  justifyContent='space-between' className={classes.headerContainer}>
          <Grid item>
            <Typography variant='h4' component='h2'>
              {t('orgs')}
            </Typography>
            <Typography className={classes.headerIntro}>
              { t('organizations_dashboard_subheader') }
            </Typography>
          </Grid>
          <Grid item style={{marginRight:'-3rem'}}>
            <ActionBar />
          </Grid>
        </Grid>
      </Toolbar>
      <DeleteOrganization />
      <AddOrUpdateOrganization />
      <Grid container className={classes.container} data-cy="organizationsList">
        {isLoading ? (
          <>
            {cardSkeletons.map((c) => (
              <CardSkeleton key={c} />
            ))}
          </>
        ) : (
          <>
            {allowedOrganizations.map((organization) => {
              if (user.type === 'admin')
                return (
                  user.admin_rights.includes(organization._id) && (
                    <Grid item key={organization._id} className={classes.card}>
                      <CardOrg organization={organization} action='Update' />
                    </Grid>
                  )
                );
              return (
                <Grid item key={organization._id} className={classes.card}>
                  <CardOrg organization={organization} action='Update' />
                </Grid>
              );
            })}
          </>
        )}
      </Grid>
    </Container>
  );
};
export default Organizations;
