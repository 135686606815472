import { GLOBAL } from '../../../../../../../../utils/constants';

const styles = (theme) => ({
  containerPaper: {
    padding: theme.spacing(2, 3),
    width: '100%',
  },
  container: {
    margin: theme.spacing(4),
    padding: theme.spacing(3),
  },
  multiSelect: {
    paddingTop: 5,
    paddingBottom: 23,
    height: 35,
  },
  selectSkeleton: {
    height: 94,
    margin: theme.spacing(-2.2, 0, 1),
  },
  gridXs12: {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  switchBase: {
    color: '#757575',
    '&$checked': {
      color: '#FFFFFF',
      transform: 'translateX(14px)',
    },
    '&$checked + $track': {
      border:'none',
      backgroundColor: theme.palette.primary.main,
      opacity:1
    },
  },
  checked: {},
  track: {
    background: '#FFFFFF',
    border:'1px solid',
    borderColor:'#757575',
    borderRadius: 12,
    width: 43,
    height: 24,
  },
  thumb: {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
    marginTop:8,
    marginLeft:5
  },
  sliderThumb:{
    borderRadius: '4px',
    width: 4,
    height: 24,
    backgroundColor: 'primary.main',
    marginTop: '-0.7rem'
  },
  sliderTrack:{
    height: 8,
    opacity: 0.4,
    marginLeft: '-2px',
    marginTop: '-3px',
  },
  btnClose: {
    borderRadius: 20,
    color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]
  },
  buttonAction: {
    padding:'10px 16px 10px 16px',
    borderRadius: '20px',
    color:'#005F64',
    borderColor:'#005F64',
    height:'37px',
    width:'105px',
    '&:hover': {
      borderColor:'#005F64',
    },
  },
  stepButton:{
    '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active':{
      color:theme.palette.primary.main
    },
    '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed':{
      color:theme.palette.primary.main
    },
    '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root':{
      color:'#E0E0E0'
    },
    '& .css-1hv8oq8-MuiStepLabel-label':{
      color:'#9E9E9E',
      fontSize: 14,
      fontWeight: 500,
      fontFamily:'Montserrat',
      letterSpacing:'0.1px',
      textTransform:'uppercase'
    },
    '& .css-1hv8oq8-MuiStepLabel-label.Mui-active':{
      color:'#212121',
      fontSize: 14,
      fontWeight: 500,
      fontFamily:'Montserrat',
      letterSpacing:'0.1px',
      textTransform:'uppercase'
    }
  },
  gridFirstStep:{
    marginTop:24,
    marginBottom:24
  },
  typographyFirstStep:{
    color: '#325D6C',
    marginLeft:'10px'
  },
});

export const textFieldCommonStyle = {
  width: '173px',
  height: '56px',
}

export const divCommonStyle = {
  width:'123px',
  marginTop:'-5px',
  marginRight: '16px'
}

export const divArrowsActionStyle = {
  display:'flex',
  flexDirection:'column'
}

export const divSwitchStyle = {
  display:'flex',
  marginLeft:'-0.4rem',
  marginTop:'-0.4rem'
}

export const typographyByValueStyle = {
  color: '#325D6C',
  marginLeft:'10px',
  marginTop:'24px',
  marginBottom:'24px',
}

export const divValueRangeStyle = {
  marginLeft:5,
  height:56,
  minWidth:45,
  alignContent:'center',
  border:'1px solid',
  borderColor:'#757575',
  padding:'7px 5px 8px 11px',
  borderRadius:'4px'
}

export const divMenuItemStyle = {
  borderRadius: '16px',
  width: '24px',
  height: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 4,
}

export const selectStyle = {
  height: '56px',
  width: '120px',
  marginLeft:'-5px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#757575',
  },
  '& .MuiListItem-root': {
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}

export const arrowsCommonStyle = {
  cursor:'pointer',
}

export const labelOpacityStyle = {
  color: '#424242',
  marginTop:5
}

export const fontVisibilityStyle = {
  color: '#424242',
  marginLeft:'10px',
}

export const gridSliderStyle = {
  display:'flex',
  marginLeft:'5px'
}

export const gridRangeStyle = {
  display:'flex',
  marginTop:'-15px',
  marginLeft:'12px'
}

export const inputColorFieldStyle = {
  marginTop: 0,
  width:'24px',
  height:'24px',
  '& .MuiInputBase-input': {
    padding: '0px',
    height: '24px',
    width: '24px',
    borderRadius: '4px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}

export default styles;
