import * as React from 'react';
import {
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useLocation, Outlet } from 'react-router-dom';
import { useAuth } from 'components/providers/AuthProvider';
import logo from 'assets/img/logo.0c7a85ba.png';
import {
  MuiContainerNavBar, MuiGridContainer,
  MuiGridImgContainer,
  MuiImg
} from "./styles/workspace";

const useStyles = makeStyles((theme) => ({
  navBar: {
    boxShadow: 'none',
    overflow: 'hidden',
    marginBottom: 40,
  },
  toolBar: {
    paddingLeft: '55vh',
    backgroundColor: 'white',
  },
  tab: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary,
  },
  tabs: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    '& .Mui-selected': {
      fontSize: 15,
      fontWeight: 700,
      color: theme.palette.primary.main,
      transition: 'font-size 10ms, font-weight 10ms',
    },
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
  img: {
    width: 240,
    height: 300,
    objectFit: 'scale-down',
  },
  imageContainer: {
    textAlign: 'center',
  },
  container: {
    paddingTop: 20,
  },
  typography: {
    width: '100vh',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  paper: {
    justifyContent: 'center',
  },
}));

const Workspace = () => {
  const location = useLocation();
  const { user, isLoading } = useAuth();

  const isSpecificationView = location.pathname.split('/').includes('specification')

  return (
    <MuiContainerNavBar>
      <Grid container>
        {!isSpecificationView && (
          <MuiGridImgContainer item xs={3}>
            <Typography variant='h6' sx={{textAlign: 'center', fontWeight: 'bold'}}> {user?.org.name}/{user?.name}</Typography>
            {isLoading ? (
              <>
                <MuiImg src={logo} alt={'logo'}/>
              </>
            ) : (
              <>
                <MuiImg src={user?.org.attrs.profile_img_url} alt={'logo'}/>
              </>
            )}
          </MuiGridImgContainer>
        )}
        <MuiGridContainer item xs={isSpecificationView ? 12 : 9}>
          <Outlet />
        </MuiGridContainer>
      </Grid>
    </MuiContainerNavBar>
  );
};
export default Workspace;
