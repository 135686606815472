import * as React from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button as MuiButton,
  Card as MaterialCard,
  CardActionArea,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

import DescriptionIcon from '@material-ui/icons/Description';
import LinkIcon from '@material-ui/icons/Link';

import { TextField } from 'components/common/ReactHooksFormFields';
import Modal from 'components/common/Modal';
import useUploadFile from 'api/hooks/useUploadFile';
import Button from 'components/common/Button';
import { useTranslation } from 'components/providers/TranslationProvider';
import useCreateDataset from 'api/hooks/useCreateDataset';
import Toast from 'components/common/Toast';
import { giveMeId } from 'utils/supportComponents';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'components/providers/AuthProvider';

import UploadFile from './Uploadfile';
import styles from '../styles/formModal';
import useImportFromCarto from '../../../../api/hooks/useImportFromCarto';
import axios from '../../../../api/axios/axiosInstance';
import apiPaths from '../../../../api/apiPaths';
import { status200 } from '../../../../api/status.utils';
import { messageSeverity } from 'utils/constants';
import { Box, Checkbox } from '@mui/material';

import { cleanImportJobErrors, setImportJobErrors } from 'store/appSlice';
import { useDispatch, useSelector } from 'react-redux';

import { hexToRgb } from 'utils/colorConverter';

import useDeleteDataset from 'api/hooks/useDeleteDataset';
import Loader from '../common/Loader';

const schema = yup.object().shape({
  prosperiaName: yup.string().min(4, 'min_4_characters').required('url_required'),
  description: yup.string(),
  continueOnErrors: yup.boolean(),
  overrideOnExist: yup.boolean(),
});

const useStyles = makeStyles(styles);

const useStylesDatasetType = makeStyles((theme) => ({
  root: {
    width: 150,
    height: 150,
    cursor: ({ disabled }) => (disabled ? 'auto' : 'pointer'),
    borderStyle: 'solid',
    borderWidth: ({ active, disabled }) => (active && !disabled ? '2px' : '1px'),
    borderColor: ({ active, disabled }) => {
      if (active) return theme.palette.primary.dark;
      if (disabled) return theme.palette.grey[300];
      return theme.palette.grey[300];
    },
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    margin: theme.spacing(1),
  },
  cardContent: {
    cursor: ({ disabled }) => (disabled ? 'auto' : 'pointer'),
    background: ({ active }) =>
      active && `rgba(${hexToRgb(localStorage.getItem('colorPrimary')).join(', ')},0.2)`,
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    objectFit: 'scale-down',
    height: '5vw',
    borderWidth: 10,
    borderColor: ({ active }) => (active ? theme.palette.primary.dark : theme.palette.grey[300]),
  },
  title: {
    marginTop: 0,
    fontSize: 12,
  },
  container: {
    justifyContent: 'start',
    alignContent: 'center',
    marginBottom: 25
  },
  icons: {
    width: 100,
    height: 100,
    color: localStorage.getItem('colorPrimary'),
    fontSize: 40,
  },
}));

const FormModal = ({ dataset, action, open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('error');
  const [open1, setOpen1] = React.useState(false);
  const [localfileUrl, setLocalFileUrl] = React.useState('');
  const [, setSynced] = React.useState(true);
  const [privateLoading, setPrivateLoading] = React.useState(false);
  // eslint-disable-next-line
  const [, setUploaded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { mutateAsync: uploadImage, isError: uploadIsError, error: uploadError } = useUploadFile();
  const [continueOnErrors,] = React.useState(true);
  const [overrideOnExist, setOverrideOnExist] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [, setShowProgressBar] = React.useState(false);
  const [showImportErrors, setShowImportErrors] = React.useState(false);
  const [showImportWarnings, setShowImportWarnings] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = React.useState('');
  const importJobErrors = useSelector((state) => state.app.importJobErrors);
  const [idDataSet , setIdDataset] = React.useState();
  const deleteMutation = useDeleteDataset();

  const dispatch = useDispatch()
  const location = useLocation();
  const { user } = useAuth();
  const closeDialog = () => {
    setTimeout(() => {
      onClose();
    }, 200);
  };

  const handleDownload = (data) => {
    try {
      const json = JSON.stringify(data);
      const blob = new Blob([json], { type: 'application/json' });
      const href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = 'import_errors.json';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error  downloading file:', error);
    }
  };

  const handleDelete = (id) => {
      const data = {
        dataset_id: idDataSet
      };
      deleteMutation.mutateAsync(data);
      setShowImportErrors(false);
      setShowImportWarnings(false);
      setIdDataset();
      closeDialog();
  };


  const handleChangeOverrideOnExist = () => {
    setOverrideOnExist(!overrideOnExist);
  }

  const showMessage = (message, severity = messageSeverity.SUCCESS) => {
    setMessage(message);
    setSeverity(severity);
    setOpen1(true);
  };

  const handleImportJobResultCheck = (message, severity, intervalHandler) => {
    showMessage(message, severity);
    setPrivateLoading(false);
    setTimeout(() => {
      closeDialog();
    }, 1000);
    clearInterval(intervalHandler);
  };

  const checkImportJobStatus = (importJobId) => {
    let intervalMaxFailedRequest = 10;
    let intervalFailedRequestCount = 0;

    let intervalHandler = setInterval(() => {
      if (intervalFailedRequestCount === intervalMaxFailedRequest) {
        setShowProgressBar(false);
        dispatch(setImportJobErrors({
          message: t('call_lambda_fail')
        }))
        setPrivateLoading(false);
        clearInterval(intervalHandler);
        setShowImportErrors(true);
      }

      axios.get(`${apiPaths.importJob}/get/${importJobId}`, status200)
        .then(res => {
          if (res && res.status === 200 && res.data) {
            let importJob = res.data.import_job;

            if (importJob.status === 'in_progress') {
              if (importJob.message) {
                setProgress(importJob.message.progress);
                setNotificationMessage(importJob.message.notification);
              }
            }

            if (importJob.status === 'failed') {
              setShowProgressBar(false);
              dispatch(setImportJobErrors(importJob.message));
              setPrivateLoading(false);
              clearInterval(intervalHandler);
              setShowImportErrors(true);
            }

            if (importJob.status === messageSeverity.SUCCESS) {
              setShowProgressBar(false);
              handleImportJobResultCheck(
                t('imported_dataset'),
                messageSeverity.SUCCESS, intervalHandler
              );
            }

            if (importJob.status === messageSeverity.WARNING) {
              setShowProgressBar(false);
              dispatch(setImportJobErrors(importJob.message));
              setPrivateLoading(false);
              clearInterval(intervalHandler);
              setShowImportWarnings(true);
              setIdDataset(importJob.collectionId);
            }
          } else {
            intervalFailedRequestCount++;
          }
        })
        .catch(err => {
          console.error(t('status_import_job_error'), err);
          intervalFailedRequestCount++;
          clearInterval(intervalHandler);
        })
      }, 1600);
  };

  const performActionsAfterUpload = () => {
    setPrivateLoading(true);

    setSeverity('info');
    setOpen1(true);
  };

  const setErrors = (data) => {
    setMessage( t(data) );
    setSeverity('error');
    setOpen1(true);
  };

  const { mutateAsync: createDataset, isLoading: createIsLoading } = useCreateDataset(
    performActionsAfterUpload,
    setErrors
  );

  const { mutateAsync: importFromCarto, isLoading: importIsLoading } = useImportFromCarto(
    performActionsAfterUpload,
    setErrors
  );

  const getDefaultValues = () => {
    return dataset
      ? {
          url: dataset.url,
          prosperiaName: dataset.prosperiaName,
          cartoName: dataset.cartoName,
          description: dataset.description,
          datasetLocal: dataset.datasetLocal
        }
      : {
          url: '',
          prosperiaName: '',
          cartoName: '',
          description: '',
          datasetLocal: false
        };
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const handleUploadFile = async (e) => {
    setMessage(
      t('processing_message')
    );
    setSeverity('info');
    setOpen1(true);
    setUploaded(false);
    setIsLoading(true);
    const file = new FormData();
    file.append('image_file', e.target.files[0]);
    const fileUrl = await uploadImage(file);
    setLocalFileUrl(fileUrl);
    setUploaded(true);
    setIsLoading(false);
    setMessage(t('success_message'));
    setSeverity('success');
    setOpen1(true);
    setSynced(false);
  };

  const newDataset = (data) => {
    const datasetToCreate = {
      prosperiaName: data.prosperiaName,
      cartoName: data.cartoName,
      description: data.description,
      org: giveMeId(location.pathname, user),
      continueOnErrors: continueOnErrors,
      overrideOnExist: overrideOnExist,
      cartoData: {
        url: localfileUrl || data.url
      },
    };

    let datasetProcessPromise;
    if (valueRadio === 'carto_name') {
      datasetProcessPromise = importFromCarto(datasetToCreate);
    } else {
      datasetProcessPromise = createDataset(datasetToCreate);
    }

    datasetProcessPromise
      .then(importJobId => {
        setShowProgressBar(true);
        checkImportJobStatus(importJobId);
      })
      .catch(() => {
        setShowProgressBar(false);
      });
  };

  React.useEffect(() => {
    if (uploadIsError) {
      setMessage(uploadError?.data?.error);
      setSeverity('error');
      setOpen1(true);
    }
  }, [uploadIsError, uploadError?.data?.error, t]);

  React.useEffect(() => {
    dispatch(cleanImportJobErrors())
  }, [])

  const typeImportDataset = ['url_map', 'carto_name', 'local'];
  const [valueRadio, setValueRadio] = React.useState('');

  const configureImportSection = () => {
    switch (valueRadio) {
      case typeImportDataset[0]:
      case typeImportDataset[2]:
        return (
          <>
            <FormLabel id="radio-buttons-type-dataset-import">{t('options_importation')}</FormLabel>
            <Grid item xs={12} className={classes.inputText}>
              <FormControlLabel
                control={
                  <Checkbox
                    className='radio-color'
                    onChange={handleChangeOverrideOnExist}
                  />
                }
                label={t('overwrite_if_exists')}
              />
            </Grid>
          </>
        );
      case typeImportDataset[1]:
        return (
          <>
          </>
        );
    }
  }

  const inputByTypeDataSetImport = () => {
    switch (valueRadio) {
      case typeImportDataset[0]:
        return (
          <Grid item xs={12} className={classes.inputText}>
            <TextField
             name='url'
             margin='dense'
             type='text'
             label={t('dataset_url_label')}
             variant='outlined'
             fullWidth
             error={t(errors.url?.message)}
             data-cy="url"
            />
          </Grid>
        );
      case typeImportDataset[1]:
        return (
          <Grid item xs={12} className={classes.inputText}>
            <TextField
              name='cartoName'
              margin='dense'
              type='text'
              label={t('dataset_carto_name')}
              variant='outlined'
              fullWidth
              error={t(errors.cartoName?.message)}
            />
          </Grid>
        );
      case typeImportDataset[2]:
        return (
          <Grid item xs={12}>
            <Typography>{t('browse_dataset')}</Typography>
            <UploadFile handleChange={handleUploadFile} name='organizationLogo' />
          </Grid>
        );
    }
  }

  const Card = ({ imgSrc, title, active, onClick, disabled, type }) => {
    const classesDatasetType = useStylesDatasetType({ active, disabled });
    return (
      <MaterialCard className={classesDatasetType.root}>
        <CardActionArea className={classesDatasetType.cardContent} onClick={onClick} data-cy={type} >
          <Grid
            container
            direction='column'
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            {imgSrc}
            <Typography align='center' className={classesDatasetType.title}>
              {title}
            </Typography>
          </Grid>
        </CardActionArea>
      </MaterialCard>
    );
  };

  const DatasetType = React.forwardRef(({ name }, ref) => {
    const classesDatasetType = useStylesDatasetType();
    const { control } = useFormContext();
    const selectInputValues = [
      {
        value: 'local',
        label: t('local_dataset'),
        disabled: false,
        src: <DescriptionIcon color='primary' className={classesDatasetType.icons} />,
      },
      {
        value: 'url_map',
        label: t('url_dataset'),
        disabled: false,
        src: <LinkIcon color='primary' className={classesDatasetType.icons} />,
      },
    ];
    return (
      <>
        <Controller
          control={control}
          ref={ref}
          name={name}
          render={({ field: { value, onChange } }) => (
            <Grid container spacing={1} className={classesDatasetType.container}>
              {selectInputValues.map((i) => (
                <Card
                  key={i.value}
                  active={value === i.value}
                  onClick={i.disabled ? undefined : () => {onChange(i.value); setValueRadio(i.value);}}
                  title={i.label}
                  imgSrc={i.src}
                  disabled={i.disabled}
                  type={i.value}
                />
              ))}
            </Grid>
          )}
        />
      </>
    );
  });

  const datasetForm = (
    <Container display='flex' className={classes.container}>
      <Loader
        loading={privateLoading}
        progress={progress}
        headerMessage={'Uploading dataset'}
        titleMessage={t('uploading_dataset')}
        notificationMessage={notificationMessage}
      />
      <FormLabel id="radio-buttons-type-dataset-import">{t('import_dataset_preference')}</FormLabel>
      <DatasetType name='type' />

      {configureImportSection()}

      {!!valueRadio && (
        <>
          <Grid item xs={12} className={classes.inputText}>
            <TextField
              name='prosperiaName'
              margin='dense'
              type='text'
              label={t('name')}
              variant='outlined'
              fullWidth
              error={t(errors.prosperiaName?.message)}
              data-cy="prosperiaName"
            />
          </Grid>
          <Grid item xs={12} className={classes.inputText}>
            <TextField
              name='description'
              margin='dense'
              type='text'
              label={t('dataset_local_name')}
              variant='outlined'
              fullWidth
              error={t(errors.description?.message)}
            />
          </Grid>
        </>
      )}
      { inputByTypeDataSetImport()}
    </Container>
  );

  const onSubmit = handleSubmit((data) => newDataset(data));

  const actions = (
    <>
      {(!showImportErrors && !showImportWarnings) && (
        <MuiButton onClick={onClose} color='primary' variant='text' className={classes.btnClose}>
        {t('cancel_btn')}
      </MuiButton>   )}
      {(!showImportErrors && !showImportWarnings) && (
      <Button
        variant='outlined'
        className={classes.buttonAction}
        style={{width: !isLoading ? '105px': '120px'}}
        onClick={onSubmit}
        loading={createIsLoading || importIsLoading}
        disabled={isLoading || !isValid || createIsLoading || importIsLoading}
        data-cy="uploadDataset"
      >
        {!isLoading ? t(action) : 'processing...'}
      </Button>)}
    </>
  );

  return (
    <FormProvider {...methods}>
      <Toast
        open={open1}
        handleClose={() => setOpen1((prevState) => !prevState)}
        severity={severity}
        vertical='top'
        horizontal='center'
        title={message}
      />
      <form>
        <Modal open={open} onClose={onClose} title={t('upload_dataset')} actions={actions}>
          {(showImportErrors || showImportWarnings) && (
            <>
              {showImportWarnings && (
                <FormLabel id="warning-import-label">{t('warning-import-label')}</FormLabel>
              )}
              {showImportErrors && (
                <FormLabel id="error-import-label">{t('error-import-label')}</FormLabel>
              )}
              <Box style={{marginTop: 25}}>
                {
                  Object.keys(importJobErrors).map((key1, index1) => (
                    <Box key={key1}>
                      {typeof importJobErrors[key1] === 'object' && (
                        <>
                          <Typography>{index1 + 1}. {key1}</Typography>
                          {Object.keys(importJobErrors[key1]).map((key2, index2) => (
                            <Box key={key2}>
                              <Typography style={{marginLeft: 16}}>{index1 + 1}.{index2 + 1}. {key2}: {importJobErrors[key1][key2]}</Typography>
                              <br/>
                            </Box>
                          ))}
                        </>
                      )}
                      {typeof importJobErrors[key1] === 'string' && (
                        <>
                          <Typography>{index1 + 1}. {key1}: {importJobErrors[key1]}</Typography>
                          <br/>
                        </>
                      )}
                    </Box>
                  ))
                }
                <Grid container justifyContent='space-between' style={{ marginBottom: 25, marginTop: 25 }}>
                  <Box>
                    {showImportWarnings && (
                      <Button
                      style= {{marginLeft: 8, paddingTop: 10 , backgroundColor: '#FF6464'}}
                      onClick={() => handleDelete(idDataSet)}
                      loading={deleteMutation.isLoading}
                      startIcon={<DeleteIcon/>}
                      >
                      { t('delete') }
                    </Button>
                    )}
                  </Box>
                  <Box>
                    <Button
                      style= {{marginLeft: 8, paddingTop: 10 }}
                      onClick={() => {
                        setShowImportErrors(false);
                        setShowImportWarnings(false);
                        closeDialog();
                      }}
                      startIcon={<CloseIcon/>}
                      data-cy="closeDatasetModal"
                    >{ t('hide') }
                    </Button>
                    <Button
                      style={{marginLeft: 8, paddingTop: 10}}
                      onClick={() => handleDownload(importJobErrors)}
                      startIcon={<DownloadIcon/>}
                    >{ t('download') }
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </>
          )}
          {(!showImportErrors && !showImportWarnings) && (
            <>
              {datasetForm}
            </>
          )}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default FormModal;
