import { Grid, styled, Typography } from '@mui/material';


export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  marginTop:'18px',
  marginBottom:'5px'
}));

export const MuiTypographyName = styled(Typography)(({ theme }) => ({
  marginLeft:'3rem',
  color:'#000000'
}));

export const MuiTypographyIcon = styled(Typography)(({ theme }) => ({
  marginLeft:'-20px',
  color:'#000000'
}));

export const MuiTypographyBorderColor = styled(Typography)(({ theme }) => ({
  marginLeft:'-15px',
  color:'#000000'
}));

const styles = (theme) => ({
  controlsContainer: {
    margin: theme.spacing(0),
  },
  selectSkeleton: {
    height: 80,
    margin: theme.spacing(0),
  },
  containerLegendItems: {
    overflow: 'scroll',
    marginLeft: 0,
    border: '1px solid',
    borderRadius: '12px',
    borderColor: '#E0E0E0',
    overflowY: 'auto',
    height: 200
  },
  container: {
    height: 250,
    paddingRight: theme.spacing(1),
  },
  selectContainer: {
    padding: theme.spacing(1, 3),
  },
  buttonsContainer: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    justifyContent: 'flex-end',
  },
});
export default styles;
