import * as React from 'react';
import {
  AppBar,
  Drawer,
  Divider,
  Hidden,
  Grid,
  IconButton,
  Toolbar,
  makeStyles,
  Container,
  Button,
  Link
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PublicIcon from '@mui/icons-material/Public';
import CloseIcon from '@material-ui/icons/Close';
import { NavLink, useLocation } from 'react-router-dom';

import UserMenu from 'components/views/login/UserMenu';
import { useAuth } from 'components/providers/AuthProvider';
import { useTranslation } from 'components/providers/TranslationProvider';
import { useTheme } from 'components/providers/CustomThemeProvider';

import LanguageSelector from './LanguageSelector';
import OrgDescription from './OrgDescription';
import NavigationMenu from './NavigationMenu';
import OrgInfo from './OrgInfo';
import styles from './styles/header';
import SettingsIcon from 'components/common/SettingsIcon';
import SettingsIconHover from 'components/common/SettingsIconHover';

const useStyles = makeStyles(styles);

const Header = () => {
  const location = useLocation();
  const classes = useStyles({ disabledContainer: location.pathname.split('/').includes('view') });
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const { isAuthenticated , user} = useAuth();
  const { setSwitchColor, isCustomColor } = useTheme();
  const urlParams = location.pathname.split('/');
  const schemeId = location.pathname.split('/')[4];

  const { t } = useTranslation();

  React.useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  const getNavigateSettings = () => {
    if (urlParams.includes('view') && urlParams.includes('public'))
      return `${urlParams.slice(0, 3).join('/')}/maps/${urlParams[6]}/specification`;

    if (urlParams.includes('view') && urlParams.includes('custom'))
      return `${urlParams.slice(0, 3).join('/')}/maps/${urlParams[6]}/specification`;

    if (urlParams[6] === 'custom' && urlParams[7]) return `${urlParams.slice(0, 7).join('/')}`;

    return `${location.pathname}`;
  };

  const getLastElement = () => {
    if (urlParams[6] === 'custom' && urlParams[8]) return 5;
    if (urlParams[6] === 'custom' && urlParams[7]) return 4;
    if (urlParams.includes('view')) return 3;
    if (urlParams.includes('specification')) return 2;
    if (urlParams[2]) return 1;
    if (urlParams[1]) return 0;
    return null;
  };

  return (
    <AppBar position='static' className={classes.navBar}>
      <Container className={classes.container}>
        <Toolbar variant='dense' className={classes.toolbar}>
          <Hidden smUp>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='menu'
              onClick={handleDrawerToggle}
            >
              {drawerOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <Divider orientation='vertical' light />
          </Hidden>
          <OrgInfo />
          {isAuthenticated && user.type !== 'level_0' && (
            <Hidden xsDown>
              <div className={classes.dividerContainer}>
                <Divider variant='middle' orientation='vertical' className={classes.divider} light />
              </div>
              <NavigationMenu location={location} />
            </Hidden>
          )}
          {isAuthenticated && (
            <Hidden smUp>
              <Drawer
                variant='temporary'
                anchor='left'
                open={drawerOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                PaperProps={{
                  className: classes.drawer,
                }}
              >
                <Toolbar variant='dense' />
                <Grid container direction='column' justifyContent='space-between' item xs>
                  <NavigationMenu location={location} column />
                </Grid>
              </Drawer>
            </Hidden>
          )}
          <Grid container item xs justifyContent='flex-end'>
            {location.pathname.split('/')[1] !== 'public' ||
              (user?.type !== 'level_0' && location.pathname.split('/')[1] === 'public' )  ? (
              <Grid container className={classes.languageSelectorContainer}>
                {getLastElement() === 3 && ( <Link style={{marginRight: 24}} className={classes.displayFlex} to={getNavigateSettings()} component={NavLink} underline='none'>
                  <button
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{ cursor: 'pointer', border: 'none', background: 'none' }}
                    >
                      { isHovered ? <SettingsIconHover/> : <SettingsIcon/> }
                  </button>
                </Link>)}
                {getLastElement() === 2 && ( <Link style={{marginRight: 24}} className={classes.displayFlex} to={`${location.pathname.split('/').splice(0, 4).join('/')}/view/custom/${schemeId}`} component={NavLink} underline='none'>
                  <IconButton className={classes.mapIconButton}>
                    <PublicIcon className= {classes.mapIcon} />
                  </IconButton>
                </Link>)}
                <LanguageSelector />
                {isAuthenticated ? (
                  <UserMenu />
                ) : (
                  !location.pathname.split('/').includes('public') && (
                    <Button component={NavLink} to='/login' className={classes.loginButton}>
                      {t('login')}
                    </Button>
                  )
                )}
                {urlParams.includes('view') && <OrgDescription />}
              </Grid>
            ): (
              <LanguageSelector />
            )}
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
